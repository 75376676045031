<template>
  <section>
    <div class="d-flex justify-center mt-5">
      <div class="row">
        <div class="col-12">
          <!-- <v-img class="logoImg"
            :src="require('@/assets/buymytrip.png')"
            style="height: 90px;"
          /> -->
          <v-img class="logoImg" :src="portalLogo" style="height: 90px;"></v-img>
        </div>

        <!-- <div class="col-sm-6 col-12 content-rev">
          <span
            style="
              font-size: 69px;
              font-weight: 600;
              color: rgb(255 178 58);
              position: relative;
            "
            >RESERVE</span
          >
        </div> -->
      </div>
    </div>

    <!-- <div class="d-flex justify-center mt-3" style="font-family:'Roboto';color:white;font-size: 43px;">Best Fare Guranteed</div>
   -->

    <!-- <div class="d-flex justify-center mt-5">
      <v-progress-circular
        :size="260"
        :width="7"
        color="white"
        indeterminate
      >
      <v-carousel  cycle  :interval="interval"
  style="width: 250px !important;height:250px !important;"
      hide-delimiter-background
      hide-delimiters
      :show-arrows="false"
      >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
  </v-progress-circular>
  
  </div>
   -->

    <div class="d-flex justify-center mt-1">
      <!-- <div class="spinner">
        <v-img :src="require('@/assets/plane-window1.gif')"></v-img>
      </div> -->
      <v-img :src="require('@/assets/airgif.gif')" height="300px"></v-img>
    </div>

    <div>
      <div class="slideset1">
        <div style="width: 100%" align="center">
          <p style="font-family: 'Roboto'">
            {{ $t("loaderPageContent.loadMsg1") }}
          </p>
        </div>
        <div style="width: 100%" align="center">
          <p style="font-family: 'Roboto'">
            {{ $t("loaderPageContent.loadMsg2") }}
          </p>
        </div>
        <div style="width: 100%" align="center">
          <p style="font-family: 'Roboto'">
            {{ $t("loaderPageContent.loadMsg3") }}
          </p>
        </div>
      </div>
    </div>
    <br />
    <!-- <div class="container-fluid">
              <div class="d-flex align-center flex-column mt-5">
                  <v-card rounded="5" max-width="735px"  style="padding:30px;">
                      <div class=" col-12 mt-2" align="center">
                       
                      </div>
                      <p class="m-0 mt-3 text-center">
                          <b> Please wait while we check availability and confirm your itinerary.</b>
  
                      </p>
                      <div class="d-flex justify-content-center">
  
                          <v-img :src="require('@/assets/preloader_flights.gif')" height="150px"></v-img>
                      </div>
  
                      <p class="text-center" id="textchanging">
                          <b> Displaying your flight details</b>
                      </p>
                      <div class="row">
                          <div class="col-1 air-plane" align="center">
                              <v-icon>mdi-airplane-takeoff</v-icon><br>
  
                          </div>
                          <div class="col-10">
  
                              <div class="progress-wrapper mt-2">
                                  <div class="progress">
                                      <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0"
                                          aria-valuemax="100"></div>
                                  </div>
  
                              </div>
                          </div>
                          <div class="col-1 air-plane air1" align="center">
                              <v-icon>mdi-airplane-landing</v-icon>
                          </div>
  
                      </div>
  
                      <div class="row">
                          <div class="col-6 from text-truncate">
                              <b>
                                  <span style="text-align: center;color:rgb(115, 115, 233)"> {{ oneway.from }}</span><br>
  
                                  {{ oneway.dedate }}
                              </b>
                          </div>
                  
                          <div class="col-6 from text-truncate" style="display: flex;
    justify-content: end;">
                              <b>
                                  <span style="text-align: center;color:rgb(115, 115, 233)"> {{ oneway.to }}</span><br>
  
                                 {{ oneway.redate }}
                              </b>
                          </div>
                      </div>
                      <br>
  
                      <div style="font-size:17px;font-weight:500;text-align:center">
                          <span>{{oneway.adult}} Adult</span>
                          <span v-if="oneway.child>0">,  {{oneway.child}}  Children</span>
                          <span v-if="oneway.infrant>0">,  {{oneway.infrant}}  Infants</span>
                          <span >,  ({{ oneway.class }})</span>
                      </div>
                      <div class="loader-main"></div>
  
                      <div class="row justify-content-center mt-3 ">
                          <div class="col-lg-4 border-right">
                              <div class="detail-png text-center">
               
                                  <p class="text-muted text-center para1">Best Fare Guranteed</p>
                                  <p class="text-center para2">
                                      Find our lowest price to destinations worldwide guranteed.
                                  </p>
                              </div>
                          </div>
                          <div class="col-lg-4 border-right">
                              <div class="detail-png text-center">
                                  
                                  <p class="text-muted text-center para1">Best Fare Guranteed</p>
                                  <p class="text-center para2">
                                      Search select and save. the fastest way to book your trip.
                                  </p>
                              </div>
                          </div>
                          <div class="col-lg-4">
                              <div class="detail-png text-center">
                           
                                  <p class="text-muted text-center para1">Best Fare Guranteed</p>
                                  <p class="text-center para2">
                                      Get award winning support and special deals. Reach us at
                                      reservation@buymytrip.com
                                  </p>
                              </div>
                          </div>
                      </div>
                  </v-card>
              </div>
          </div> -->
  </section>
</template>
<script>
// import moment from "moment";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  data() {
    return {
      portalLogo:"",
      interval: 1000,
      items: [
        {
          src: require("@/assets/caro1.jpg"),
        },
        // {
        //   src: require('@/assets/caro2.jpg')
        // },
        // {
        //   src: require('@/assets/caro3.jpg')
        // },
        // {
        //   src: require('@/assets/caro4.jpg')
        // },
      ],
      oneway: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        adult: "",
        infrant: "",
        child: "",
        class: "",
      },
    };
  },
  methods: {
  //   data1() {
  //     let resul = [];
  //     resul = JSON.parse(localStorage.getItem("rountData"));
  //     // console.log(resul, "ppppppppp");
  //     if (resul.from.name) {
  //       this.oneway.from = resul.from.name;
  //     } else {
  //       this.oneway.from = resul.from;
  //     }
  //     if (resul.to.name) {
  //       this.oneway.to = resul.to.name;
  //     } else {
  //       this.oneway.to = resul.to;
  //     }
  //     this.oneway.dedate = moment(resul.dedate).format("DD MMM-YYYY");
  //     if (resul.redate) {
  //       this.oneway.redate = moment(resul.redate).format("DD MMM-YYYY");
  //     } else {
  //       this.oneway.redate = moment(resul.dedate).format("DD MMM-YYYY");
  //     }

  //     this.oneway.adult = resul.adult;
  //     this.oneway.infrant = resul.infrant;
  //     this.oneway.child = resul.child;
  //     this.oneway.class = resul.class;
  //   },
   getConfig() {

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
       this.portalLogo = getConfigData1.payload.portal_configuration.logo_path
      }
  },
  },
  created() {
    // this.data1();
    this.getConfig() 
  },
};
</script>
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
}

.main-section {
  width: 100%;
  /* height: 500px; */
}

/* loader main */
.con {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 30px;
  max-width: 735px;
  margin: auto;
}

.loader-main {
  border-bottom: 1px dashed rgba(17, 17, 26, 0.5);
}

.loader-main p {
  font-size: 15px;
  font-weight: 700;
}

.flight-img {
  width: 250px;
}

.info-img {
  width: 45px;
}

/* .detail-png img{
      margin-left: 50px;
  } */
.detail-png .para1 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

.text-center.para2 {
  font-size: 12px;
}

.border-right {
  border-right: 1px dashed rgba(17, 17, 26, 0.5);
}

.message {
  font-size: 12px;
  font-weight: 400;
}

.progress {
  height: 7px !important;
  background: none;
  border: 1px solid #002a5f !important;
  overflow: visible !important;
  z-index: -9999;
  position: relative;
  max-width: 700px !important;
  margin: 0% auto;
}

.progress-bar {
  background-color: #002a5f;
  z-index: 1;
  position: relative;
  overflow: visible !important;
  animation: progressLineTransmission 10s linear both;
}

.slideset1 {
  height: 10em;
  position: relative;
  /* color: white; */
  margin: auto;
  display: flex;
  justify-content: center;
  top: 50px;
}

.slideset1 > * {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  animation: 6s autoplay1 infinite;
}

@keyframes autoplay1 {
  0% {
    visibility: visible;
  }
  33.33% {
    visibility: hidden;
  }
}
.slideset1 > *:nth-child(1) {
  animation-delay: 0s;
}
.slideset1 > *:nth-child(2) {
  animation-delay: 2s;
}
.slideset1 > *:nth-child(3) {
  animation-delay: 4s;
}
>>> .v-progress-circular--indeterminate > svg {
  animation: progress-circular-rotate 100s linear infinite;
  transform-origin: center center;
  transition: all 100s ease-in-out;
}
@keyframes progressLineTransmission {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.progress::before {
  content: "";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  left: -5px;
  z-index: 9999999;
  overflow: visible;
}

.progress::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  right: -5px;
  z-index: 9999999;
  overflow: visible;
}

.air-plane {
  position: relative;
  bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}

>>> .v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 50% !important; */
}
@media only screen and (max-width: 570px) {
  .content-rev {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 480px) {
  .air-plane {
    position: relative;
    bottom: 1px;
    font-size: 14px;
  }
  .air1 {
    display: flex;
    justify-content: flex-end;
  }
  .from {
    font-size: 12px;
  }
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(1turn);
  }
}

.spinner {
  position: relative;
  z-index: 0;
  height: 250px;
  width: 168px;
  border-radius: 81px;
  overflow: hidden;
  padding: 10px;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #ee2b34;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#ee2b34, #ee2b34),
      linear-gradient(#ee2b34, #ee2b34), linear-gradient(#ee2b34, #ee2b34),
      linear-gradient(#fff, #fff);
    animation: spinner-rotate 1s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 9px);
    height: calc(100% - 9px);
    background: #ee2b34;
    border-radius: 75px;
    /* color:#fff; */
  }
}
.logoImg{
  width:500px;
}
.slideset1 p{
  font-size:35px;
}
@media screen and (max-width:430px) {
  .logoImg{
    width:250px;
  }
  .slideset1 p{
    font-size:20px;
  }
}
</style>
