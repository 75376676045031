<template>
  <div>
    <div v-for="(item, index) in transformedArray" :key="index">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      originalArray: [
        "a",
        "b",
        "c",
        "d",
        "a",
        "b",
        "c",
        "d",
        "a",
        "b",
        "c",
        "d",
      ],
    };
  },
  computed: {
    transformedArray() {
      const chunkSize = 3; // Define chunk size
      const result = [];
      for (let i = 0; i < this.originalArray.length; i += chunkSize) {
        result.push(this.originalArray.slice(i, i + chunkSize));
      }
      return result;
    },
  },
};
</script>
