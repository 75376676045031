import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import i18n from '@/plugins/i18n';
// import VueTelInput from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";



loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(PrimeVue)
  .use(i18n)
  .use(AutoComplete)
  .mount("#app");
  
