<template>
  <div class="flight-back">
    <div
      :style="{
        'background-image':
          'url(' + require('../assets/background_img.jpg') + ')',
      }"
      style="background-size: cover; background-position: center"
    >
      <section
        class="py-4"
        style="background: #00000052; background-blend-mode: screen"
      >
        <v-container
          class="container container-styles px-0"
          style="max-width: 1200px !important"
        >
          <div class="row gx-0">
            <div class="col-md-12 col-12 text-center">
              <div class="ps-lg-4 ps-0">
                <div class="banner-left-small-title">
                  <h1
                    class="text-light pt-sm-5 pt-0 mb-4 banner-lg-text"
                    style="-webkit-text-stroke: 0.5px black"
                  >
                    {{ $t("homePageContent.welcomeMsg.text1") }}
                  </h1>
                  <span
                    class="text-light banner-sm-text"
                    style="
                      font-size: 23px;
                      font-weight: 500;
                      color: white !important;
                      background: #0000003b;
                      margin: auto;
                      padding: 0 14px;
                    "
                  >
                    {{ $t("homePageContent.welcomeMsg.text2") }}
                    <!-- Hop-in for a trip full of experiences.<br />
                    Get great deals for your travel requirements. -->
                  </span>
                </div>
                <!-- <div class="banner-left-title mt-1 text-light">
              <h1 class="text-center" style="font-family: 'Roboto';font-size: 50px;font-weight: 600;">
                Live to Travel
              </h1>
            </div> -->
              </div>
            </div>
            <div class="col-md-6 d-none">
              <v-img
                :src="require('@/assets/main-bg1.jpg')"
                style="width: 648px; height: 70%; position: absolute"
              />
            </div>
          </div>

          <div class="m-sm-0 m-3 form-container">
            <div>
              <div class="row gx-0 mb-4">
                <div class="col-lg-12 ps-0 pe-0">
                  <div class="headcard" align="center">
                    <div class="form-card">
                      <div class="row gx-0">
                        <div class="row pb-3 gx-0">
                          <div
                            class="col-md-4 col-12 d-flex flex-wrap w-auto gap-sm-0 gap-1"
                          >
                            <div class="d-sm-block d-none">
                              <v-btn
                                rounded="xl"
                                size="small"
                                value="1"
                                class="normal-radio"
                                :class="{ radio: red1 === 1 }"
                                @click="act(1)"
                                ><span>{{
                                  $t("formsContents.options.return")
                                }}</span></v-btn
                              >
                              <v-btn
                                rounded="xl"
                                size="small"
                                value="2"
                                class="normal-radio"
                                :class="{ radio: red1 === 2 }"
                                @click="act(2)"
                                ><span>{{
                                  $t("formsContents.options.oneWay")
                                }}</span></v-btn
                              >
                              <v-btn
                                rounded="xl"
                                size="small"
                                value="3"
                                class="normal-radio"
                                :class="{ radio: red1 === 3 }"
                                @click="act(3)"
                                ><span>{{
                                  $t("formsContents.options.multiCity")
                                }}</span></v-btn
                              >
                            </div>

                            <div class="d-sm-none d-block">
                              <div class="d-flex flex-wrap gap-1">
                                <div
                                  value="1"
                                  class="normal-radio d-flex align-items-center"
                                  :class="{ radio: red1 === 1 }"
                                  @click="act(1)"
                                >
                                  <span>
                                    <v-icon size="25"
                                      >mdi-swap-horizontal-circle-outline</v-icon
                                    ></span
                                  >
                                  <span class="f-size-11 fw-500 ms-1 text-wrap">
                                    {{ $t("formsContents.options.return") }}
                                  </span>
                                </div>

                                <div
                                  value="3"
                                  class="normal-radio d-flex align-items-center"
                                  :class="{ radio: red1 === 2 }"
                                  @click="act(2)"
                                >
                                  <span>
                                    <v-icon size="25"
                                      >mdi-arrow-right-circle-outline</v-icon
                                    ></span
                                  >
                                  <span class="f-size-11 fw-500 ms-1 text-wrap">
                                    {{ $t("formsContents.options.oneWay") }}
                                  </span>
                                </div>

                                <div
                                  value="3"
                                  class="normal-radio d-flex align-items-center"
                                  :class="{ radio: red1 === 3 }"
                                  @click="act(3)"
                                >
                                  <span>
                                    <v-icon size="25"
                                      >mdi-plus-circle-outline</v-icon
                                    ></span
                                  >
                                  <span class="f-size-11 fw-500 ms-1 text-wrap">
                                    {{ $t("formsContents.options.multiCity") }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-8 col-12 d-flex w-auto ms-md-4 ms-md-2 ms-0 mt-md-0 mt-2"
                          >
                            <div class="input-styles-2 select-option">
                              <v-select
                                v-model="classType"
                                :items="classTypeitems"
                                ref="mySelect"
                                class="select-2"
                                item-title="text"
                                variant="underlined"
                              >
                                <template v-slot:item="{ props, item, index }">
                                  <v-list-item
                                    v-bind="props"
                                    :subtitle="item.department"
                                    @click="selectClasstype(item, index)"
                                  >
                                  </v-list-item>
                                </template>
                              </v-select>
                              <!-- <v-select
                                v-model="classType"
                                ref="mySelect"
                                class="select-2"
                                @click="selectClasstype()"
                                :items="['Economy', 'Business', 'First Class']"
                                variant="underlined"
                                style="color: black"
                              ></v-select> -->
                            </div>

                            <div
                              class="d-flex input-styles-1 ms-2"
                              style="
                                height: 32px;
                                border-radius: 15px;
                                border: 1px solid #0000003d;
                              "
                            >
                              <span
                                class="d-flex me-2"
                                style="
                                  font-size: 16px;
                                  color: gray;
                                  position: relative;
                                  top: 0px !important;
                                "
                              >
                                <v-icon> mdi-account-outline </v-icon>
                              </span>

                              <v-select
                                v-model="selectedCountry"
                                :items="countries"
                                ref="countrySelect"
                                variant="underlined"
                                style="position: relative; top: -15px"
                                class="select-1"
                              >
                                <template #item="{}">
                                  <div
                                    class="dropdown_section"
                                    style="cursor: pointer"
                                  >
                                    <div
                                      class="row d-flex align-center my-2 mx-1"
                                      style="height: 37px"
                                      @click.stop
                                    >
                                      <div class="col-3" style="width: 38%">
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 500;
                                            color: #4e4d4d;
                                          "
                                          >{{
                                            $t("formsContents.options.adult")
                                          }}</span
                                        >
                                      </div>
                                      <div class="col-3 px-0" style="width: 9%">
                                        <v-icon
                                          style="
                                            width: auto;
                                            color: grey;
                                            font-size: 18px;
                                          "
                                        >
                                          mdi-human-male-female
                                        </v-icon>
                                      </div>
                                      <div
                                        class="col-6 adult"
                                        style="width: 50%; height: auto"
                                      >
                                        <div class="row" style="padding: 0px">
                                          <div class="col-4" @click="deg1()">
                                            <div class="adult-plus">-</div>
                                          </div>
                                          <div
                                            class="col-4"
                                            style="background-color: white"
                                            align="center"
                                          >
                                            <div>{{ aduls }}</div>
                                          </div>
                                          <div class="col-4" @click="add1()">
                                            <div class="adult-plus">+</div>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- <div>{{ item.name }}</div> -->
                                    </div>

                                    <div
                                      class="row d-flex align-center my-2 mx-1"
                                      @click.stop
                                    >
                                      <div
                                        class="col-4 d-flex flex-column pe-0"
                                        style="width: 38%"
                                      >
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 500;
                                            color: #4e4d4d;
                                          "
                                          >{{
                                            $t("formsContents.options.child")
                                          }}</span
                                        >
                                        <span
                                          style="
                                            font-size: 11px;
                                            color: #4e4d4d;
                                          "
                                          >2-11(yrs)</span
                                        >
                                      </div>
                                      <div class="col-2 px-0" style="width: 9%">
                                        <v-icon
                                          style="
                                            width: auto;
                                            color: grey;
                                            font-size: 18px;
                                          "
                                        >
                                          mdi-human-child
                                        </v-icon>
                                      </div>
                                      <div
                                        class="col-6 adult"
                                        style="width: 50%"
                                      >
                                        <div class="row" style="padding: 0px">
                                          <div class="col-4" @click="deg2()">
                                            <div class="adult-plus">-</div>
                                          </div>
                                          <div
                                            class="col-4"
                                            style="background-color: white"
                                            align="center"
                                          >
                                            <div>{{ aduls1 }}</div>
                                          </div>
                                          <div class="col-4" @click="add2()">
                                            <div class="adult-plus">+</div>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- <div>{{ item.name }}</div> -->
                                    </div>

                                    <div
                                      class="row d-flex align-center my-2 mx-1"
                                      @click.stop
                                    >
                                      <div
                                        class="col-5 d-flex flex-column pe-0"
                                        style="width: 38%"
                                      >
                                        <span
                                          style="
                                            font-size: 14px;
                                            font-weight: 500;
                                            color: #4e4d4d;
                                          "
                                          >{{
                                            $t("formsContents.options.infants")
                                          }}</span
                                        >
                                        <span
                                          style="
                                            font-size: 11px;
                                            color: #4e4d4d;
                                          "
                                          >2(yrs)</span
                                        >
                                      </div>

                                      <div class="col-1 ps-0" style="width: 9%">
                                        <v-icon
                                          style="
                                            width: auto;
                                            color: grey;
                                            font-size: 18px;
                                          "
                                        >
                                          mdi-baby-buggy
                                        </v-icon>
                                      </div>
                                      <div
                                        class="col-6 adult"
                                        style="width: 50%"
                                      >
                                        <div class="row" style="padding: 0px">
                                          <div class="col-4" @click="deg3()">
                                            <div class="adult-plus">-</div>
                                          </div>
                                          <div
                                            class="col-4"
                                            style="background-color: white"
                                            align="center"
                                          >
                                            <div>{{ aduls2 }}</div>
                                          </div>
                                          <div class="col-4" @click="add3()">
                                            <div class="adult-plus">+</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- <div class="row d-flex align-center  my-2 mx-1" @click.stop>
                                    <div class="col-4" style="width:35%">
                                      <span style="font-size: 14px;font-weight: 500;color: #4e4d4d;">Cabin</span>
                                    </div>

                                    <div class="col-1 ps-2" style="width:12%">
                                      <v-icon style="width:auto; color: grey; font-size:18px;">
                                        mdi-seat-passenger
                                      </v-icon>
                                    </div>
                                    <div class="col-7 p-0" style="width:50%">
                                      <div class="input-styles-2 select-option">
                                        <v-select v-model="oneway.class" ref="mySelect" class="select-2"
                                          @click="selectClasstype()" :items="['Economy', 'Business', 'First Class']"
                                          variant="underlined" style="color:gray;"></v-select>
                                      </div>
                                    </div>

                                  </div> -->

                                    <div
                                      class="mt-4 pe-3 d-flex justify-end"
                                      @click="
                                        (travels = false),
                                          localdone(),
                                          closeDropdown()
                                      "
                                    >
                                      <v-btn
                                        rounded="3"
                                        color="#ee2b34"
                                        width="100px"
                                        height="30px"
                                        style="color: white"
                                      >
                                        {{ $t("formsContents.options.done") }}
                                      </v-btn>
                                    </div>
                                  </div>
                                </template>

                                <template #selection="{}">
                                  <div class="pax-fonts">
                                    <span
                                      >{{ aduls + aduls1 + aduls2 }}
                                      {{
                                        aduls + aduls1 + aduls2 > 1
                                          ? this.$t(
                                              "formsContents.options.passengers"
                                            )
                                          : this.$t(
                                              "formsContents.options.passenger"
                                            )
                                      }}
                                    </span>
                                    <!-- <span>{{ aduls }} Adult</span>
                                  <span v-if="aduls1 > 0">, {{ aduls1 }} Child</span>
                                  <span v-if="aduls2 > 0">, {{ aduls2 }} Infant</span> -->
                                    <!-- <span>, {{ oneway.class }}</span> -->
                                  </div>
                                </template>
                              </v-select>
                            </div>
                          </div>
                        </div>

                        <v-card
                          rounted="2"
                          class="bg-transparent"
                          style="overflow: unset; z-index: 99"
                        >
                          <div v-if="rount || Oneway">
                            <v-form>
                              <div class="row gx-0 bg-white">
                                <div class="col-md-10">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="row gx-sm-3 gx-0">
                                        <div
                                          class="col-md-4 col-12"
                                        >
                                          <div class="d-flex form-radius">
                                            <div
                                              class="input-styles d-flex align-center styles_input1"
                                              :class="{
                                                'input-styles-focus':
                                                  focusEvent1,
                                              }"
                                              @click="
                                                
                                                  (focusEvent1 = true),
                                                  disableText(false)
                                              "
                                            >
                                              <span class="d-flex px-3">
                                                <v-icon
                                                  style="
                                                    color: lightgray;
                                                    font-size: 28px;
                                                  "
                                                  >mdi-airplane-takeoff</v-icon
                                                >
                                              </span>

                                              <!-- <div>
                                                <span class="p-float-label">
                                                  <AutoComplete
                                                    v-model="oneway.from"
                                                    optionLabel="name"
                                                    dropdown
                                                    forceSelection
                                                    :suggestions="
                                                      filteredCountries
                                                    "
                                                    @complete="search"
                                                    ref="fromAutoComplete"
                                                    @change="onChangeFrom()"
                                                    @click="fromClick(oneway)"
                                                    @focus="disableText(false)"
                                                    @blur="disableText(true)"
                                                    style="
                                                      width: 100%;
                                                      height: auto;
                                                      font-size: 18px;
                                                    "
                                                  >
                                                    <template
                                                      #option="slotProps"
                                                    >
                                                      <div
                                                        class="flex align-items-center dropdown-box"
                                                        @click="focus1()"
                                                      >
                                                        <div>
                                                          {{
                                                            slotProps.option
                                                              .originalName
                                                          }}
                                                        </div>
                                                      </div>
                                                    </template>
                                                  </AutoComplete>
                                                  <label>{{
                                                    $t(
                                                      "formsContents.labels.flyFrom"
                                                    )
                                                  }}</label>
                                                  <p
                                                    class="m-0 fromText"
                                                    v-if="
                                                      inputFocus &&
                                                      oneway.from == null
                                                    "
                                                  >
                                                    {{
                                                      $t(
                                                        "formsContents.labels.subDep"
                                                      )
                                                    }}
                                                  </p>
                                                </span>
                                              </div> -->
                                              <div class="autocomplete-label">
                                                <label :class="{'fly-from': focused || inputCity.length > 0}">
                                                    {{$t("formsContents.labels.flyFrom")}}</label>
                                                    <div class="custom-select-dropdown">
                                                        <!-- <div class="select-input">
                                                           <input type="text" readonly
                                                              value="Bangladesh">
                                                            <i class="bi bi-chevron-down"></i>
                                                         </div> -->
                                                        <div class="vas-autocomplete">
                                                            <input type="text" 
                                                                :placeholder="$t('formsContents.labels.subDep')"
                                                                v-model="inputCity"
                                                                @keyup="typeCity(inputCity, 1)"
                                                                class="inter-input"
                                                                :class="{'input-filled': inputCity.length > 0}"
                                                                @click="(inputCity = ''),
                                                                    (formres = true),
                                                                    (formres1 = false);city1 = [];"
                                                                    @focus="isFocused()" 
                                                                    @blur="onBlur()"
                                                            />
                                                            <div v-if="typedrop">
                                                                <div class="autocompete-menual-dropdown"
                                                                    v-if="this.city.length > 0">
                                                                    <div v-for="(data, index) in city" :key="index">
                                                                        <div @click="datachange(data)"
                                                                           :style="index == city.length - 1
                                                                                ? ''
                                                                                : 'border-bottom:1px solid lightgray'
                                                                            " class="d-flex va-back">
                                                                            <div style="
                                                                                display: flex;
                                                                                align-items: center;
                                                                                ">
                                                                                <v-icon class="air-icon" color="gray" size="20">
                                                                                    {{
                                                                                    data.location ===
                                                                                    "airport"
                                                                                    ? "mdi-airplane"
                                                                                    : "mdi-radiobox-marked"}}
                                                                                </v-icon>
                                                                            </div>

                                                                            <div class="m-1 va-content">
                                                                                {{ data.iata }}, {{
                                                                                data.city
                                                                                }},{{ data.name }}
                                                                            </div>
                                                                        </div>
                                                                        <!-- -------group--- -->

                                                                        <div v-if="
                                                                                data.group_airports
                                                                                .length > 0">
                                                                            <div v-for="group in data.group_airports"
                                                                                :key="group">
                                                                                <div v-if="
                                                                                    data.group_airport_city !=
                                                                                    group.iata
                                                                                ">
                                                                                    <div class="d-flex va-back"
                                                                                        @click="
                                                                                    datachange(group)
                                                                                    ">
                                                                                     <div style="
                                                                                        display: flex;
                                                                                        align-items: center;
                                                                                    " class="ms-4">
                                                                                        <v-icon class="air-icon" color="gray" size="15">
                                                                                                                mdi-airplane
                                                                                            </v-icon>
                                                                                     </div>

                                                                                    <div
                                                                                       class="m-1 va-content">
                                                                                        {{group.iata}}, {{group.city
                                                                                    }},{{group.name}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                               </div>
                                               
                                            <div v-if="fromloader">
                                                <v-progress-circular
                                                color="rgb(2 38 82)" size="24"
                                                indeterminate style="position:relative;top:5px;"
                                                ></v-progress-circular>
                                            </div>
                                            </div>
                                            <!-- <span
                                              style="
                                                border-right: 1px solid
                                                  rgb(25 24 24 / 46%);
                                                height: 70px;
                                                margin: auto;
                                              "
                                            ></span> -->
                                          </div>

                                          <div
                                            class="text-start"
                                            v-if="
                                              fromErrorMessage || maximumletter
                                            "
                                            style="
                                              position: relative;
                                              left: 84px;
                                            "
                                          >
                                            <!-- <p class="p-error-from" style="line-height:5;" v-if="fromErrorMessage">Departure is
                                        required</p> -->
                                            <!-- <br v-if="fromErrorMessage && maximumletter" /> -->
                                            <!-- <p
                                              class="p-error-from"
                                              v-if="
                                                maximumletter && focusEvent1
                                              "
                                            >
                                              Enter minimum 3 letters
                                            </p> -->
                                          </div>


                                          <div v-if="formres">
                                            <div
                                              v-if="resultFrom.length"
                                              class="recent_style"
                                            >
                                              <v-card
                                                style="
                                                  max-height: 300px;
                                                  border-radius: 20px;
                                                "
                                              >
                                                <h5
                                                  style="
                                                    text-align: left;
                                                    margin: 12px;
                                                    font-weight: 500;
                                                    font-size: 20px;
                                                  "
                                                >
                                                  {{
                                                    $t(
                                                      "formsContents.labels.recentSearch"
                                                    )
                                                  }}
                                                </h5>
                                                <div
                                                  class="row resu-form mt-1"
                                                  v-for="data of resultFrom"
                                                  :key="data"
                                                >
                                                  <div class="col-2">
                                                    <v-icon>
                                                      mdi-clock-outline
                                                    </v-icon>
                                                  </div>

                                                  <div
                                                    class="col-10"
                                                    @click="getrecent(data)"
                                                  >
                                                    <div
                                                      style="
                                                        font-size: 16px;
                                                        text-align: left;
                                                      "
                                                    >
                                                      {{
                                                          data.from
                                                      }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </v-card>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="col-md-4 col-12"
                                        >
                                          <div class="d-flex form-radius">
                                            <div
                                              class="input-styles d-flex align-center"
                                              :class="{
                                                'input-styles-focus':
                                                  focusEvent2,
                                              }"
                                              @click="
                                                  (focusEvent2 = true),
                                                  disableText1(false)
                                              "
                                            >
                                              <span class="d-flex px-3">
                                                <v-icon
                                                  style="
                                                    color: lightgray;
                                                    font-size: 28px;
                                                  "
                                                  >mdi-airplane-takeoff</v-icon
                                                >
                                              </span>

                                              <!-- <div></div> -->
                                                <!-- <span class="p-float-label">
                                                  <AutoComplete
                                                    v-model="oneway.to"
                                                    ref="secondAutoComplete"
                                                    optionLabel="name"
                                                    dropdown
                                                    @click="toClick(oneway)"
                                                    :suggestions="
                                                      filteredCountries
                                                    "
                                                    @focus="disableText1(false)"
                                                    @blur="disableText1(true)"
                                                    forceSelection
                                                    @complete="search"
                                                    @change="onChangeTo()"
                                                    style="
                                                      width: 100%;
                                                      height: auto;
                                                      font-size: 18px;
                                                    "
                                                  >
                                                    <template
                                                      #option="slotProps"
                                                    >
                                                      <div
                                                        class="flex align-items-center dropdown-box"
                                                        @click="focus2()"
                                                      >
                                                        <div>
                                                          {{
                                                            slotProps.option
                                                              .originalName
                                                          }}
                                                        </div>
                                                      </div>
                                                    </template>
                                                  </AutoComplete>
                                                  <label>{{
                                                    $t(
                                                      "formsContents.labels.flyTo"
                                                    )
                                                  }}</label>
                                                  <p
                                                    v-if="
                                                      fromval &&
                                                      oneway.to == null
                                                    "
                                                    class="m-0 fromText"
                                                  >
                                                    {{$t("formsContents.labels.subArr")}}
                                                  </p>
                                                </span> -->

                                                <div class="autocomplete-label">
                                                    <label :class="{'fly-from': focusedTo || inputCityto.length > 0}">{{$t("formsContents.labels.flyTo")}}</label>
                                                        <div class="custom-select-dropdown">
                                                            <!-- <div class="select-input">
                                                               <input type="text" readonly
                                                                value="Family Tour">
                                                                 <i class="bi bi-chevron-down"></i>
                                                            </div> -->
                                                            <div class="vas-autocomplete">
                                                                <input type="text" 
                                                                    :placeholder="$t('formsContents.labels.subArr')"
                                                                    v-model="inputCityto" @keyup="
                                                                    typeCity(inputCityto, 2)" 
                                                                    class="inter-input"
                                                                    :class="{'input-filled': inputCityto.length > 0}"
                                                                    @click="
                                                                    (inputCityto = ''),
                                                                    (formres1 = true),
                                                                    (formres = false),(city = [])"
                                                                    @focus="isFocusedTo()"
                                                                    @blur="onBlurTo()"
                                                                />

                                                                <div v-if="typedrop1">
                                                                    <div class="autocompete-menual-dropdown1"
                                                                       v-if="this.city1.length > 0">
                                                                       {{ console.log(this.city1,'cityyy') }}
                                                                        <div v-for="(data, index
                                                                            ) in city1" :key="index">
                                                                            <div @click=" datachangeTo(data)" 
                                                                                :style="index == city1.length - 1
                                                                                    ? '' : 'border-bottom:1px solid lightgray'" 
                                                                                    class="d-flex va-back">
                                                                                    <div style="
                                                                                        display: flex;
                                                                                        align-items: center;">
                                                                                        <v-icon class="air-icon" color="gray" size="20">
                                                                                            {{data.location ==="airport"
                                                                                                ? "mdi-airplane"
                                                                                                : "mdi-radiobox-marked"
                                                                                            }}
                                                                                        </v-icon>
                                                                                    </div>

                                                                                    <div class="m-1 va-content">
                                                                                        {{ data.iata }}, {{data.city}},{{ data.name }}
                                                                                    </div>
                                                                                </div>

                                                                                <div class="ms-4" v-if="
                                                                                    data.group_airports.length > 0">
                                                                                    <div v-for="group in data.group_airports"
                                                                                        :key="group">
                                                                                        <div v-if="
                                                                                            data.group_airport_city !=group.iata
                                                                                            ">
                                                                                            <div class="d-flex va-back"
                                                                                                @click="datachangeTo(group)">
                                                                                                <div style="
                                                                                                    display: flex;
                                                                                                    align-items: center;">
                                                                                                    <v-icon class="air-icon"
                                                                                                        color="gray" size="15">mdi-airplane</v-icon>
                                                                                                </div>

                                                                                                <div
                                                                                                    class="m-1 va-content">
                                                                                                     {{group.iata}}, {{group.city}},{{group.name}}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="toloader">
                                                <v-progress-circular
                                                color="rgb(2 38 82)" size="24"
                                                indeterminate style="position:relative;top:5px;right:16px;"
                                                ></v-progress-circular>
                                            </div>
                                              
                                            </div>

                                            <!-- <span
                                              style="
                                                border-right: 1px solid
                                                  rgb(25 24 24 / 46%);
                                                height: 70px;
                                                margin: auto;
                                              "
                                            ></span> -->
                                          </div>
                                          <div
                                            class="text-start"
                                            v-if="
                                              toErrorMessage || maximumletter1"
                                            style="
                                              position: relative;
                                              left: 84px;
                                            "
                                          >
                                            <!-- <span class="p-error-from" style="line-height:5" v-if="toErrorMessage">Arrival is
                                        required</span> -->
                                            <!-- <span
                                              class="p-error-to"
                                              v-if="
                                                maximumletter1 && focusEvent2
                                              "
                                              >Enter minimum 3 letters</span
                                            > -->
                                          </div>
                                          <div v-if="formres1">
                                            <div
                                              v-if="resultTo.length"
                                              class="recent_style"
                                            >
                                              <v-card
                                                style="
                                                  max-height: 300px;
                                                  border-radius: 20px;
                                                "
                                              >
                                                <h5
                                                  style="
                                                    text-align: left;
                                                    margin: 12px;
                                                    font-weight: 500;
                                                    font-size: 20px;
                                                  "
                                                >
                                                  {{
                                                    $t(
                                                      "formsContents.labels.recentSearch"
                                                    )
                                                  }}
                                                </h5>
                                                <div
                                                  class="row resu-form mt-1"
                                                  v-for="data of resultTo"
                                                  :key="data"
                                                >
                                                  <div class="col-2">
                                                    <v-icon>
                                                      mdi-clock-outline
                                                    </v-icon>
                                                  </div>

                                                  <div
                                                    class="col-10"
                                                    @click="getrecent1(data)"
                                                  >
                                                    <div
                                                      style="
                                                        font-size: 16px;
                                                        text-align: left;
                                                      "
                                                    >
                                                      {{
                                                          data.to
                                                      }}
                                                    </div>
                                                  </div>
                                                </div>
                                              </v-card>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          class="col-md-4 col-12 px-0"
                                          v-if="rount"
                                        >
                                          <div
                                            class="d-flex align-center input-styles styles_input2"
                                            :class="{
                                              'input-styles-focus':
                                                focusEvent3 ||
                                                oneway.travelDate,
                                            }"
                                            @click="focusCalendar('calendar1')"
                                          >
                                            <span class="d-flex pt-1 ps-2 pe-3">
                                              <v-icon
                                                style="
                                                  color: lightgray;
                                                  font-size: 28px;
                                                "
                                                >mdi-calendar-blank-outline</v-icon
                                              >
                                            </span>

                                            <span
                                              class="p-float-label"
                                              style="position: relative"
                                            >
                                              <VueDatePicker
                                                ref="dpRef1"
                                                v-model="oneway.travelDate"
                                                :min-date="new Date()"
                                                :six-weeks="sixWeekMode"
                                                @mousedown="
                                                  focusedElement = 'calendar1'
                                                "
                                                range
                                                :max-date="
                                                  new Date(
                                                    new Date().getFullYear() +
                                                      1,
                                                    new Date().getMonth(),
                                                    new Date().getDate()
                                                  )
                                                "
                                                :format="dateFormat"
                                                :hide-navigation="['time']"
                                                auto-apply
                                                @focus="
                                                  (focusEvent3 = true),
                                                    (focusEvent1 = false),
                                                    (focusEvent2 = false)
                                                "
                                                @blur="dateUnfocus"
                                                @closed="closeDateMenu"
                                                :multi-calendars="multiCalender"
                                                @update:modelValue="
                                                  fromDateChanged
                                                "
                                                :on-click-outside="
                                                  ClickOutDepat
                                                "
                                                :month-change-on-scroll="false"
                                                class="px-0"
                                              >
                                                <template #clear-icon="{}">
                                                  <!-- <img class="input-slot-image" src="/logo.png"  /> -->
                                                  <v-icon
                                                    class="input-slot-image me-2"
                                                    v-if="focusEvent3"
                                                    @click="clearDate"
                                                    >mdi-close-circle</v-icon
                                                  >
                                                </template>
                                              </VueDatePicker>

                                              <!-- {{
                                                !oneway.travelDate
                                                  ? "Travel Date"
                                                  : ""
                                              }} -->
                                              <label class="travelDate">{{
                                                $t(
                                                  "formsContents.labels.travelDate"
                                                )
                                              }}</label>

                                              <!-- <span class="datepicker-label">
                                                Travel Date
                                              </span> -->
                                            </span>
                                          </div>
                                          <!-- <div class="text-start">
                                      <span class="p-error-from" v-if="fromDateError">Dep. Date is required</span>
                                    </div> -->
                                        </div>

                                        <div
                                          class="col-md-4 col-12 px-0"
                                          v-if="Oneway"
                                        >
                                          <div
                                            class="d-flex align-center input-styles styles_input2"
                                            :class="{
                                              'input-styles-focus':
                                                focusEvent3 || oneway.dedate,
                                            }"
                                            @click="focusCalendar('calendar1')"
                                          >
                                            <span class="d-flex pt-1 ps-2 pe-3">
                                              <v-icon
                                                style="
                                                  color: lightgray;
                                                  font-size: 28px;
                                                "
                                                >mdi-calendar-blank-outline</v-icon
                                              >
                                            </span>

                                            <span
                                              class="p-float-label"
                                              style="position: relative"
                                            >
                                              <VueDatePicker
                                                ref="dpRef1"
                                                v-model="oneway.dedate"
                                                :min-date="new Date()"
                                                :six-weeks="sixWeekMode"
                                                @mousedown="
                                                  focusedElement = 'calendar1'
                                                "
                                                :max-date="
                                                  new Date(
                                                    new Date().getFullYear() +
                                                      1,
                                                    new Date().getMonth(),
                                                    new Date().getDate()
                                                  )
                                                "
                                                :format="dateFormat"
                                                :hide-navigation="['time']"
                                                auto-apply
                                                @focus="
                                                  (focusEvent3 = true),
                                                    (focusEvent1 = false),
                                                    (focusEvent2 = false)
                                                "
                                                :multi-calendars="multiCalender"
                                                @update:modelValue="
                                                  fromDateChanged
                                                "
                                                :on-click-outside="
                                                  ClickOutDepat
                                                "
                                                :month-change-on-scroll="false"
                                                class="px-0"
                                              >
                                                <template #clear-icon="{}">
                                                  <!-- <img class="input-slot-image" src="/logo.png"  /> -->
                                                  <v-icon
                                                    class="input-slot-image me-2"
                                                    v-if="focusEvent3"
                                                    @click="clearDate"
                                                    >mdi-close-circle</v-icon
                                                  >
                                                </template>
                                              </VueDatePicker>
                                              <label class="travelDate">{{
                                                $t(
                                                  "formsContents.labels.travelDate"
                                                )
                                              }}</label>

                                              <!-- <label>{{
                                                !oneway.dedate
                                                  ? "Travel Date"
                                                  : ""
                                              }}</label>

                                              <span class="datepicker-label">
                                                {{
                                                  $t(
                                                    "formsContents.labels.travelDate"
                                                  )
                                                }}
                                              </span> -->
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-md-2 p-0"
                                  style="background-color: rgb(238, 43, 52)"
                                >
                                  <div v-if="Oneway">
                                    <v-btn
                                      block
                                      class="search-btn m-auto"
                                      :disabled="disButton"
                                      color="white"
                                      height="70"
                                      @click="sendedone()"
                                      style="
                                        font-size: 25px;
                                        background: #ee2b34;
                                      "
                                      variant="tonal"
                                    >
                                      <span>{{
                                        $t("formsContents.options.search")
                                      }}</span>
                                    </v-btn>
                                  </div>

                                  <div v-if="rount">
                                    <v-btn
                                      block
                                      class="search-btn m-auto"
                                      :disabled="disButton"
                                      variant="tonal"
                                      color="white"
                                      height="70"
                                      @click="sended()"
                                      style="
                                        font-size: 16px;
                                        background: #ee2b34;
                                      "
                                    >
                                      <span>
                                        {{ $t("formsContents.options.search") }}
                                      </span>
                                    </v-btn>
                                  </div>
                                </div>
                              </div>
                            </v-form>
                          </div>

                          <div class="pb-0" v-if="multicity">
                            <v-form>
                              <div class="row gx-0">
                                <div
                                  class="row g-0 multiform-bottom mb-sm-0 mb-3 bg-white"
                                  v-for="(data, index) of multiData.itinery"
                                  :key="index"
                                >
                                  <div
                                    class="col-md-4 col-12 pe-0 multiform-bottom1"
                                  >
                                    <div class="d-flex">
                                      <div
                                        class="input-styles d-flex align-center styles_input1"
                                        @click="
                                            (data.focusMultiEvent1 = true),
                                            disableTextMulti(false, index)
                                        "
                                      >
                                        <span class="d-flex px-3">
                                          <v-icon
                                            style="
                                              color: lightgray;
                                              font-size: 28px;
                                            "
                                            >mdi-airplane-takeoff</v-icon
                                          >
                                        </span>

                                        <!-- <div></div> -->
                                          <!-- <span class="p-float-label">
                                            <AutoComplete
                                              v-model="data.from"
                                              optionLabel="name"
                                              dropdown
                                              forceSelection
                                              :suggestions="filteredCountries"
                                              @complete="search"
                                              ref="fromAutoComplete"
                                              @change="onChangeMultiFrom(index)"
                                              @click="
                                                multiFromClick(index),
                                                  (focusedElement = 'from')
                                              "
                                              @focus="
                                                disableTextMulti(false, index)
                                              "
                                              @blur="
                                                disableTextMulti(true, index)
                                              "
                                              style="
                                                width: 100%;
                                                height: auto;
                                                font-size: 18px;
                                              "
                                            >
                                              <template #option="slotProps">
                                                <div
                                                  class="flex align-items-center dropdown-box"
                                                  @click="multifocus1(index)"
                                                >
                                                  <div>
                                                    {{
                                                      slotProps.option
                                                        .originalName
                                                    }}
                                                  </div>
                                                </div>
                                              </template>
                                            </AutoComplete>
                                            <label
                                              >{{
                                                $t(
                                                  "formsContents.labels.flight"
                                                )
                                              }}
                                              {{ index + 1 }}
                                              {{
                                                $t(
                                                  "formsContents.labels.fromData"
                                                )
                                              }}</label
                                            >
                                            <p
                                              v-if="
                                                data.inputFocus1 && !data.from
                                              "
                                              class="m-0 where"
                                              style="
                                                color: #9ca3af;
                                                font-size: 14px;
                                                font-weight: 300;
                                              "
                                            >
                                              {{
                                                $t(
                                                  "formsContents.labels.subDep"
                                                )
                                              }}
                                            </p>
                                          </span> -->
                                        
                                        <div class="autocomplete-label">
                                            <label :class="{'fly-from': focusedMulti[index] || (data.from || '').length > 0 }">{{$t("formsContents.labels.flight")}}
                                              {{ index + 1 }}
                                              {{$t("formsContents.labels.fromData")}}
                                            </label>
                                            <div class="custom-select-dropdown">
                                                <div class="vas-autocomplete">
                                                    <input type="text" style="cursor: pointer;font-weight:600;"
                                                        :placeholder="$t('formsContents.labels.subDep')"
                                                        v-model="data.from" 
                                                         @click="
                                                           multiData.itinery[
                                                            index].from = ''" 
                                                            @keyup="typeMultiCity(
                                                            data.from,index,1)" 
                                                            @focus="isFocusedMulti(index)"
                                                            @blur="onBlurMulti(index)"
                                                            class="inter-input text-truncate"
                                                            :class="{'input-filled': (data.from || '').length > 0}"
                                                        />
                                                        <div v-if="multiData.itinery[index]
                                                            .autocomfrom ? true : false">
                                                            <div class="autocompete-menual-dropdown"
                                                               v-if="this.multidrop.length > 0 ? true : false ">
                                                                <div v-for="(data1, index1
                                                                  ) in multidrop" :key="index1">
                                                                  {{console.log(data1,"data1data1")}}
                                                                 <div @click="datachangeMuti(data1,index)" 
                                                                  :style="index1 == multidrop.length - 1
                                                                    ? '' : 'border-bottom:1px solid lightgray'" 
                                                                    class="d-flex p-2 va-back">
                                                                    <div style="
                                                                       display: flex;align-items: center;">
                                                                        <v-icon style="transform: rotate(90deg);" 
                                                                        color="gray" size="20">mdi-airplane
                                                                        </v-icon>
                                                                    </div>

                                                                    <div class="ms-1 va-content">
                                                                        {{ data1.iata }}, {{ data1.city }},
                                                                        {{ data1.name }}
                                                                    </div>
                                                                </div>

                                                                <div v-if="data1.group_airports.length > 0">
                                                                    <div v-for="(group, index1) in data1.group_airports"
                                                                        :key="index1">
                                                                       <div v-if="data1.group_airport_city !=
                                                                          group.iata">
                                                                         <div class="d-flex va-back"
                                                                            @click="datachangeMuti(group,index1)">
                                                                            <div style="display: flex;
                                                                               align-items: center;" class="ms-4">
                                                                                <v-icon style="transform: rotate(90deg);" 
                                                                                 color="gray" size="15">mdi-airplane
                                                                                </v-icon>
                                                                            </div>

                                                                            <div class="m-1 va-content">
                                                                                {{group.iata}}, {{group.city}},{{ group.name }}
                                                                            </div>
                                                                         </div>
                                                                        </div>
                                                                    </div>
                                                                 </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                     </div>
                                                </div>
                                            </div>
                                            <div v-if="data.fromloader">
                                                <v-progress-circular
                                                color="rgb(2 38 82)" size="24"
                                                indeterminate style="position:relative;top:5px;right:16px;"
                                                ></v-progress-circular>
                                            </div>
                                      </div>
                                    </div>

                                    <div
                                      class="text-start"
                                      v-if="fromErrorMessage || maximumletter"
                                      style="position: relative; left: 84px"
                                    >
                                      <!-- <p class="p-error-from" style="line-height:5;" v-if="fromErrorMessage">Departure is
                                        required</p> -->
                                      <!-- <br v-if="fromErrorMessage && maximumletter" /> -->
                                      <!-- <p
                                          class="p-error-from"
                                          v-if="data.maximumletter"
                                        >
                                          Enter minimum 3 letters
                                        </p> -->
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-12 ps-0 pe-0 multiform-bottom1"
                                  >
                                    <div class="d-flex">
                                      <div
                                        class="input-styles d-flex align-center"
                                        :class="{
                                          'input-styles-focus':
                                            data.focusMultiEvent2,
                                        }"
                                        @click="
                                            (data.focusMultiEvent2 = true),
                                            disableTextMultiTo(false, index)
                                        "
                                      >
                                        <span class="d-flex px-3">
                                          <v-icon
                                            style="
                                              color: lightgray;
                                              font-size: 28px;
                                            "
                                            >mdi-airplane-takeoff</v-icon
                                          >
                                        </span>

                                        <!-- <div></div> -->
                                          <!-- <span class="p-float-label">
                                            <AutoComplete
                                              v-model="data.to"
                                              ref="secondAutoComplete"
                                              optionLabel="name"
                                              dropdown
                                              @click="multiToClick(index)"
                                              :suggestions="filteredCountries"
                                              forceSelection
                                              @complete="search"
                                              :rules="[
                                                (v) =>
                                                  !!v || 'Enter the detail',
                                              ]"
                                              @change="onChangeMultiTo(index)"
                                              @focus="
                                                disableTextMultiTo(false, index)
                                              "
                                              @blur="
                                                disableTextMultiTo(true, index)
                                              "
                                              style="
                                                width: 100%;
                                                height: auto;
                                                font-size: 18px;
                                              "
                                            >
                                              <template #option="slotProps">
                                                <div
                                                  class="flex align-items-center dropdown-box"
                                                  @click="multifocus2(index)"
                                                >
                                                  <div>
                                                    {{
                                                      slotProps.option
                                                        .originalName
                                                    }}
                                                  </div>
                                                </div>
                                              </template>
                                            </AutoComplete>
                                            <label
                                              >{{$t("formsContents.labels.flight")}}
                                              {{ index + 1 }}
                                              {{$t("formsContents.labels.toData")}}
                                              </label
                                            >
                                            <p
                                              v-if="data.fromval1 && !data.to"
                                              class="m-0 where"
                                              style="
                                                color: #9ca3af;
                                                font-size: 14px;
                                                font-weight: 300;
                                              "
                                            >
                                              {{
                                                $t(
                                                  "formsContents.labels.subArr"
                                                )
                                              }}
                                            </p>
                                          </span> -->
                                          <div class="autocomplete-label">
                                            <label :class="{'fly-from': focusedMultiTo[index] || (data.to || '').length > 0 }">{{$t("formsContents.labels.flight")}}
                                              {{ index + 1 }}
                                              {{$t("formsContents.labels.toData")}}</label>
                                                <div class="custom-select-dropdown">
                                                    <div class="vas-autocomplete">
                                                        <input style="cursor: pointer;font-weight:600;" type="text"
                                                            :placeholder="$t('formsContents.labels.subArr')"
                                                            v-model="data.to" @click="
                                                            multiData.itinery[
                                                            index].to = ''" 
                                                            @keyup="typeMultiCity(data.to,index,2)" 
                                                            class="inter-input text-truncate" 
                                                            @focus="isFocusedMultiTo(index)"
                                                            @blur="onBlurMultiTo(index)"
                                                            :class="{'input-filled': (data.to || '').length > 0}"
                                                        />
                                                            <div v-if="data.autocomTo">
                                                                <div class="autocompete-menual-dropdown1"
                                                                    v-if="multidrop1.length > 0
                                                                        ? true: false">
                                                                    <div v-for="(data, index1
                                                                       ) in multidrop1" :key="index1">
                                                                      <div @click="
                                                                        datachangeMutiTo(data,index)" 
                                                                        :style="index1 == multidrop1.length - 1
                                                                          ? '' : 'border-bottom:1px solid lightgray'
                                                                           " class="d-flex p-2 va-back">
                                                                        <div style="display: flex;
                                                                            align-items: center;">
                                                                          <v-icon style="transform: rotate(
                                                                             90deg ); " 
                                                                             color="gray" size="20">
                                                                               {{ data.location ==="airport"
                                                                                 ? "mdi-airplane" : "mdi-radiobox-marked"}}
                                                                          </v-icon>
                                                                        </div>

                                                                        <div class="ms-1 va-content">
                                                                            {{ data.iata }}, {{ data.city }},{{ data.name }}
                                                                        </div>
                                                                      </div>

                                                                      <div v-if="
                                                                        data.group_airports.length > 0">
                                                                        <div v-for="(group, index1) in data.group_airports"
                                                                            :key="index1">
                                                                            <div v-if="data.group_airport_city !=
                                                                              group.iata">
                                                                              <div class="d-flex va-back"
                                                                                 @click="datachangeMutiTo(group,index1)">
                                                                                <div style="display: flex;
                                                                                  align-items: center;" class="ms-4">
                                                                                    <v-icon style="
                                                                                      transform: rotate(90deg);" 
                                                                                        color="gray" size="15">
                                                                                          mdi-airplane
                                                                                    </v-icon>
                                                                                </div>

                                                                                <div
                                                                                    class="m-1 va-content">
                                                                                    {{group.iata}}, {{group.city}},{{ group.name }}
                                                                                </div>
                                                                               </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>
                                             <div v-if="data.toloader">
                                                <v-progress-circular
                                                color="rgb(2 38 82)" size="24"
                                                indeterminate style="position:relative;top:5px;right:16px;"
                                                ></v-progress-circular>
                                            </div>
                                      </div>
                                    </div>
                                    <div
                                      class="text-start"
                                      v-if="toErrorMessage || maximumletter1"
                                      style="position: relative; left: 84px"
                                    >
                                      <!-- <span class="p-error-from" style="line-height:5" v-if="toErrorMessage">Arrival is
                                        required</span> -->
                                      <!-- <span
                                          class="p-error-to"
                                          v-if="data.maximumletter1"
                                          >Enter minimum 3 letters</span
                                        > -->
                                    </div>
                                  </div>

                                  <div class="col-md-2 col-12 calendarInput px-0">
                                    <div class="d-flex justify-content-between">
                                      <div
                                        class="d-flex align-center input-styles styles_input2"
                                        tabindex="0"
                                        :class="{
                                          'input-styles-focus':
                                            data.focusMultiEvent3 ||
                                            data.departDate,
                                        }"
                                        @click="
                                          focusMulti_AutoComplete(
                                            'calendar1',
                                            index
                                          )
                                        "
                                      >
                                        <span class="d-flex pt-1 ps-2 pe-3">
                                          <v-icon
                                            style="
                                              color: lightgray;
                                              font-size: 28px;
                                            "
                                            >mdi-calendar-blank-outline</v-icon
                                          >
                                        </span>

                                        <span
                                          class="p-float-label"
                                          style="position: relative"
                                        >
                                          <VueDatePicker
                                            ref="dpRef4"
                                            v-model="data.departDate"
                                            :six-weeks="sixWeekMode"
                                            :min-date="
                                              getdate1(multiData.itinery, index)
                                            "
                                            :start-date="
                                              getdate1(multiData.itinery, index)
                                            "
                                            :max-date="
                                              new Date(
                                                new Date().getFullYear() + 1,
                                                new Date().getMonth(),
                                                new Date().getDate()
                                              )
                                            "
                                            :format="dateFormat"
                                            :hide-navigation="['time']"
                                            auto-apply
                                            :multi-calendars="multiCalender"
                                            class="px-0"
                                            @focus="
                                              (data.focusMultiEvent3 = true),
                                                (data.focusMultiEvent1 = false),
                                                (data.focusMultiEvent2 = false)
                                            "
                                            @update:modelValue="
                                              (newValue) =>
                                                multiDateChanged(
                                                  newValue,
                                                  index
                                                )
                                            "
                                            :month-change-on-scroll="false"
                                          >
                                            <template #clear-icon="{ clear }">
                                              <!-- <img class="input-slot-image" src="/logo.png"  /> -->
                                              <v-icon
                                                class="input-slot-image me-2"
                                                v-if="data.focusMultiEvent3"
                                                @click="clear"
                                                >mdi-close-circle</v-icon
                                              >
                                            </template>
                                          </VueDatePicker>
                                          <label class="travelDate">{{
                                            $t(
                                              "formsContents.labels.travelDate"
                                            )
                                          }}</label>

                                          <!-- <label>{{
                                            !data.departDate
                                              ? this.$t(
                                                  "formsContents.labels.travelDate"
                                                )
                                              : ""
                                          }}</label> -->

                                          <!-- <span class="datepicker-label">
                                            {{
                                              $t(
                                                "formsContents.labels.travelDate"
                                              )
                                            }}
                                          </span> -->
                                        </span>
                                      </div>

                                      <div class="addmore-btn">
                                        <div
                                          class="d-flex"
                                          v-if="index !== 0 ? true : false"
                                        >
                                          <div
                                            class="crossicon"
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            <span
                                              style="margin: auto"
                                              v-if="index > 1 ? true : false"
                                              @click="removemulti(index)"
                                            >
                                              <v-icon color="red">
                                                mdi-close-circle-outline
                                              </v-icon>
                                            </span>
                                          </div>

                                          <div
                                            v-if="
                                              multiData.itinery.length == 4
                                                ? false
                                                : true
                                            "
                                          >
                                            <v-btn
                                              v-if="
                                                index ==
                                                multiData.itinery.length - 1
                                                  ? true
                                                  : false
                                              "
                                              class="me-lg-0 me-1 d-flex align-center formbottom_addBtn"
                                              @click="addTrip(index)"
                                              prepend-icon="mdi-plus-circle"
                                            >
                                              <span class="addmorebtn"
                                                >Add More</span
                                              ></v-btn
                                            >
                                          </div>

                                          <div v-if="index == 3 ? true : false">
                                            <v-btn
                                              class="me-lg-0 me-1 d-flex align-center formbottom_ClearBtn"
                                              @click="clearall1()"
                                              prepend-icon="mdi-close-box-multiple"
                                            >
                                              <span class="clearAll"
                                                >clearall</span
                                              ></v-btn
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-2 col-12 searchBtns px-0 m-auto">
                                    <div class="search-mediabtn">
                                      <v-btn
                                        block
                                        v-if="index == 0 ? true : false"
                                        class="search-btn2 m-auto"
                                        :disabled="disButton"
                                        color="#ee2b34"
                                        height="70px"
                                        @click="sendedMulticity()"
                                        style="font-size: 25px"
                                      >
                                        <span>
                                          {{
                                            $t("formsContents.options.search")
                                          }}</span
                                        >
                                      </v-btn>

                                      <div
                                        class="d-flex"
                                        v-if="index !== 0 ? true : false"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                            margin-right: 10px;
                                          "
                                        >
                                          <span
                                            style="margin: auto"
                                            v-if="index > 1 ? true : false"
                                            @click="removemulti(index)"
                                          >
                                            <v-icon color="red">
                                              mdi-close-circle-outline
                                            </v-icon>
                                          </span>
                                        </div>

                                        <div
                                          v-if="
                                            multiData.itinery.length == 4
                                              ? false
                                              : true
                                          "
                                        >
                                          <v-btn
                                            v-if="
                                              multiData.itinery.length - 1 ==
                                                3 ||
                                              index ==
                                                multiData.itinery.length - 1
                                                ? true
                                                : false
                                            "
                                            class="d-flex align-center formbottom_addBtn"
                                            @click="addTrip(index)"
                                            prepend-icon="mdi-plus-circle"
                                          >
                                            {{
                                              $t(
                                                "formsContents.options.addCity"
                                              )
                                            }}</v-btn
                                          >
                                        </div>

                                        <div v-if="index == 3 ? true : false">
                                          <v-btn
                                            class="d-flex align-center formbottom_ClearBtn"
                                            @click="clearall1()"
                                            prepend-icon="mdi-close-box-multiple"
                                          >
                                            {{
                                              $t(
                                                "formsContents.options.clearAll"
                                              )
                                            }}</v-btn
                                          >
                                        </div>
                                      </div>
                                    </div>

                                    <div class="search-mediabtn1">
                                      <v-btn
                                        block
                                        v-if="
                                          index == multiData.itinery.length - 1
                                            ? true
                                            : false
                                        "
                                        class="search-btn2 m-auto"
                                        :disabled="disButton"
                                        color="#ee2b34"
                                        height="70px"
                                        @click="sendedMulticity()"
                                        style="font-size: 16px"
                                      >
                                        <span>
                                          {{
                                            $t("formsContents.options.search")
                                          }}
                                        </span>
                                      </v-btn>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </v-form>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
      </section>
    </div>

    <div class="pb-2" style="background-color: #d3d3d32e">
      <section class="subcribe-card">
        <div class="container container-styles">
          <div class="d-flex flex-row flex-wrap star-logo">
            <div
              class="col-lg-3 col-md-6 col-12 col-sm-6 d-flex flex-row mt-4"
              v-for="(data1, index) of service"
              :key="index"
            >
              <div class="col-md-3">
                <div
                  class="ms-2"
                  style="
                    padding: 5px;
                    border-radius: 10%;
                    max-width: 80px;
                    box-shadow: none;
                  "
                  v-if="index == 3 ? false : true"
                >
                  <v-img
                    :src="data1.url"
                    max-height="50px"
                    max-width="50px"
                    style="margin: 0 auto"
                    class="logo-img"
                  />
                </div>
              </div>
              <div class="col-md-9 ms-2 d-flex flex-column flex-nowrap">
                <h6>{{ data1.name }}</h6>
                <p style="font-size: 13px">
                  {{ data1.letter }}
                </p>
              </div>
            </div>

            <div class="col-lg-2 col-md-5 col-12 col-sm-6 mt-2 ms-2">
              <div class="ms-sm-0 ms-2" style="padding: 8px; box-shadow: none">
                <v-img class="starRating"
                  :src="require('@/assets/bmttrustscore - Copy.png')"
                  style="margin: 0"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <v-container class="p-0" style="max-width: 1300px !important">
      <section class="p-5">
        <div
          class="container container-styles mt-1 py-1 px-3"
          style="background-color: #ffffffd9"
        >
          <h2 class="display-6 heading">
            {{ $t("homePageContent.contentSection.heading1") }}
          </h2>
          <div class="row mt-3">
            <div
              class="col-lg-3 col-md-6"
              v-for="data of topDestin"
              :key="data"
            >
              <v-card class="mx-auto mb-lg-0 mb-2" max-width="400">
                <v-img :src="data.image_url" cover height="230px" />

                <div
                  class="flex-column"
                  style="
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    font-size: 18px;
                    font-weight: 500;
                  "
                >
                  <div class="d-flex flex-row justify-content-between">
                    <div>
                      {{ data.city }}
                    </div>
                    <div
                      class="mt-1"
                      style="
                        font-weight: 600;
                        font-size: 16px;
                        text-align: center;
                      "
                    >
                      {{ currency }} {{ data.price }}
                    </div>
                  </div>

                  <div
                    class="mt-1 d-flex flex-row justify-content-between align-center"
                  >
                    <div style="font-size: 13px; font-weight: 400">
                      {{ data.country }}
                    </div>
                    <!-- <div style="font-weight: 600;font-size:16px; text-align: center">
                      {{ data.currency }} {{ data.price }}
                    </div> -->

                    <!-- <a :href="data.book_link" style="text-decoration:none;"> -->
                    <div>
                      <a style="text-decoration: none">
                        <v-btn
                          class="top-btn"
                          block
                          color="#ee2b34"
                          height="25px"
                          style="font-size: 11px; font-weight: 700"
                        >
                          <span class="btn-text">
                            {{ $t("homePageContent.contentSection.bookNow") }}
                          </span>
                          <v-img
                            class="btn-img"
                            src="../assets/rightArrow.png"
                            width="35px"
                            height="31px"
                          ></v-img>
                        </v-btn>
                      </a>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <!-- <div
              class="col-lg-3 col-md-6"
              v-for="data of Destination"
              :key="data"
            >
              <v-card class="mx-auto" max-width="400">
                <v-img :src="data.url" cover height="230px" />

                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    font-size: 20px;
                    font-weight: 500;
                  "
                >
                  <div>
                    <div>
                      {{ data.name }}
                    </div>
                    <div style="font-size: 13px; font-weight: 400">
                      {{ data.cou }}
                    </div>
                  </div>

                  <div class="mt-1">
                    <div style="font-weight: 600;font-size:16px; text-align: center">
                      {{ data.rate }}
                    </div>
                    <a :href="data.book_link" style="text-decoration:none;">
                      <v-btn
                        block
                        color="#ee2b34"
                        height="25px"
                        style="font-size: 11px; font-weight: 700"
                      >
                        <span>
                          {{ $t("homePageContent.contentSection.bookNow") }}
                        </span>
                      </v-btn>
                    </a>
                  </div>
                </div>
              </v-card>
            </div> -->
          </div>
        </div>
      </section>
    </v-container>

    <section>
      <div class="container mt-2 py-4" style="position: relative">
        <h2 class="text-center heading">
          {{ $t("homePageContent.contentSection.heading2") }}
        </h2>
        <div class="carousel-slide">
          <!-- <VueSlickCarousel v-bind="settings" :arrows="true" :dots="true"
           :prevArrow="prevArrow"
            :nextArrow="nextArrow"
            :slidesToShow="slidesToShow"
            :slidesToScroll="slidesToScroll">
            <div v-for="data of flightDeals"
                  :key="data">
                  <v-card style="box-shadow: none !important">
                    <v-img
                      :src="data.url"
                      cover
                      height="200px"
                      width="270px"
                      style="margin: 0 auto"
                      class="my-3"
                    />
                  </v-card>
                  <div class="text-start ps-4">
                    <h6>{{ data.name }}</h6>
                    <p class="m-0">{{ data.date }}</p>
                    <div
                      class="row d-flex flex-row justify-content-between my-2"
                    >
                      <div class="col-4">
                        <p class="m-0">{{ data.class }}</p>
                        <p
                          class="m-0"
                          style="font-weight: 600; font-size: 16px"
                        >
                          {{ data.price }}
                        </p>
                      </div>
                      <div class="col-7 d-flex justify-end m-auto">
                        <v-btn
                          style="background-color: #ed242e; color: white"
                          height="33px"
                          >{{
                            $t("homePageContent.contentSection.bookNow")
                          }}</v-btn
                        >
                      </div>
                    </div>
                  </div>
            </div>
          </VueSlickCarousel> -->
          <v-sheet
            class="mx-auto"
            elevation="8"
            max-width="100%"
            style="box-shadow: none !important; background-color: transparent"
          >
            <v-slide-group
              v-model="model1"
              show-arrows
              cycle
              :cycle-interval="3000"
            >
              <v-slide-group-item
                v-for="n in 15"
                :key="n"
                v-slot="{toggle, selectedClass }"
              >
                <v-card
                  class="slide-card1"
                  color="white"
                  :class="['ma-4', selectedClass]"
                  @click="toggle"
                  v-for="data of flightDeals"
                  :key="data"
                  style="box-shadow: 0 0 11px rgba(33, 33, 33, 0.2)"
                >
                  <v-card style="box-shadow: none !important">
                    <v-img
                      :src="data.image_url"
                      cover
                      height="200px"
                      width="270px"
                      style="margin: 0 auto; border-radius: 5px"
                      class="my-4"
                    />
                  </v-card>
                  <div class="text-start ps-4">
                    <h6>{{ data.origin }} to {{ data.destination }}</h6>
                    <p class="m-0">{{ data.startdate }} - {{ data.enddate }}</p>
                    <div
                      class="row d-flex flex-row justify-content-between my-2"
                    >
                      <div class="col-4 pe-0">
                        <p class="m-0">{{ gettransClass(data.class) }}</p>
                        <!-- <p
                          class="m-0"
                          style="font-weight: 600; font-size: 16px"
                        >{{ data.currency }} {{ data.price }}
                        </p> -->
                        <p
                          class="m-0"
                          style="font-weight: 600; font-size: 16px"
                        >
                          {{ currency }} {{ data.price }}
                        </p>
                      </div>
                      <div class="col-7 d-flex justify-end m-auto ps-0">
                        <v-btn
                          style="background-color: #ed242e; color: white"
                          height="33px"
                          >{{
                            $t("homePageContent.contentSection.bookNow")
                          }}</v-btn
                        >
                      </div>
                    </div>
                  </div>

                  <!-- <div class="d-flex fill-height align-center justify-center">
                    <v-scale-transition>
                      <v-icon
                        v-if="isSelected"
                        color="white"
                        size="48"
                        icon="mdi-close-circle-outline"
                      ></v-icon>
                    </v-scale-transition>
                  </div> -->
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
    </section>

    <section class="travel-type mobile p-5">
      <div class="container container-styles">
        <div class="pt-2">
          <!-- <h6 class="trvel-para text-center">TRAVEL TYPE</h6> -->
          <h4 class="display-6 heading text-center">
            {{ $t("homePageContent.contentSection.heading3") }}
          </h4>
        </div>
        <div>
          <v-sheet
            class="mx-auto"
            elevation="8"
            max-width="100%"
            style="box-shadow: none !important; background-color: transparent"
          >
            <v-slide-group
              v-model="model"
              selected-class="bg-success"
              show-arrows
            >
              <v-slide-group-item
                v-for="n in 15"
                :key="n"
                v-slot="{ isSelected, toggle, selectedClass }"
              >
                <v-card
                  class="slide-card"
                  color="grey-lighten-1"
                  :class="['ma-4', selectedClass]"
                  @click="toggle"
                  v-for="data of slide"
                  :key="data"
                >
                  <v-img :src="data.url" cover height="350px" width="258px" />
                  <p class="badge">{{ data.type }}</p>
                  <div class="d-flex fill-height align-center justify-center">
                    <v-scale-transition>
                      <v-icon
                        v-if="isSelected"
                        color="white"
                        size="48"
                        icon="mdi-close-circle-outline"
                      ></v-icon>
                    </v-scale-transition>
                  </div>
                </v-card>
              </v-slide-group-item>
            </v-slide-group>
          </v-sheet>
        </div>
      </div>
    </section>

    <section class="travel-type mobile">
      <div class="container container-styles">
        <div class="pt-1">
          <h4 class="display-6 heading text-center">
            {{ $t("homePageContent.contentSection.heading4") }}
          </h4>
        </div>
        <div class="Fjq1WzSqK01XLJIUrh6P">
          <div class="rNCanQJ9jJ1PhSAOaVJt">
            <a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/turkish-airlines-tk"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/TK.png"
                alt="Turkish Airlines" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/westjet-ws"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/WS.png"
                alt="WestJet" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/qatar-airways-qr"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/QR.png"
                alt="Qatar Airways" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/air-india-ai"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/AI.png"
                alt="Air India" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/egyptair-ms"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/MS.png"
                alt="EgyptAir" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/air-canada-ac"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/AC.png"
                alt="Air Canada" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/british-airways-ba"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/BA.png"
                alt="British Airways" /></a
            ><a
              class="czgJDQ5awfrbTAAiEhGh"
              href="/airlines/american-airlines-aa"
              target="_blank"
              rel="noreferrer"
              ><img
                src="https://assets.wego.com/image/upload/h_64,c_fit,f_auto,fl_lossy,q_auto:low/v20240517/flights/airlines_rectangular/AA.png"
                alt="American Airlines"
            /></a>
          </div>
        </div>
      </div>
    </section>

    <section class="px-5 py-5">
      <h2 class="text-center fw-bold">
        {{ $t("homePageContent.contentSection.heading5") }}
      </h2>
      <div class="row">
        <div
          class="col-md-6 col-sm-6 col-12 col-lg-3 mt-2"
          v-for="data1 of bookingus"
          :key="data1"
        >
          <v-card
            style="
              padding: 15px;
              border-radius: 10%;
              max-width: 300px;
              height: 190px;
              box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
            "
            class="me-0"
          >
            <v-img
              :src="data1.img"
              height="45px"
              width="45px"
              style="margin: 0 auto"
              class="my-2"
            />

            <h6 class="text-center" style="font-size: 18px; font-weight: 600">
              {{ data1.name }}
            </h6>

            <p class="text-center" style="font-size: 13px">
              {{ data1.letter }}
            </p>
          </v-card>
        </div>
      </div>
    </section>
    <!-- <v-container style="max-width: 1300px !important"></v-container> -->
    <div
      class="px-5 mt-2 subscribe-section"
      style="background: lightgrey !important"
    >
      <section>
        <div class="row py-3">
          <div class="col-lg-7 m-auto">
            <div>
              <h3 style="font-size: 28px" class="mb-4">
                {{ $t("homePageContent.contentSection.subcribe") }}
              </h3>
              <p style="font-size: 14px" class="mb-3">
                {{ $t("homePageContent.contentSection.subcribeText") }}
              </p>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12">
                <v-form
                  ref="form"
                  v-model="validData"
                  @submit.prevent="subscribeData"
                >
                  <div
                    class="d-flex subscribe-inputFields"
                    @click="roundedShadow = true"
                    :class="{ 'rounded-shadow': roundedShadow }"
                  >
                    <div style="width: 47%" class="mt-1 ms-1">
                      <v-text-field
                        :label="this.$t('formsContents.labels.email')"
                        variant="solo"
                        v-model="subEmail"
                        :rules="subEmailvalid"
                        @keyup="subData(subEmail)"
                        rounded
                        v-if="emailBox || !emailBox"
                      ></v-text-field>
                    </div>
                    <div
                      v-if="phoneNumber"
                      style="
                        border-right: 1px solid #666666;
                        height: 40px;
                        margin: auto;
                      "
                    ></div>
                    <div style="width: 47%" class="mt-1 ms-1">
                      <v-text-field
                        :label="this.$t('formsContents.labels.phoneNumber')"
                        v-model="subPhonenumber"
                        @keypress="filter(event)"
                        variant="solo"
                        :rules="phoneValidate"
                        maxlength="16"
                        rounded
                        v-if="phoneNumber"
                      ></v-text-field>
                    </div>
                    <v-btn
                      class="subscribe-button m-1 ms-2"
                      variant="tonal"
                      type="submit"
                      :disabled="loadingData"
                    >
                      <span
                        ><v-icon color="white" style="transform: rotate(45deg)"
                          >mdi-arrow-top-right</v-icon
                        ></span
                      >
                    </v-btn>
                  </div>
                </v-form>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-lg-6 radio-switch d-flex align-center">
                <v-switch
                  v-model="phoneNumber"
                  :color="phoneNumber ? 'success' : ''"
                  @click="this.phoneNumber = !this.phoneNumber"
                  style="flex: none"
                ></v-switch>
                <span
                  style="font-size: 14px; font-weight: 600; padding-left: 5px"
                  >{{ $t("homePageContent.contentSection.sendWhatsapp") }}</span
                >
              </div>

              <div
                class="col-lg-6 mt-1 d-flex justify-end captcha_section"
                id="visible"
                style="cursor: pointer"
              >
                <div class="d-flex">
                  <div
                    class="me-2"
                    style="
                      display: flex;
                      background-color: #213d77;
                      height: 40px;
                      width: 150px;
                      border-radius: 5px;
                    "
                  >
                    <canvas
                      ref="myCanvas"
                      width="150"
                      height="50"
                      style="position: relative; top: -6px"
                    ></canvas>
                    <span @click="generate()" style="margin: auto">
                      <v-icon color="white" size="small">mdi-reload</v-icon>
                    </span>
                  </div>

                  <div style="width: 117px">
                    <v-text-field
                      label="Enter Captcha"
                      variant="solo-filled"
                      v-model="cacheData"
                      @keyup="keyData(cacheData)"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class=" d-flex  justify-content-center align-center mt-2">
              <v-btn class="subscribe-btn" variant="tonal" @click="subscribeData()">Subscribe</v-btn>
            </div> -->

            <div class="">
              <span style="font-size: 14px">{{
                $t("homePageContent.contentSection.subcribeAgree")
              }}</span>
              <!-- <p class="agree-error" >{{ agreeMsg }}</p> -->
            </div>
          </div>

          <div class="col-lg-5">
            <v-img
              :src="require('@/assets/subscribe4.png')"
              width="80%"
              style="margin: 0 auto"
            ></v-img>
          </div>
        </div>
      </section>
    </div>

    <!-- 
  <section>
      <div class="container container-styles subscribe py-4 my-3" style="background-color:white">
        <div class="row">
          <div>
            <h3 class="heading text-center sub-text">
              Subscribe To Our Newsletter & Get exciting offers
            </h3>
            <p style="font-size: 14px; color:rgba(255, 255, 255, 0.9);" class="text-center">
              Keep yourself updates with latest travels news and ongoing promotions. We promise no spamming and we won't
              share your details with any 3rd party.
            </p>
          </div>

          <div class="row justify-center m-sm-auto m-auto">
            <div class="col-sm-4 col-12">
              <div class="col-lg-12 radio-switch d-flex align-center" style="margin-right: 25px;margin-bottom: 3px;">
                <v-switch color="sucess" :model-value="false" @click="subRadio()" style="flex: none;"></v-switch>
                <span style="font-size: 14px; font-weight: 600; padding-left: 5px;">Send me on Whatsapp</span>
              </div>
            </div>
          </div>
          <div class="row mt-2 justify-center m-sm-auto m-auto">
            <div class="col-sm-4 col-12">
              <v-text-field label="Email" variant="solo"></v-text-field>
            </div>
          </div>
          <div class="row gy-2 justify-center m-sm-auto m-auto" v-if="phoneNumber">
            <div class="col-sm-4 col-12">
              <v-text-field label="Phone Number" variant="solo" type="number" maxlength="12"></v-text-field>
            </div>
          </div>

          <div class=" d-flex  justify-content-center align-center mt-2">
            <v-btn class="subscribe-btn" variant="tonal" @click="subscribeData()">Subscribe</v-btn>
          </div>

          <div class=" d-flex flex-colunm  justify-content-center align-center mt-2">

            <div>
              <v-checkbox v-model="subscribe.checkAgree" @change="clearAgreeError" style="flex: none;"></v-checkbox>
              <span class="agree-content " style="color:rgba(255, 255, 255, 0.9);position: relative;
    top: -35px;
    left: 37px;">I agree to receive
                promotional messages and updates from MyAirBudget.</span>
              <p class="agree-error">{{ agreeMsg }}</p>
            </div>
          </div>






        </div>
      </div>
 
</section> -->
  </div>

  <div class="whatts-logo" v-if="pluginWhatspp">
    <!-- <a href="https://api.whatsapp.com/send?phone=919565788237" target="_blank"> -->
    <a :href="whatsappApi" target="_blank">
      <v-img :src="require('@/assets/whatts.png')" width="70px"
    /></a>
  </div>
  <div v-if="loader">
    <dataloader></dataloader>
  </div>

  <v-dialog v-model="dialog1">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>Your request cannot be processed at this moment.</h5>
        <h6>Please contact customer support at {{ emailPortal }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="(dialog1 = false), (loader = false)" color="#ee2b34"
            >Try Again</v-btn
          >

          <v-btn to="/" color="#ee2b34">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h5>
          Your request has been received. Our customer support team will get
          back to you at the soonest possible.
        </h5>

        <div class="d-flex justify-center mt-3">
          <v-btn
            @click="
              (subEmail = null), (subPhonenumber = null), (dialog2 = false)
            "
            color="#ee2b34"
            >Ok</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-dialog>

  <!-- <div v-if="showCookieInfo" class="cookie-info">
      <p>
        Third-party cookies are not required for this website to function properly. 
        Please enable the "Block third-party cookies" option in your browser settings to continue.
      </p>
      <button class="text-dark" @click="closeCookieInfo">Got it</button>
    </div> -->

  <!-- <component :is="selectedFooter" /> -->
</template>

<script>
import moment from "moment";
// import multicityCom from "@/components/multicityCom.vue";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import AutoComplete from "primevue/autocomplete";
import dataloader from "@/components/dataLoder.vue";
// import userData from '@/airport_icao.json';
// import userData from "@/airlinesLogo.json";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    // multicityCom,
    VueDatePicker,
    // AutoComplete,
    dataloader,
    // VueSlickCarousel
  },

  data() {
    return {
      focused: false,
      focusedTo:false,
      focusedMulti:[],
      focusedMultiTo:[],
      fromval: true,
      fromval1: true,
      multiVal: true,
      inputFocus: true,
      model: null,
      model1: null,
      currency: "",
      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      isAutoCompleteFocused: [],
      countries: [{ name: "Australia", code: "AU" }],

      imgItems: [
        {
          src: require("@/assets/homesl1.png"),
        },
        {
          src: require("@/assets/homesl2.png"),
        },
        {
          src: require("@/assets/homesl3.png"),
        },
        {
          src: require("@/assets/homesl4.png"),
        },
        {
          src: require("@/assets/homesl5.png"),
        },
      ],

      // classTypeitems: ['Economy', 'Business', 'First Class'],

      fromlabel: false,
      tolabel: false,
      loader: false,
      dialog1: false,
      dialog2: false,
      dateFormat: "dd MMM",
      sixWeekMode: true,
      classSelect: false,
      subEmail: "",
      subPhonenumber: "",
      multiCalender: true,
      validData: false,
      selectedCountry: "",
      maximumletter: false,
      maximumletter1: false,
      fromDate: "",
      name1: "John Doe",
      source: "vasanth",
      trav: true,
      loginbox: false,
      registerbox: false,
      terms: false,
      visible: false,

      // showCookieInfo: false,

      selectAirports: "",
      red: "1",
      red1: 1,
      rount: true,
      Oneway: false,
      multicity: false,
      // select: "Economy",
      travels: false,
      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      classType: "Economy",
      classTypeitems: [
        {
          text: this.$t("homePageContent.contentSection.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("homePageContent.contentSection.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("homePageContent.contentSection.firstclass"),
          value: "First Class",
          department: "First Class Department",
        },
        // { text: this.$t('homePageContent.contentSection.premiumEconomy'), value: 'Premium Economy', department: 'Premium Economy Department' },
      ],
      addition: 0,
      customer: [],
      buton: true,
      avath: false,
      error: false,
      viewbox: false,
      isVisible: true,
      // selectAirports: "",
      token: "Tc7RQaX79Hrx5CFK",

      // Autocomplete
      loading: false,
      fromAirports: [],
      airitems: [],
      // Autocomplete End

      items: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,
      valid: false,
      Errormsg: "",
      agreeMsg: "",
      travelDate: "",
      subscribe: {
        email: "",
        phone: "",
        checkAgree: false,
      },

      roundedShadow: false,
      emailBox: false,
      phoneNumber: true,

      subEmailvalid: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      phoneValidate: [
        (v) => /^\+?[0-9]{1,16}$/.test(v) || "Please enter a phone number ",
        // (v) => /^(\+?[0-9]{1,12})?$/.test(v) || "Please enter a valid phone number (up to 12 digits, starting with +)",
      ],

      input: [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ],

      addmore: true,
      clearall: false,
      fromloader:false,
      toloader:false,

      oneway: {
        from: null,
        to: null,
        travelDate: [
          new Date(new Date().setDate(new Date().getDate() + 2)),
          new Date(new Date().setDate(new Date().getDate() + 2 + 20)),
        ],
        // travelDate: new Date(new Date().setDate(new Date().getDate() + 2)),
        dedate: new Date(new Date().setDate(new Date().getDate() + 2)),
        redate: new Date(new Date().setDate(new Date().getDate() + 22)),
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            departDate: new Date(new Date().setDate(new Date().getDate() + 2)),
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            inputFocus1: true,
            fromval1: true,
            autocomfrom: false,
            autocomTo: false,
            fromloader: false,
            toloader: false,
          },
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            inputFocus1: true,
            fromval1: true,
            autocomfrom: false,
            autocomTo: false,
            fromloader: false,
            toloader: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      array: [
        {
          name: "keerthi",
          age: 90,
        },
        {
          name: "keerthi",
          age: 90,
        },
      ],

      Adult: [],
      Child: [],
      recendFrom: [],
      recendTo: [],
      resultFrom: [],
      resultTo: [],
      valid1: false,
      forData: false,
      formres: false,
      formres1: false,
      Infrant: [],
      ailenelogosh: [],
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      concatenatedArray: [],
      loadingData: true,
      dataloaded: false,
      randomAlphabets: "",
      cacheData: "",
      fromRecent: "",
      toRecent: "",
      multi: {
        from: null,
        to: null,
      },
      deatail: {
        name: "",
        email: "",
        password: "",
        repassword: "",
      },
      loginuser: {
        email: "",
        password: "",
      },
      userName: [(v) => !!v || "Please enter your name"],
      email: [(v) => !!v || "Please enter your Email"],
      password: [(v) => !!v || "Please enter your Password"],
      repassword: [
        (v) => !!v || "Please enter your Re-Password",
        (v) => v === this.deatail.password || "Passwords do not match",
      ],
      pnr: [(v) => !!v || "Please enter your PNR/Booking Ref no"],
      emaillast: [(v) => !!v || "Please enter your Email/Lastname"],
      Destination: [
        {
          name: "Andaman",
          rate: "$480",
          url: require("@/assets/andaman.jpg"),
          cou: "INDIA",
        },
        {
          name: "Colombo",
          rate: "$890",
          url: require("@/assets/SriLanka.jpg"),
          cou: "SriLanka",
        },
        {
          name: "Dubai",
          rate: "$2310",
          url: require("@/assets/DUBAI.jpg"),
          cou: "UAE",
        },
        {
          name: "Bangkok",
          rate: "$760",
          url: require("@/assets/Thailand.jpg"),
          cou: "Thailand",
        },
      ],

      service: this.getService(),
      bookingus: this.getbookingUs(),
      // flightDeals: this.getflightDeals(),

      Airlogosh: [
        {
          url: require("@/assets/aircanada.png"),
        },
        {
          url: require("@/assets/airindia1.png"),
        },
        {
          url: require("@/assets/british1.png"),
        },
        {
          url: require("@/assets/emirats1.png"),
        },
        {
          url: require("@/assets/vistara.png"),
        },
      ],

      profile: [
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Alex Feder",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Elly Forb",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Mia Nest",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Dan Dorno",
        },
      ],

      slide: [
        {
          url: require("@/assets/slide1.jpg"),
          type: "Exploration",
        },
        {
          url: require("@/assets/slide2.jpg"),
          type: "Vacation",
        },
        {
          url: require("@/assets/slide3.jpg"),
          type: "Business",
        },
        {
          url: require("@/assets/slide4.jpg"),
          type: "Journey",
        },
      ],
      // flightDeals: [
      //   {
      //     url: require("@/assets/lakshadweep-Islands.jpg"),
      //     name: "Toronto to Montego Bay",
      //     date: "25 May 2024 - 08 Jun 2024",
      //     class: "Economy",
      //     price: "$1210",
      //   },
      //   {
      //     url: require("@/assets/shillong.jpg"),
      //     name: "Calgery to Vietnam",
      //     date: "10 Jul 2024 - 27 Aug 2024",
      //     class: "First Class",
      //     price: "$980",
      //   },
      //   {
      //     url: require("@/assets/Nainital.jpg"),
      //     name: "Vancouver to Colombo",
      //     date: "05 Jun 2024 - 13 Sep 2024",
      //     class: "Business",
      //     price: "$750",
      //   },
      //   {
      //     url: require("@/assets/Manali.jpg"),
      //     name: "Canada to Toronto",
      //     date: "14 Mar 2024 - 22 Oct 2024",
      //     class: "Economy",
      //     price: "$1450",
      //   },
      //   {
      //     url: require("@/assets/delhi.webp"),
      //     name: "New Delhi to London",
      //     date: "01 Sep 2024 - 31 Nov 2024",
      //     class: "First Class",
      //     price: "$1870",
      //   },
      // ],

      airlineIcao: {},

      // uniqueId:[],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      focusedElement: null,
      changeTextFrom: "",
      changeTextTo: "",
      focusEvent1: false,
      focusEvent2: false,
      focusEvent3: false,
      dateUnfocus: false,
      closeDateMenu: false,

      focusFalse: true,

      pluginWhatspp: false,
      whatsappApi: "",
      clickedIndex: null,

      //  Top Destinations
      topDestin: [],

      // Flight Deals
      flightDeals: [],
    };
  },

  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },

  methods: {
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    selectClasstype(item) {
      this.classType = item.value;
    },

    getService() {
      return [
        {
          url: require("@/assets/bestpricepromise.png"),
          name: this.$t("homePageContent.hightlightContent.heading1"),
          letter: this.$t("homePageContent.hightlightContent.subHead1"),
        },
        {
          url: require("@/assets/getgreatedeals.png"),
          name: this.$t("homePageContent.hightlightContent.heading2"),
          letter: this.$t("homePageContent.hightlightContent.subHead2"),
        },
        {
          url: require("@/assets/expertguide.png"),
          name: this.$t("homePageContent.hightlightContent.heading3"),
          letter: this.$t("homePageContent.hightlightContent.subHead3"),
        },
      ];
    },

    getbookingUs() {
      return [
        {
          img: require("../assets/airplane (1).png"),
          name: this.$t("homePageContent.whybookContent.heading1"),
          letter: this.$t("homePageContent.whybookContent.content1"),
        },
        {
          img: require("../assets/low-price.png"),
          name: this.$t("homePageContent.whybookContent.heading2"),
          letter: this.$t("homePageContent.whybookContent.content2"),
        },
        {
          img: require("../assets/discount.png"),
          name: this.$t("homePageContent.whybookContent.heading3"),
          letter: this.$t("homePageContent.whybookContent.content3"),
        },
        {
          img: require("../assets/24-hours.png"),
          name: this.$t("homePageContent.whybookContent.heading4"),
          letter: this.$t("homePageContent.whybookContent.content4"),
        },
      ];
    },

    getflightDeals() {
      return [
        {
          url: require("@/assets/lakshadweep-Islands.jpg"),
          name: "Toronto to Montego Bay",
          date: "25 May 2024 - 08 Jun 2024",
          class: this.$t("homePageContent.contentSection.economy"),
          price: "$1210",
        },
        {
          url: require("@/assets/shillong.jpg"),
          name: "Calgery to Vietnam",
          date: "10 Jul 2024 - 27 Aug 2024",
          class: this.$t("homePageContent.contentSection.firstclass"),
          price: "$980",
        },
        {
          url: require("@/assets/Nainital.jpg"),
          name: "Vancouver to Colombo",
          date: "05 Jun 2024 - 13 Sep 2024",
          class: this.$t("homePageContent.contentSection.business"),
          price: "$750",
        },
        {
          url: require("@/assets/Manali.jpg"),
          name: "Canada to Toronto",
          date: "14 Mar 2024 - 22 Oct 2024",
          class: this.$t("homePageContent.contentSection.economy"),
          price: "$1450",
        },
        {
          url: require("@/assets/delhi.webp"),
          name: "New Delhi to London",
          date: "01 Sep 2024 - 31 Nov 2024",
          class: this.$t("homePageContent.contentSection.firstclass"),
          price: "$1870",
        },
      ];
    },

    gettransClass(className) {
      const formattedClassName = className.replace(/\s+/g, "").toLowerCase();
      // console.log(formattedClassName,'getclassgetclassgetclass')
      return this.$t(`homePageContent.contentSection.${formattedClassName}`);
    },

    // closeCookieInfo() {
    //     this.showCookieInfo = false;
    //   },

    getrecent(data) {
      // console.log(data, "pppp");
      this.oneway.from = data.from;
      this.fromRecent = data.from;
      this.inputCity = data.from;
      // this.oneway.from= $data[$data.length - 1].replace(")", '')
    },

    getrecent1(data) {
      this.oneway.to = data.to;
      this.toRecent = data.to;
      this.inputCityto = data.to;
      //
    },

    getrecentData(data) {
      // console.log(data, "ahahakkaaj");
      let res = data.split(",");
      let result = "";
      res.forEach((v, i) => {
        if (i !== res.length - 1) result += v + ",";
      });
      return result.slice(0, -1);
    },

    generateRandomAlphabets() {
      const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * alphabets.length);
        result += alphabets[randomIndex];
      }

      this.randomAlphabets = result;
      this.updateCanvas();
    },
    updateCanvas() {
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.font = "20px Arial";
      ctx.fillStyle = "white";
      ctx.fillText(this.randomAlphabets, 10, 40);
    },
    generate() {
      this.generateRandomAlphabets();
    },
    keyData($data) {
      if (this.randomAlphabets == $data.toUpperCase()) {
        this.loadingData = false;
      } else {
        this.loadingData = true;
      }
    },
    subData($data) {
      console.log($data, "tytttt");
      if ($data.includes("com")) {
        document.getElementById("visible").style.visibility = "visible";
        this.dataloaded = true;
      } else {
        this.dataloaded = false;
      }
    },
    disableText(isFocused) {
      this.inputFocus = isFocused;
      // if(this.oneway.from == null){
      //   this.inputFocus = true
      // }
      // else{
      //   this.inputFocus = false
      // }
    },
    disableText1(isFocused) {
      this.fromval = isFocused;
    },
    disableTextMulti(isDisabled, index) {
      console.log(isDisabled, index, "indexxxx1");
      this.multiData.itinery.forEach((data, i) => {
        data.inputFocus1 = i === index ? isDisabled : true;
      });
    },

    disableTextMultiTo(data1, index) {
      this.multiData.itinery.forEach((data, i) => {
        data.fromval1 = i === index ? data1 : true;
      });
    },

    focusAutocomplete(element) {
      this.focusedElement = element;

      if (this.focusedElement === "from") {
        this.formres = true;
        this.formres1 = false;

        this.focusEvent1 = true;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.$refs.fromAutoComplete.$el.querySelector("input").focus();
      } else if (this.focusedElement === "to") {
        this.formres1 = true;
        this.formres = false;
        this.focusEvent3 = false;
        this.focusEvent1 = false;
        this.focusEvent2 = false;

        this.$nextTick(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        });
      }
    },

    focusMulti_AutoComplete(element, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (!this.focusFalse) {
            this.focusedElement = null;
            v.focusMultiEvent3 = false;
          } else {
            this.focusedElement = element;
          }
        }
      });

      this.multiData.itinery.map((v, i) => {
        v.disableTextFrom =
          this.focusedElement === "from" && index === i ? false : true;
        v.disableTextTo =
          this.focusedElement === "to" && index === i ? false : true;
      });

      if (this.focusedElement === "from") {
        this.multiData.itinery.map((v, i) => {
          // console.log(v,i,index,'mimimimi...1.')
          if (i == index) {
            v.formres = true;
            v.formres1 = false;
            const inputElement =
              this.$refs.fromAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          }
        });
      } else if (this.focusedElement === "to") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            const inputElement =
              this.$refs.secondAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          }
        });
      } else if (this.focusedElement == "calendar1") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            }
            // v.$refs.fromAutoComplete.$el.querySelector("input").focus();

            // v.$nextTick(() => {
            //   this.$refs.secondAutoComplete.$el.querySelector("input").focus();
            // })
          }
        });
      }
    },

    focusCalendar(element) {
      if (!this.focusFalse) {
        this.focusedElement = null;
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
        this.$refs.dpRef1.closeMenu();
      } else {
        this.focusedElement = element;
      }

      if (this.focusedElement == "calendar1") {
        this.$nextTick(() => {
          this.$refs.dpRef1.openMenu();
          this.focusEvent3 = true;
          this.focusEvent2 = false;
          this.focusEvent1 = false;
          this.formres = false;
          this.formres1 = false;
        });
      } else {
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
      }
    },
    
            datachange(data) {
                console.log(data, "pppp");
                this.oneway.from = `${data.city} (${data.iata})`;
                this.inputCity = this.oneway.from;
                this.typedrop = false;
            },
            datachangeTo(data) {
                this.oneway.to = `${data.city} (${data.iata})`;
                this.inputCityto = this.oneway.to;
                this.typedrop1 = false;
            },
            datachangeMuti(data, index) {
                console.log(data, index, "data,index1");
                this.multiData.itinery.map((v, i) => {
                    if (i == index) {
                        v.from = `${data.city} (${data.iata})`;
                        v.autocomfrom = false;
                    }
                });
                console.log(this.multiData, "this.multiDatathis.multiData");
            },
            datachangeMutiTo(data, index) {
                console.log(data, index, "data,index1");
                this.multiData.itinery.map((v, i) => {
                    if (i == index) {
                        v.to = `${data.city} (${data.iata})`;
                        v.autocomTo = false;
                    }
                });

                for (let i = 1; i < this.multiData.itinery.length; i++) {
                    this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
                }
                console.log(this.multiData, "this.multiDatathis.multiData");
            },

    handleOutsideClick(event) {
      // if (!this.$el.contains(event.target)) {
      //   this.inputFocus = false;
      // }
      if (!event.target.closest(".input-styles")) {
        this.typedrop = false;
        this.typedrop1 = false;
        this.formres = false;
        this.formres1 = false;
        this.focusedElement = null;
        this.focusEvent1 = false;
        this.focusEvent2 = false;
        this.focusEvent3 = false;
        // this.inputFocus = true;
        // this.fromval = true;

        this.multiData.itinery.map((v) => {
          // console.log(v, i, "focusMultiEvent3focusMultiEvent3");
        //   v.focusMultiEvent1 = false;
        //   v.focusMultiEvent2 = false;
        //   v.focusMultiEvent3 = false;
        v.autocomfrom = false;
        v.autocomTo = false;
        });
      }

      if (!event.target.closest(".subscribe-inputFields")) {
        this.focusedElement = null;
        this.roundedShadow = false;
      }
    },
    // subRadio() {

    //   this.phoneNumber = !this.phoneNumber
    // },
    adulted() {
      this.Adult.push({
        PassengerID: "T",
        PTC: "ADT",
      });
      //console.log(this.Adult, 'add')
    },
    adulted1() {
      this.Adult.pop();
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        PassengerID: "T",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
    },
    Infranted1() {
      this.Infrant.pop();
    },

    act(data) {
      this.red1 = data;
      //console.log(this.red1, '00')
      this.oneway.city = this.red1;
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
      }
      if (data == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
      }
      if (data == 3) {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
      }
    },
    roundData() {
      let result = "";
      result = localStorage.getItem("rountData");

      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;
      } else {
        this.oneway.from = result1.from;
      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;
      } else {
        this.oneway.to = result1.to;
      }
      this.oneway.dedate = moment(result1.dedate).format("DD MMM-YYYY");

      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");

      this.oneway.class = result1.class;

      this.aduls = result1.adult;

      this.aduls2 = result1.infrant;

      this.aduls1 = result1.child;

      this.select = result1.class;
    },

    addTrip() {
      if (this.multiData.itinery.length < 4) {
        this.multiData.itinery.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          multiSearchBtn: true,
          focusMultiEvent1: false,
          focusMultiEvent2: false,
          focusMultiEvent3: false,
          inputFocus1: true,
          fromval1: true,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        });

        if (this.multiData.itinery.length == 4) {
          this.addmore = false;
        }
      }

      // if(this.multiData.itinery.length-1){
      //   this.multiSearchBtn = true
      // }

      if (this.multiData.itinery.length > 2) {
        this.clearall = true;
      }

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }

      // for (let i = 0; i <= this.multiData.length - 1; i++) {
      //     //console.log(this.multiData.length,'this.multiData.length')
      //     if(this.multiData.length==2){
      //       this.multiData[1].from = this.multiData[0].to
      //     }
      //     else if(this.multiData.length==3){
      //       this.multiData[2].from = this.multiData[1].to
      //     }
      //     else if(this.multiData.length==4){
      //       this.multiData[3].from = this.multiData[2].to
      //     }
      //   }
      //console.log(this.multiData, 'ooooo')
    },

    clearall1() {
      this.multiData.itinery.splice(2, 5);
      (this.multiData.itinery = [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
          inputFocus1: true,
          fromval1: true,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
          inputFocus1: true,
          fromval1: true,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },
    delet(index) {
      this.multiData.itinery.splice(index, 1);
    },

    deg1() {
      this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls += 1;
        //console.log(this.aduls, '1111')

        this.adulted();
        this.added();
      }

      // //console.log( this.Adult,'concatenatedArray')
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        this.Childed1();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;
        //console.log(this.aduls1, '2222')

        this.Childed();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;
        //console.log(this.aduls2, '3333')

        this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    // selectClasstype() {
    //   this.classSelect = !this.classSelect;
    //   this.$refs.mySelect.focus();
    //   // console.log(this.classSelect, 'ffffaaaallllssseeee')
    //   if (!this.classSelect) {
    //     this.$refs.countrySelect.focus();
    //   }
    // },

    closeDropdown() {
      this.$refs.countrySelect.blur();
      // this.$refs.mySelect.blur();
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;
      // this.$refs.dpRef2.openMenu();
      this.focusedElement = "calendar2";

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
        this.$refs.dpRef1.openMenu();

        // setTimeout(() => {
        //   this.$refs.dpRef2.closeMenu();
        // }, 10)
      }
    },

    clearDate() {
      this.oneway.dedate = null;
      this.oneway.travelDate = null;
      this.focusEvent3 = false;
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();
      // console.log(v.clicked3,"checking....")

      if (this.oneway.dedate) {
        this.clicked1 = true;
        // this.fromlabel=true
      } else {
        this.clicked1 = false;
        // this.fromlabel=false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
        this.focusedElement = null;

        // this.tolabel=true
      } else {
        this.clicked2 = false;
        this.toDateError = true;
        // this.tolabel=false;/
        this.$refs.dpRef2.openMenu();
      }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();

      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;
      //console.log(this.oneway.dedate, "kkkkkkkkk");

      // this.$refs.select1.focus();

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },
    // Oneway datePicker End

    // Multicity datepicker

    multiDateChanged(newValue, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (v.departDate) {
            v.focusMultiEvent3 = false;
          } else {
            v.focusMultiEvent3 = true;
          }
        }
      });

      this.multiData.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },
    isFocused(){
        this.focused = true;
    },
    onBlur(){
        this.focused = false
    },
    isFocusedTo(){
        this.focusedTo = true;
    },
    onBlurTo(){
        this.focusedTo = false
    },
    isFocusedMulti(index){
        this.focusedMulti[index] = true
    },
    onBlurMulti(index){
        this.focusedMulti[index] = false
    },
    isFocusedMultiTo(index){
        this.focusedMultiTo[index] = true
    },
    onBlurMultiTo(index){
        this.focusedMultiTo[index] = false
    },

    getdate1($data, index) {
      if (index > 0) {
        return $data[index - 1].departDate;
      } else {
        return `${new Date()}`;
      }
    },

    multiClickOutside() {
      this.input.clicked3 = false;
      this.datePickerIsOpen = false;
    },

    sended() {
      this.focusFalse = false;
      this.inputCity =
        this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      this.inputCityto =
        this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);

      console.log(this.focusEvent3, "focusEvent3focusEvent3");

      this.$nextTick(() => {
        this.focusEvent3 = false;
        this.$refs.dpRef1.closeMenu();
        console.log(this.focusEvent3, "this.focusEvent3this.focusEvent3");
      });

      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      // console.log(this.oneway, 'oneway.classoneway.class')
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        setTimeout(() => {
          // this.$refs.dpRef1.closeMenu();
          this.focusEvent3 = false;
          // console.log(this.$refs.dpRef1, 'fdfdfdfdfdf...1')
        }, 5);

        let result3 = [];
        let result2 = [];
        result3 = JSON.parse(localStorage.getItem("recentFrom"));
        let obj = {
          from: this.inputCity,
          to: this.inputCityto,
          fromDate: this.oneway.dedate,
          toDate: this.oneway.redate,
          adult: this.aduls,
          child: this.aduls1,
          inf: this.aduls2,
          city: this.red1,
          class: this.classType,
        };

        // console.log(obj,'pppppppp')

        this.recendFrom.unshift(obj);

        if (result3 == null) {
          // console.log('ppp')
        } else {
          result2 = [...this.recendFrom, ...result3];
        }

        localStorage.setItem("recentFrom", JSON.stringify(result2));
        // console.log(  this.$store.state.recentForm,'  this.$store.state.recentForm')

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else {
          this.roundto = this.oneway.to;
        }
        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY-MM-DD");
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        console.log(rountdetail, "rountdetailrountdetail");

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        // alert("please enter your detail");
        this.validateOnewayForm();
      }
    },

    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;

        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);
        let fromData = "";
        let toData = "";

        if (this.oneway.from.name) {
          fromData = this.oneway.from.name;
        } else {
          fromData = this.oneway.from;
        }

        if (this.oneway.to.name) {
          toData = this.oneway.to.name;
        } else {
          toData = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");

        // console.log(this.oneway, ' this.oneway this.oneway this.oneway this.oneway this.oneway')
        let rountdetail = {
          from: fromData,
          to: toData,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedMulticity() {
      this.focusFalse = false;
      console.log(!this.multiData.itinery[2], "ppppptttttttttt");

      if (
        this.multiData.itinery[0].from &&
        this.multiData.itinery[0].to &&
        this.multiData.itinery[0].departDate &&
        this.multiData.itinery[1].from &&
        this.multiData.itinery[1].to &&
        this.multiData.itinery[1].departDate &&
        (!this.multiData.itinery[2] ||
          (this.multiData.itinery[2].from &&
            this.multiData.itinery[2].to &&
            this.multiData.itinery[2].departDate)) &&
        (!this.multiData.itinery[3] ||
          (this.multiData.itinery[3].from &&
            this.multiData.itinery[3].to &&
            this.multiData.itinery[3].departDate))
      ) {
        this.multiData.adult = this.aduls;
        this.multiData.child = this.aduls1;
        this.multiData.infrant = this.aduls2;
        this.multiData.class = this.classType;
        let $data = [];
        this.multiData.itinery.forEach((v) => {
          console.log(v, "multy");
          let itineraryObject = {
            from: v.from,
            to: v.to,
            depdata: moment(v.departDate).format("DD-MMM-YYYY"),
          };
          $data.push(itineraryObject);
        });
        console.log($data, " $data $data");

        this.multiData.itinery = $data;

        console.log(this.multiData, "this.multiData");
        let muti_city = JSON.stringify(this.multiData);
        this.$router.push({ path: "/search", query: { muti_city } });
      } else {
        // alert("please enter your detail");
      }
    },

    // sendedMulticity() {

    //   for (let i = 0; i < this.input.length; i++) {

    //     if (this.input[i].from) {
    //       this.input[i].multiFromError = false;
    //     } else {
    //       this.input[i].multiFromError = true;
    //     }

    //     if (this.input[i].to) {
    //       this.input[i].multiToError = false;
    //     } else {
    //       this.input[i].multiToError = true;
    //     }

    //     if (this.input[i].departDate) {
    //       this.input[i].multiDateError = false;
    //     } else {
    //       this.input[i].multiDateError = true;
    //     }
    //   }

    // },

    // submit() {
    //   //console.log(this.valid, '9999')
    //   if (this.valid) {
    //     loginService.createUser(this.deatail).then(res => {
    //       //console.log(res, '88888')
    //     })
    //   }
    // },

    login() {
      let user1 = this.customer.findIndex(
        (v) => v.email === this.loginuser.email
      );
      //console.log(user1, '23442234')
      let user2 = this.customer.findIndex(
        (v) => v.password === this.loginuser.password
      );
      //console.log(user2, '23442234')
      if (user1 === 0 && user2 === 0) {
        this.avath = true;
        this.buton = false;
      } else {
        this.avath = false;
        this.buton = true;
        this.error = true;
      }
    },

    onChangeMultiFrom(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
            v.maximumletter = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
            v.maximumletter1 = true;
          } else {
            v.multiToError = true;
            v.maximumletter1 = false;
          }
        }
      });

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }
    },

    // console.log($data, index,'$data, index$data, index')
    // let data1=$data
    // for(let i=0;i<$data.length;i++){
    //     console.log(data1[i],'v[i].departDate')
    //     for(let j=0;j<data1.length;j++){
    //      if($data[i].departDate > data1[j].departDate || $data[i].departDate > data1[j].departDate){
    //        return $data[i-1].departDate=null
    //        console.log(data1[j].departDate,'data1[j].departDatedata1[j].departDate')
    //      }
    //     }
    // }

    // $data.forEach((v,i)=>{
    //     console.log(v[i],'v[i].departDate')
    //     if(v[i].departDate > v[i+1].departDate){

    //        return $data.departDate.splice(v[i],6)
    //     }
    //     else{
    //         return v[i].departDate
    //     }
    // })

    //      if (index == 0 && (($data[index].departDate > $data[index + 1].departDate) || (!$data[index + 1].departDate))) {
    //         console.log("Partially WOrkinggg...1.")
    //         $data[index + 1].departDate = null
    //     }

    //     else if ($data[index].departDate > $data[index + 1].departDate) {
    //             $data[index + 1].departDate = null
    //             console.log("Partially WOrkinggg...2.")
    //         $data[index].departDate.splice()
    //     }

    //     else{
    //     console.log('Not workinfgkkkk')
    // }

    // getdate1($data, index) {
    //     if (index > 0) {
    //         return $data[index - 1].departDate
    //     }
    //     else {
    //         return `${new Date}`
    //     }
    // },

    // multiClickOutside(index) {
    //     console.log(index,"checking....")

    //     this.multiData.clicked3 = false
    //     this.datePickerIsOpen = false;

    //     this.multiData.map((v, i) => {
    //         if (index == i) {
    //             console.log(i,v,"checking...1.")
    //             this.$refs.dpRef4.closeMenu();
    //         }
    //     })
    // },

    multiFromClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;
            if (!v.from) {
              v.multiFromError = true;
            } else {
              v.multiFromError = false;
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multiData.itinery.map((v, i) => {
        this.clickedIndex = index;
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;
            v.multiVal = true;
            if (!v.to) {
              v.multiToError = true;
              v.multiVal = true;
            } else {
              v.multiToError = false;
              v.multiVal = false;
            }
          }
        }
      });
    },

    multifocus1(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.to) {
            setTimeout(() => {
              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement =
                this.$refs.fromAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },

    multifocus2(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.departDate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }

          // for (let i = 0; i < 1; i++) {
          //   console.log(this.multiData[0].to, 'bfbfbfbf.....')
          //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
          // }
        }
      });
      

      // setTimeout(() => {
      //   if (this.$refs.dpRef4.length > 0) {
      //     const inputElement = this.$refs.dpRef4[index];
      //     if (inputElement) {
      //       inputElement.openMenu();
      //     }
      //   }

      // for (let i = 0; i < 1; i++) {
      //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
      //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
      // }
    },
    
            async typeCity(event, $data) {
                if (event.length >= 1) {
                    this.formres = false;
                    if (event.length >= 1) {
                        this.formres1 = false;
                    }
                }
                this.city = [];
                this.city1 = [];
                // let $event = [];
                console.log(event, $data, "searchsearchsearchsearch");
                if ($data == 1 && event.length > 0) {
                    this.fromloader = true;
                } else {
                    this.fromloader = false;
                }

                if ($data == 2 && event.length > 0) {
                    this.toloader = true;
                } else {
                    this.toloader = false;
                }

                if (event.length >= 3) {
                    await axios
                        .get(
                            `${"https://dl.buymytrip.com/api/airportautocomplete?airportString"}=${event}`, {
                                headers: {
                                    "Content-Type": this.airlineIcaoType,
                                },
                            }
                        )
                        .then((response) => {
                            console.log(response, response.data, "responseyyyyyyy");

                            if ($data == 1) {
                                this.fromloader = false;
                                this.city = response.data;
                                this.typedrop = true;
                            } else if ($data == 2) {
                                this.toloader = false;
                                this.city1 = response.data;
                                this.typedrop1 = true;
                            }

                        })

                        .catch((error) => {
                            console.log(error, "erroroor.....");
                        });

                    this.maximumletter = false;
                    this.maximumletter1 = false;
                    this.loading = true;
                }
            },

            async typeMultiCity(event, index, $num) {
                console.log(event, index, $num, "searchsearchsearchsearch");
                // let $event = [];
                console.log(event.length, index, "searchsearchsearchsearch...1");
                
                if (event.length > 0) {
                    if ($num == 1 && event.length > 0) {
                        this.multiData.itinery.forEach((v, i) => {
                                v.fromloader = true;
                                v.autocomTo = false;
                            if (i == index && event.length > 2) {
                                v.autocomfrom = true;
                               
                            } else {
                                v.autocomfrom = false;
                                v.fromloader = false;
                                this.multidrop = [];
                            }
                        });
                    } else if ($num == 2 && event.length > 0) {
                        this.multiData.itinery.forEach((v, i) => {
                            v.autocomfrom = false;
                            v.toloader = true;
                            if (i == index && event.length > 2) {
                                v.autocomTo = true;
                            } else {
                                v.autocomTo = false;
                                v.toloader = false;
                                this.multidrop1 = [];
                            }
                        });

                        for (let i = 1; i < this.multiData.itinery.length; i++) {
                            this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
                        }
                    }

                    await axios
                        .get(
                            ` ${"https://dl.buymytrip.com/api/airportautocomplete?airportString"}=${event}`, {
                                headers: {
                                    "Content-Type": this.airlineIcaoType,
                                },
                            }
                        )
                        .then((response) => {
                            console.log(response.data, "responsedata");

                            if ($num == 1) {
                                this.multiData.itinery.map((v) => {
                                    v.fromloader = false;
                                });

                                this.multidrop = response.data;
                                console.log(this.multidrop, "this.multidrop");
                            } else if ($num == 2) {
                                this.multiData.itinery.map((v) => {
                                    v.toloader = false;
                                });

                                this.multidrop1 = response.data;
                            }
                        })

                        .catch((error) => {
                            console.log(error, "erroroor.....");
                        });

                    this.maximumletter = false;
                    this.maximumletter1 = false;
                    this.loading = true;
                }
            },

    // Subcribe Section
    subscribeData() {
      let subscribeData = {
        request_type: "subscriber_form",
        email: this.subEmail,
        whatsapp: true,
        phone_no: this.subPhonenumber,
        token: "djfkdjkf",
      };
      console.log(subscribeData, "subscribeDatasubscribeData");
      if (this.validData) {
        this.loader = true;
        axios
          .post("https://dl.buymytrip.com/api/subscriber-form", subscribeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            this.dialog2 = true;
          })
          .catch((error) => {
            console.log(error, "error");
            setTimeout(() => {
              this.dialog1 = true;
            }, 500);
          });
      }
    },
    clearEmailError() {
      if (this.Errormsg && this.subscribe.email) {
        this.Errormsg = "";
      }
    },
    clearAgreeError() {
      if (this.agreeMsg && this.subscribe.checkAgree) {
        this.agreeMsg = "";
      }
    },
    // Subcribe Section End

    // fromOutsideAuto($event){
    //   console.log($event.from, 'p.....')
    //   if ($event.from) {
    //     this.fromlabel=true
    //   }
    //   else{
    //   this.fromlabel=false
    //   }
    // },

    // toOutsideAuto($event){
    //   console.log($event.to, 'p.....')
    //   if ($event.to) {
    //     this.tolabel=true
    //   }
    //   else{
    //   this.tolabel=false
    //   }
    // },

    // AutoComplete Functions

    fromClick($event) {
      this.formres = true;
      this.fromlabel = true;
      this.changeTextFrom = false;
      console.log($event, "p.....");
      if ($event.from) {
        $event.from = null;
        this.fromErrorMessage = true;
        this.changeTextFrom = false;
      }
    },

    toClick($event) {
      this.tolabel = true;
      //console.log($event, 'p.....')
      if ($event.to) {
        $event.to = null;
        this.toErrorMessage = true;
        this.changeTextTo = false;
      }
    },

    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          this.changeTextFrom = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },
    // if (this.oneway.from) {
    //   //console.log(this.oneway.from, 'okokok....1...')
    //   this.fromErrorMessage = false;
    // }
    // else {
    //   //console.log(this.oneway.from, 'okokok....2...')
    //   this.fromErrorMessage = true;
    // }

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          this.changeTextTo = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    removemulti(index) {
      console.log(index, this.multiData, "pp");
      this.multiData.itinery = this.multiData.itinery.filter(
        (v, i) => i !== index
      );
    },

    // fetchData() {

    //   console.log(userData, 'respomsedrespomsedr.......')
    //   Object.keys(userData).forEach((icao) => {
    //     const airport = userData[icao];

    //     if (airport.iata !== '') {
    //       this.fromAirports.push({
    //         iata: airport.iata,
    //         icao: airport.icao,
    //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //       });
    //     }
    //   });
    // },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      this.fromAirports = [];

      if (event.query.length >= 3) {
        await axios
          .get(
            `${"https://dltest.wistirna.com/api/airportautocomplete?airportString"}=${
              event.query
            }`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from) {
          this.maximumletter = true;
        }
        if (this.oneway.to) {
          this.maximumletter1 = true;
        }
      }
    },

    focus1() {
      if (!this.oneway.to) {
        this.fromval = false;
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
        console.log("testueeiekek");
        setTimeout(() => {
          this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus2() {
      if (!this.oneway.dedate || !this.oneway.travelDate) {
        setTimeout(() => {
          this.focusEvent3 = true;
          if (this.$refs.dpRef1) {
            this.$refs.dpRef1.openMenu();
          }
        }, 100);
      } else {
        this.focusEvent3 = false;

        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          //console.log(this.input[0].to, 'bfbfbfbf.....')
          this.input[1].from = this.input[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth <= 480) {
        // console.log(this.multiCalender,'multmiikmhuihuu...1111')
        this.multiCalender = false;
      } else {
        // console.log(this.multiCalender,'multmiikmhuihuu...2222')

        this.multiCalender = true;
      }
    },
    async getTopDestin() {
      await axios
        .get("https://dl.buymytrip.com/api/top_destinations", {
          params: {
            domain: "buymytrip.com",
          },
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response.data) {
            this.topDestin = response.data;
          }
          // console.log(response,'topDestinationsResponse')
        })
        .catch((error) => {
          console.log(error, "topDestinationsResponse");
        });
    },
    async getFlightDeal() {
      await axios
        .get("https://dl.buymytrip.com/api/flight_deals", {
          params: {
            domain: "buymytrip.com",
          },
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response.data) {
            this.flightDeals = response.data;
          }
          console.log(response, "flightDealsResponse");
        })
        .catch((error) => {
          console.log(error, "flightDealsResponse");
        });
    },
    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let pluginWhats = "";
        pluginWhats =
          getConfigData.payload.portal_configuration.menus.enabled.plugins;
        // topDestinationsApi = getConfigData.payload.portal_configuration.API_endpoints.top_destinations;
        // flightDealsApi = getConfigData.payload.portal_configuration.API_endpoints.flight_deals
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        this.currency =
          getConfigData.payload.portal_configuration.currency.display;
        if (pluginWhats.includes("whatsapp")) {
          this.pluginWhatspp = true;
          this.whatsappApi =
            getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.pluginWhatspp = false;
        }
      }
    },

    autoDate() {
      this.oneway.travelDate = [];
      let value1 = new Date();
      let value2 = new Date(new Date().setDate(value1.getDate() + 7));

      this.oneway.travelDate.push(value1, value2);

      if (this.oneway.travelDate == 2) {
        this.oneway.dedate = this.oneway.travelDate[0];
        this.oneway.redate = this.oneway.travelDate[1];
      }

      // console.log(this.oneway.travelDate, 'creaaadddddddd....')
    },

    recentFromData() {
    //   let result1 = [];
      let result2 = [];
      // let result3 = []
      // localStorage.setItem("recentFrom")
      let resultData = JSON.parse(localStorage.getItem("recentFrom"));
    //   console.log(resultData, 'result1result1')
    //   if(recentData){
    //     try {
    //     let resultData = JSON.parse(recentData)
    //     result1.push(resultData)
    //     console.log(result1,'result1result1')
    //     }
    //     catch(err){
    //         console.log(err)
    //     }
    //   }
      if (resultData == null) {
        // console.log("suhajhbhu");
      } else if (resultData.length > 0) {
        //  alert('pppp')
        result2 = resultData.splice(0, 15);
        // result3 = result1.splice(0, 15)
        // console.log(result1, "ppp");
        let result = [];
        let resultTo = [];

        result2.forEach((a) => {
          if (!result.some((b) => b.from === a.from)) {
            result.push(a);
          }
        });
        this.resultFrom = result;
        // console.log(result2,'result3result3')
        result2.forEach((a) => {
          if (!resultTo.some((b) => b.to === a.to)) {
            resultTo.push(a);
          }
        });
        // console.log(resultTo, "resultToresultTo");

        this.resultTo = resultTo;
      } else {
        console.log(resultData);
      }
    },
  },

  // AutoComplete Functions End
  watch: {
    "$i18n.locale": {
      handler: function () {
        this.service = this.getService();
        this.bookingus = this.getbookingUs();
        // this.flightDeals = this.getflightDeals();

        this.classTypeitems = [
          {
            text: this.$t("homePageContent.contentSection.economy"),
            value: "Economy",
            department: "Economy Department",
          },
          {
            text: this.$t("homePageContent.contentSection.business"),
            value: "Business",
            department: "Business Department",
          },
          {
            text: this.$t("homePageContent.contentSection.firstclass"),
            value: "First Class",
            department: "First Class Department",
          },
        ];
        // this.classType = 'Economy';
      },
      deep: true,
    },
    //     'oneway.from'(newVal) {
    //    this.oneway.from="yyz"
    //       console.log(newVal, 'ppppvvsd')

    // },
    "oneway.from"(newval) {
      if (newval) {
        this.multiData.itinery[0].from = newval;
      }
    },
    "oneway.to"(newval) {
      if (newval) {
        this.multiData.itinery[0].to = newval;
        this.multiData.itinery[1].from = newval;
      }
    },
    "oneway.class"(newSelectValue) {
      // console.log('select property changed to:', newSelectValue);

      if (newSelectValue) {
        // setTimeout(() => {
        this.$refs.countrySelect.focus();
        this.classSelect = false;
        // console.log(this.classSelect)
        //  }, 100);
      }
    },

    "oneway.travelDate"(value) {
      console.log(value, "valuevalue");
      if (value && value.length <= 2) {
        this.oneway.dedate = value[0];
        this.oneway.redate = value[1];

        this.focusEvent3 = false;
      } else {
        this.oneway.travelDate = null;
      }
    },

    "oneway.dedate"(value) {
      if (value) {
        this.focusEvent3 = false;
      } else {
        this.oneway.dedate = null;
      }
    },
  },

  // AutoComplete Functions
  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        // console.log(item, "cute");
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          // const cityName = `${parts[1]}  (${parts[0]})`;

          const name = `${parts[1]}  (${parts[0]})`;
          const airportName = parts[parts.length - 1];
          // const name = `${cityName}<br>${airportName}`;
          const originalName = item.places;
          return {
            name,
            airportName,
            originalName,
          };
        }
      });
    },

    // selectedClassType: {
    //   get() {
    //     if(this.classType){
    //       return this.classType
    //     }
    //     else{
    //       return this.$t(`homePageContent.contentSection.${this.classType}`);
    //     }
    //   },
    //   set(value) {
    //     // Set the selected class type here
    //     this.classType = value;
    //   }
    // },

    // classTypeitems() {
    //   return [
    //     this.$t("formsContents.options.economy"),
    //     this.$t("formsContents.options.business"),
    //     this.$t("formsContents.options.firstClass"),
    //   ];
    // },
  },
  // AutoComplete Functions End



  mounted() {
    // this.fetchData();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.body.addEventListener("click", this.handleOutsideClick);
    this.recentFromData();
    this.generateRandomAlphabets();

    // if (!navigator.cookieEnabled) {
    //     this.showCookieInfo = true;
    // console.log(navigator,'navigatornavigator....1')
    //   }
    //   else{
    //     this.showCookieInfo = false;
    // console.log(navigator,'navigatornavigator....2')
    //   }

    //
    // loginService.getAllUser().then(res => {
    //   this.customer = res.data
    //   //console.log(this.customer, '44444')
    // })
    //console.log(this.oneway, 'llllllllllllllll')
    this.travelDate = new Date();
    this.travelDate.setDate(this.travelDate.getDate() + 2);
    // console.log(
    //   new Date(new Date().setDate(new Date().getDate() + 2)),
    //   "this.travelDate"
    // );
  },

  created() {
    localStorage.removeItem("delete_Id");
    this.getConfig();
    this.getTopDestin();
    this.getFlightDeal();
    // this.autoDate();
    // this.autocompletJson();
    // this.autocompletJson();
    window.addEventListener("resize", this.handleResize);
    // this.ailenelogosh = userData.splice(0, 15);
    // console.log(this.ailenelogosh, "userDatauserData");
    console.log(this.$i18n.locale,'localelocalelocalelocaloglog')
  },

  
};
</script>

<style scoped>
.v-select .v-field .v-text-field__prefix,
.v-select .v-field .v-text-field__suffix,
.v-select .v-field .v-field__input,
.v-select .v-field.v-field {
  cursor: pointer;
  height: 41px;
  box-shadow: none !important;
}

body {
  zoom: 0.9;
  -ms-zoom: 0.9;
  -webkit-zoom: 0.9;
  -moz-transform: scale(0.9, 0.9);
  -moz-transform-origin: top;
}

.bg-backImg {
  background-image: url("../assets/slide3.jpg");
}

>>> .captcha_section .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  position: relative !important;
  top: -6px !important;
  padding: 0px 6px !important;
}

>>> .captcha_section
  .v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: 8px;
  margin: 0px 7px;
  font-size: 10px;
}

>>> .captcha_section .v-field--center-affix .v-label.v-field-label {
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
}

>>> .captcha_section .v-field__input {
  padding: 0px 6px;
}

#visible {
  visibility: hidden;
}

>>> .p-autocomplete-input::placeholder {
  color: #9ca3af70 !important;
  /* Set the desired color for the placeholder text */
}

.container-styles {
  border-radius: 20px;
}

>>> .v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
  display: flex !important;
  align-items: center !important;
}
>>> .select-1 .v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
  display: flex !important;
  align-items: center !important;
}

>>> .v-overlay__content {
  outline: none;
  position: absolute;
  pointer-events: auto;
  contain: layout;
}

.recent_style {
  position: absolute;
  background: white;
  width: 340px;
  margin: 12px 12px;
  border-radius: 20px;
  z-index: 5;
  border: 1px solid gray;
}

/* >>>.v-selection-control__input {
  bottom: 6px;
} */

>>> .subscribe-inputFields .v-input__details {
  display: block !important;
  position: relative;
  top: 5px;
}

>>> .subscribe-inputFields .v-input__details .v-messages__message {
  font-size: 10px !important;
}

.v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.v-selection-control__wrapper {
  width: 30px;
  max-height: 23px;
}

.login-card {
  position: absolute;
  z-index: 2;
}

/* Datepicker Style Section */

.dp__icon {
  display: block;
}

/* .v-icon {
   width: 100%;
 } */

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding: 0px;
  height: auto;
  font-size: 18px;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  /* color: grey; */
}

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  position: absolute;
  top: -6px;
  left: -46px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
}

.datepicker-label {
  position: absolute;
  bottom: -38px;
  transform: translateY(-100%);
  background-color: transparent;
  pointer-events: none;
  z-index: 1;
  line-height: 1;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 300 !important;
}

.label-top2 {
  position: absolute;
  top: -6px;
  left: -46px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 15px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-weight: 600;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

>>> .dp__clear_icon {
  top: 67% !important;
}

/* Datepicker Style Section End */

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 8px !important;
}

>>> .dp__input_wrap {
  position: relative;
  width: 100% !important;
  box-sizing: unset;
  top: 0px;
  left: 0px !important;
}

>>> .dp__input {
  background-color: #fff0;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

>>> .v-field__input {
  color: grey !important;
}

.form-btn {
  padding-left: 40px;
  color: white;
}

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  font-size: 15px !important;
}

.btn-active {
  background-color: #ee2b34;
  color: white;
}

.v-checkbox .v-input__details {
  display: none;
}

/* Login Page End */

/* Login Remember Btn */

/* .v-selection-control {
  align-items: start;
} */

.v-label {
  align-items: start;
}

.labeling1 {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: black;
  left: 30px;
  z-index: 2;
}

.labeling {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(51, 6, 148);
  left: 30px;
  z-index: 2;
}

.labeling:focus {
  color: red !important;
}

/* .back {
   background-image: url('../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 800px;
   background-position: top right 24%;
   z-index: 1;
   border-radius: 20px;
 } */

body {
  font-family: var(--normal-font);
}

/* navbar */
.logo {
  width: 180px;
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-14);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-700);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

/* .v-btn__content {
  color: #ee2b34;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
} */

.book-ticket {
  /* color: #ee2b34; */
  background-color: #f0f0f0;
}

.whatts-logo {
  display: block;
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 112px;
  z-index: 9999;
  width: 65px;
  height: 65px;
}

/* .whatts-logo {
   display: block;
   position: fixed;
   cursor: pointer;
   right: 30px;
   bottom: 40px;
   z-index: 9999;
 } */

/* navbar end */

/*Flight banner */
.banner-left-small-title {
  color: var(--primary-color) !important;
}

/* .bannar-bg {
   background-image: url('../assets/bg-map.png');
   background-size: 55% 83%;
   background-repeat: no-repeat;
   background-position: center;
 } */

.flight-right {
  width: 500px !important;
  height: 500px;
}

.banner-left-small-title h6 {
  font-weight: var(--font-600);
}

.banner-small-text {
  color: var(--primary-color) !important;
}

.banner-left-title h4 {
  font-family: var(--title-font);
  font-weight: var(--font-600);
  font-size: 60px;
}

.location {
  color: #ee2b34;
  width: 22px;
}

.form-control {
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  background-color: unset !important;
  /* width: 150px; */
}

.form-control:focus {
  color: unset !important;
  border-color: unset !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-select {
  color: unset !important;
  /* border-color:unset !important; */
  outline: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  /* width: 150px; */
}

.form-select:focus {
  border-color: unset !important;
}

.form-control::placeholder {
  font-size: 14px;
  font-weight: var(--font-500);
}

.input-group-text {
  text-align: center;
  white-space: nowrap;
  border: none;
}

.form-location {
  width: 18px;
  margin-top: -15px;
}

.btn {
  background-color: #ee2b34;
  color: #fff;
}

.btn:hover {
  border: 1px solid #ee2b34;
  color: #ee2b34;
}

/* banner end */

/* Explore Top Destination */

.top-destination {
  color: #ee2b34;
  font-size: 14px;
}

.heading {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.card.top-card {
  height: 330px;
  border-radius: 25px;
}

.card.top-card .card-tour {
  height: 220px;
  width: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

/* .icon-container {
    margin-right: 10px;
  } */

.calendar-wrapper {
  display: inline-block;
  border-bottom: 1px solid lightgray;
}

.calendar-label {
  display: block;
  margin-bottom: 5px;
}

.card-body .star {
  color: #ee2b34;
  margin-top: 20px;
}

.card-body .rate {
  color: #ee2b34;
}

.how-we-work {
  color: #ee2b34;
  font-weight: 600;
  font-size: 14px;
}

.how-we-section {
  margin-top: 100px;
}

.choose {
  width: 40px;
}

.choose-item {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.choose-para {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.car-right-wrapper {
  width: 700px;
  height: 700px;
}

.lorem {
  font-size: 11px;
}

.session-bg {
  background-image: url("../assets/bg1.png");
  background-repeat: no-repeat;
  background-position: top 100px right -290%;
}

.trvel-para {
  color: #ee2b34;
  font-weight: 600;
}

.owl-theme .owl-nav [class*="owl-"] {
  border: 2px solid darkturquoise;
  padding: 15px !important;
  border-radius: 50% !important;
  background-color: rgb(243, 216, 207) !important;
  width: 50px;
  margin-right: 20px;
  color: darkturquoise;
}

.owl-images img {
  height: 350px;
  border-radius: 30px;
}

.owl-theme .item {
  width: 330px;
}

.owl-images {
  position: relative;
}

.badge {
  position: absolute;
  top: 20px;
  left: 15px;
  background-color: #ee2b34;
  color: #fff;
  border-radius: 30px;
}

.testi-para {
  color: #ee2b34;
  font-weight: 600;
}

.testi-div {
  margin-top: 100px;
}

.card img {
  width: 100%;
  height: 100%;
}

.test-name {
  font-size: 18px;
  font-weight: 400;
}

.card-text {
  font-size: 14px;
}

.card {
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: none;
  border-radius: 25px;
}

.card-relative {
  position: relative;
}

.quotes {
  position: absolute;
  top: -35px;
}

/* Subscribe Section */

.subscribe {
  /* background-color: navy !important; */
  background-color: #019df2 !important;
  color: white;
  border-radius: 14px;
  line-height: 28px;
}

input {
  outline: none;
}

>>> .v-switch__track {
  height: 12px;
  width: 24px;
}

>>> .v-switch__thumb {
  width: 16px;
  height: 16px;
}

.subscribe-input {
  border-radius: 17px;
  border: none;
  padding: 15px 20px;
  background-color: #fff;
}

>>> .dp__input {
  font-family: "Poppins", sans-serif !important;
}

.rounded-shadow {
  box-shadow: 0px 0px 4px 0px rgb(16, 55, 105);
  box-shadow: 0px 0px 4px 0px #ee2b34;
}

>>> .subscribe-inputFields .v-input__control {
  display: flex;
  grid-area: control;
  height: 20px !important;
}

>>> .subscribe-inputFields .v-field__input {
  padding-top: 0;
}

>>> .subscribe-inputFields .v-field__field {
  top: -3px;
}

/* >>>.v-field__field {
  height: 40px;
} */

>>> .input-styles-2 .v-input .v-input__control {
  height: 25px !important;
}

>>> .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  position: relative;
  top: -5px;
}

>>> .subscribe-inputFields .v-input--density-default .v-field--variant-solo,
.v-input--density-default .v-field--variant-solo-inverted,
.v-input--density-default .v-field--variant-solo-filled,
.v-input--density-default .v-field--variant-filled {
  --v-input-control-height: 50px;
  --v-field-padding-bottom: -7px;
}

>>> .subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .subscribe-inputFields
  .v-input--density-default
  .v-field--variant-solo
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-inverted
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-filled
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: -1px;
}

/* .subscribe-btn {
   border-radius: 5px;
   border: none;
   width: 200px;
   height: 35px;
   background-color: #ee2b34 !important;
   color: white !important;
 } */

.subscribe-button {
  min-width: 0px !important;
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  padding: 0px;
  background-color: #ee2b34 !important;
  color: white !important;
}

.subscribe-inputFields {
  border: 1px solid #666666;
  border-radius: 32px;
}

.subscribe-inputFields .v-field--rounded {
  border-radius: 9999px 0px 0px 9999px;
  box-shadow: none;
}

.subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .v-input__details {
  display: none;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.email-error {
  position: absolute;
  margin-top: 20px !important;
  margin-bottom: -25px;
  margin-right: 65px;
}

.agree-error {
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
  font-size: 14px;
}

/* Subscribe Section End */

.form-input {
  border: none;
}

.btn-badge {
  background-color: aqua;
  border: none;
  border-radius: 40px;
}

.btn-badge:hover {
  background-color: aqua;
  border: none;
  color: black;
}

.py-8 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.social-icons {
  color: var(--primary-color) !important;
}

.fs-14 {
  font-size: 14px;
  list-style-type: none;
}

.fs-14 li {
  padding: 6px;
}

section {
  margin: auto !important;
}

.slide-card {
  border-radius: 4%;
  width: 258px;
  height: 215px;
}
.slide-card1 {
  border-radius: 4%;
  width: 258px;
  height: auto;
}

.card-test {
  position: relative;
  padding: 10px 15px;
  border-radius: 8%;
  max-height: 500px;
}

.card-img {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50%;
  right: 0;
  transform: translateY(-50%);
}

.radio {
  border-radius: 5px !important;
  background-color: #022c60 !important;
  color: white;
  font-size: 12px !important;
  font-weight: 500;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05) !important;
}

.v-input--density-default {
  --v-input-control-height: 38px;
  /* position: relative;
  top: 0px; */
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400px;
  height: 32px;
  padding-right: 10px;
  padding-right: 16px;
  border: 1px solid #0000003d;
  box-shadow: none;
  border-radius: 5px !important;
  /* background-color: gray;
  color: white !important; */
}

.normal-radio span {
  /* font-size: 12px !important; */
  font-weight: 700;
  /* color: black; */
}

.cart-adult {
  padding: 2px 15px 15px 15px;
  width: 214px;
  cursor: pointer;
  position: absolute;
  top: 170px;
  left: 252px;
}

.adult {
  height: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  /* background-color: #F7F7F7; */
}

.booknow {
  padding-right: 20px;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

>>> .p-datepicker-group-container {
  background-color: #ffffff !important;
  width: 200px;
  padding: 5px 3px;
  border: 1px solid lightgray;
  position: relative;
  right: 14px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 500px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 100% !important;
}

>>> .p-datepicker {
  width: auto;
  background: white !important;
}

/* Flight Search Box */

.search-btn {
  /* width: 60px;
  height:100px; */
  font-size: 16px;
  display: inline-block;
  /* border-radius: 50%; */
  padding: 0px;
}
.search-btn2 span {
  font-size: 18px;
  font-weight: 600;
}
.search-btn span {
  font-size: 18px;
  font-weight: 600;
  /* text-transform: capitalize;
  margin: 10px; */
}

.formbottom_ClearBtn {
  border-radius: 5px;
  color: white;
  background-color: #ee2b34;
}

.formbottom_addBtn {
  border-radius: 5px;
  color: white;
  background-color: #022c60;
}

/* .search-btn {
   background-color: #ee2b34;
   color: white !important;
   text-transform: capitalize;
 } */

.mdi-airplane-takeoff::before {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  /* color: gray; */
}

.mdi-airplane-landing::before {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  /* color: gray; */
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

/* >>>.p-autocomplete-panel {
   color: white;
   border-radius: 10px;
 } */

.p-error-from {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -16px;
}

.p-error-to {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: 1px;
}

.dropdown-box {
  padding: 8px 10px;
  /* border-radius: 10px; */
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
  /* padding-top:6px; */
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  text-align: left;
}

.p-float-label label {
  position: absolute;
  top: 5px;
  left: -1px;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: -0.5rem;
  line-height: 1.75rem;
}
.p-float-label .travelDate {
  position: absolute;
  top: 12px;
  left: -1px;
  font-weight: 600;
  font-size: 17px;
  margin-top: -0.5rem;
  line-height: 1.75rem;
}
>>> .p-float-label
  .p-inputwrapper-focus
  .input-with-placeholder
  input::placeholder {
  /* Adjust placeholder styles */
  color: #2b559e !important; /* Placeholder text color */
  font-size: 14px !important; /* Placeholder font size */
}
/* >>> .p-float-label label.active {
  top: -15px !important;
  font-size: 12px;
  color: black;
}
>>> .p-float-label span.active {
  display:none !important;
} */
/* .p-float-label label {
   position: absolute;
   pointer-events: none;
   top: 17px;
   left: 0px;
   margin-top: -0.5rem;
   transition-property: all;
   transition-timing-function: ease;
   line-height: 1;
   color: #242424;
   font-weight: 600;
   font-size: 16px;
 } */

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

/* End Flight Search Box */

/* .flight-back {
   background-image: url('../assets/travel-pattern-bg4.svg');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;
   background-color:#e0e9f191;
   background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);

 } */

.form-card {
  width: 100%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  box-shadow: none !important;
  border-radius: 5px !important;
  background: #ffffffb5;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px !important;
 } */

.form-container {
  position: relative;
  top: 20px;
}

.banner-lg-text {
  font-size: 72px;
  font-weight: 500;
}

.banner-sm-text {
  font-size: 18px;
}
.pax-fonts span {
  font-size: 16px;
}

@media only screen and (max-width: 480px) {
  .form-container {
    position: relative;
    top: 0px;
  }

  .banner-lg-text {
    font-size: 45px;
    font-weight: 500;
  }

  .banner-sm-text {
    font-size: 14px;
  }

  .mobile {
    display: none;
  }

  .flight1 {
    display: none !important;
  }

  .form-card {
    padding: 20px;
    /* border-radius: 0px 20px 20px 20px !important; */
  }

  .pax-fonts span {
    font-size: 14px !important;
    color: black !important;
    font-weight: 700 !important;
  }
  /*
>>>.select-option .v-select__selection-text {
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 700;
    position: relative;
    top: -4px;
} */

  >>> .select-option .v-select__selection-text {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    position: relative !important;
    top: -4px !important;
  }

  /* .flight-back {
     background-image: url('../assets/flight.png');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;

   } */

  /* .subcribe-card {
     margin-top: 10% !important
   } */
}

@media only screen and (max-width: 970px) {
  .car-right {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .session-bg {
    background-image: none;
  }

  .back {
    background-image: none;
  }

  .flight-right {
    width: 80% !important;
  }

  .form-btn {
    padding: 10px 0px 0px 0px !important;
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }

  .sub-text {
    font-size: 26px;
  }

  /* .subscribe-btn {
    width: 40%;
    height: 100%;
    font-size: 18px;
  } */

  .cart-adult {
    top: 113px !important;
    left: 275px !important;
  }

  .email-error {
    width: 100%;
  }

  .agree-error {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
    padding-right: 15px;
  }

  .cart-adult {
    top: 134px;
    left: 188px;
  }

  .email-error {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
  }

  .dropdown_section {
    width: auto !important;
  }

  /* .email-error {
    margin-right: 465px;
  } */
}

@media only screen and (max-width: 576px) {
  .cart-adult {
    top: 280px !important;
    left: 181px !important;
  }

  /* .email-error {
    margin-right: 240px;
  } */
}

.p-float-label::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

.p-float-label::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

.p-float-label::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

/* Scroll Bar Work */

>>> .pv_id_2_list {
  overflow: auto !important;
  max-height: 100px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  padding: 5px;
  height: 70px;
  /* box-shadow: 15px 10px 7px -18px gray;
   clip-path: inset(0px -16px 12px 0px); */
  /* padding: 1rem 1.5rem; */
}

.styles_input1 {
  border-radius: 0px 0px 0px 60px !important;
}

.styles_input2 {
  border-radius: 0px 60px 0px 0px !important;
}

.input-styles-1 {
  height: 33px !important;
  width: 250px;
  padding: 4px 7px;
  border-radius: 15px;
  border: 1px solid #0000003d;
  background-color: white;
  border-radius: 5px !important;
}

.input-styles-focus {
  /* box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05); */
  /* padding: 1rem 1.5rem; */
  border-radius: 60px !important;
  height: 70px;
  -webkit-clip-path: none !important;
  clip-path: none !important;
}

/* .input-styles {
   box-shadow: 0px 1px 2px 0px gray;
   padding: 5px;
   background: #ffffff;
   border-radius: 7px;
   height: 44px;
 } */

.input-styles-2 {
  padding: 8px 5px 8px 12px;
  border-radius: 20px;
  border: 1px solid #0000003d;
  height: 33px;
  color: black;
  /* width: 130px; */
  background-color: white;
  border-radius: 5px !important;
}

>>> .select-option .v-select__selection-text {
  color: black !important;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  top: -4px;
}

>>> .v-menu > .v-overlay__content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  left: 505px !important;
  width: 290px !important;
  border-radius: 20px !important;
  position: relative !important;
}

>>> .v-overlay-container .v-overlay .v-select__content {
  left: 2.8px !important;
  width: 300px !important;
  border-radius: 20px !important;
  position: relative !important;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px;
 } */

>>> .select-1 .v-field__append-inner {
  padding-top: 2px !important;
  position: relative;
  top: 2px !important;
}

>>> .select-1 .v-field__input {
  padding-top: 5px;
}

>>> .select-1 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --input-control-height: 52px !important;
  --v-field-padding-bottom: 0px;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px !important;
  top: -3px;
  position: relative;
}

>>> .select-2 .v-field__input {
  padding: 2px 0px 2px 2px !important;
}

>>> .select-2 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --v-input-control-height: 48px;
  --v-field-padding-bottom: 10px !important;
}

>>> .select-option .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-1 .v-list.v-theme--light.v-list--density-default.v-list--one-line {
  background: #f9f8f8 !important;
}

/* >>>.input-styles .dp__today {
   border: 1px solid #ee2b34 !important;
 } */

>>> .input-styles .dp__menu {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
  border-radius: 20px !important;
  border: 1px solid #d3d3d361 !important;
  top: 84px !important;
}

>>> .input-styles .dp__cell_inner {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  margin: 0;
}

>>> .input-styles .dp__range_between {
  border-radius: 0px !important;
}

>>> .input-styles .dp__date_hover_end:hover {
  background: #1976d2 !important;
  color: white !important;
  border-radius: 50% !important;
}

>>> .input-styles .dp__calendar_item:hover {
  background: #f3f3f3 !important;
  /* color:black !important; */
  border-radius: 50% !important;
}

/* >>>.input-styles .dp__range_end, .dp__range_start, .dp__active_date {
    border-radius: 50% !important;
    margin: 8px !important;
}
 >>>.input-styles .dp__cell_inner:hover{
    border-radius: 50% !important;
    margin: 8px !important;
} */

>>> .dp__date_hover:hover {
  background: #ee2b34;
  color: white;
}

.back-bg {
  width: 100%;
  /* height: 500px; */
  border-radius: 20px;
  padding: 20px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

/* .p-float-label .p-inputwrapper-focus ~ label {
  display: none !important;
} */

/* .p-float-label .p-inputwrapper-filled ~ .whereText {
  display: none !important;
} */

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.pax-fonts span {
  font-size: 16px;
  color: black !important;
  font-weight: 700 !important;
}

.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

>>> .contact-box .v-icon {
  transform: rotate(45deg);
}

.resu-form:hover {
  background-color: lightgray;
  /* padding:5px; */
}

.resu-form {
  padding: 10px;
}

.resu-card {
  border-radius: 10px;
  width: 350px;
  height: 200px;
  text-align: start;
}

/* Cookies Styles */

.cookie-info {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #333;
  color: white;
  text-align: center;
}
.cookie-info p {
  margin: 0;
}
.cookie-info button {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
}
>>> .subscribe-section .v-field__overlay {
  background-color: lightgrey !important;
}

/* Cookies Styles End*/

/* .resu-card:hover{
padding:0px;
} */
/* >>>.dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
   top: -10px !important;
   left: -50px !important;
   transform: none !important;
 } */

/* >>>.dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {

   top: -10px !important;
   transform: none !important;
   left: -50px !important;
 } */

>>> .p-autocomplete-input::placeholder {
  color: #2b2828 !important;
}

/* -------new form design start-------- */

.form-radius {
  border-right: 1px solid gray;
}
.multiform-radius {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}
.addmore-btn {
  display: none;
}
.search-mediabtn1 {
  display: none;
}
.multiform-bottom {
  border-bottom: 1px solid gray;
}
.multiform-bottom1 {
  border-right: 1px solid gray;
}
@media screen and (max-width: 1024px) {
  .top-btn {
    padding: 0 5px !important;
  }
}
@media only screen and (max-width:767px){
.form-radius {
    border-right: none;
    border-bottom: 1px solid gray;
  }
  .multiform-bottom1 {
    border-bottom: 1px solid gray;
    border-right:0px;
  }
  .star-logo {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 768px) {
  .starRating{
    width:300px;
  }
  
  .multiform-radius {
    border-right: none;
    border-bottom: 1px solid gray;
  }
  .addmore-btn {
    display: block;
    display: flex;
    align-items: center;
  }
  .search-mediabtn {
    display: none;
  }
  .search-mediabtn1 {
    display: block;
  }

  
  .logo-img {
    width: 100px !important;
  }

.multiform-bottom {
    border-bottom: 2px solid gray;
  }
}

.Fjq1WzSqK01XLJIUrh6P {
  align-items: center;
  display: flex;
  margin-top: 10px;
  /* max-width: 1136px; */
  width: 100%;
}
.FmDoYPTiZ64nhr6_GNO9 {
  flex-shrink: 0;
  width: 25%;
}
.JHtIKoDUU7SDdnVsKxXF {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
}
.Zjieao58I6BZGj_NJzhS {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
}
.rNCanQJ9jJ1PhSAOaVJt {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
  width: 100%;
}
.czgJDQ5awfrbTAAiEhGh {
  display: flex;
  flex-grow: 1;
  height: 32px;
  justify-content: center;
  width: 25%;
}
.where {
  color: #9ca3af;
  font-size: 14px;
  font-weight: 300;
}
@media only screen and (max-width: 992px) {
  .where {
    color: #9ca3af;
    font-size: 11px;
    font-weight: 300;
  }
}
/* -------new form design end-------- */

/* carousel */
>>> .carousel-slide .v-slide-group .v-slide-group__prev {
  position: absolute;
  left: 84%;
  top: 8%;
  /* z-index: 1; */
  padding: 5px 10px;
  background-color: #133a6a;
  border-radius: 5px;
}
>>> .carousel-slide .v-slide-group .v-slide-group__next {
  position: absolute;
  left: 90%;
  top: 8%;
  padding: 5px 10px;
  background-color: #133a6a;
  border-radius: 5px;
}
>>> .carousel-slide .v-slide-group .v-slide-group__prev .mdi-chevron-left {
  color: white !important;
}
>>> .carousel-slide .v-slide-group .v-slide-group__next .mdi-chevron-right {
  color: white !important;
}
>>> .slide-card1 .ma-4 {
  margin: 23px !important;
}
/* >>>.p-float-label .p-inputwrapper-filled ~ label {
  top:-5px !important
} */
>>> .p-float-label .dp__input_focus ~ .travelDate {
  font-size: 12px !important;
  top: -0.75rem !important;
}
.input-styles-focus .p-float-label label.travelDate,
.p-float-label label.travelDate.travelDate-focus {
  top: -0.75rem !important;
  font-size: 12px !important;
  margin-top: 0px !important;
}
>>> .dp__pointer {
  margin-top: 10px;
}
>>> .p-float-label
  .p-inputwrapper-filled
  .p-autocomplete-dd
  .p-autocomplete-input {
  padding-top: 6px;
}
/* .pax-fonts span {
  font-size: 14px;
  color: black !important;
  font-weight: 700 !important;
} */
>>> .carousel-slide .v-slide-group__content .v-card {
  width: 340px;
}
.crossicon {
  margin-right: 10px;
}
>>> .mdi-close-circle-outline::before {
  font-size: 25px;
}
@media screen and (min-width: 400px) and (max-width: 430px) {
  >>> .carousel-slide .v-slide-group__content .v-card {
    width: 350px;
  }
}
  @media screen and (max-width: 375px) {
    >>> .carousel-slide .v-slide-group__content .v-card {
      width: 305px !important;
    }
  }
.btn-text {
  display: block;
}
.btn-img {
  display: none;
}
.addmorebtn {
  display: block;
}
.clearAll {
  display: block;
}
.formbottom_addBtn {
  padding: 0 9px;
  letter-spacing: 0 !important;
}
.fromText {
  color: #9ca3af;
  font-size: 14px;
  font-weight: 300;
}
>>>.formbottom_addBtn .v-btn__prepend{
  margin-inline-end: 0px !important;
}
.formbottom_ClearBtn{
  padding: 0 10px;
}
/* mobile view */
@media screen and (min-width: 998px) and (max-width: 1024px) {
  .form-radius{
    padding:0px;
  }
  .fromText{
    font-size:13px !important;
  }
  .travelDate{
    font-size:16px !important;
  }
  .multiform-bottom .calendarInput{
    width:18.6%;
  }
  .multiform-bottom .searchBtns{
    width:14.6%;
  }
  >>> .formbottom_addBtn .v-btn__content {
    display: none !important;
  }
  .formbottom_addBtn {
    font-size: 14px;
    padding: 0 5px;
    height: 24px;
    min-width: 0px;
  }
  >>> .formbottom_addBtn .v-btn__prepend {
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  .formbottom_ClearBtn {
    font-size: 14px;
    padding: 0 5px;
    height: 24px;
    min-width: 0px;
  }
  >>> .formbottom_ClearBtn .v-btn__prepend {
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  >>> .formbottom_ClearBtn .v-btn__content {
    display: none !important;
  }
}
@media screen and (max-width: 430px) {
  >>> .carousel-slide .v-slide-group .v-slide-group__prev {
    left: 65% !important;
    top: 13% !important;
  }
  >>> .carousel-slide .v-slide-group .v-slide-group__next {
    left: 80% !important;
    top: 13% !important;
  }
  .banner-lg-text {
    font-size: 40px !important;
  }
  .normal-radio {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
  /* .pax-fonts span {
    font-size: 14px !important;
  } */
  >>> .carousel-slide .v-slide-group__content {
    margin-top: 25px;
  }

  .recent_style {
    width: 260px !important;
  }
  .formbottom_addBtn {
    font-size: 14px;
    padding: 0 5px;
    height: 24px;
    min-width: 0px;
  }
  >>> .mdi-plus-circle {
    margin-bottom: 1px;
  }

  >>> .formbottom_addBtn .v-btn__prepend {
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  .crossicon {
    margin-right: 0px;
  }
  .formbottom_ClearBtn {
    font-size: 14px;
    padding: 0 5px;
    height: 24px;
    min-width: 0px;
  }
  >>> .formbottom_ClearBtn .v-btn__prepend {
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }
  >>> .mdi-close-circle-outline::before {
    font-size: 20px;
  }
  .input-styles {
    padding: 0px;
  }
  >>> .select-option .v-select__selection-text {
    font-size: 14px !important;
  }
  .input-styles-2 {
    padding: 8px 5px 8px 8px !important;
  }
  .btn-text {
    display: none;
  }
  .btn-img {
    display: block;
  }
  .addmorebtn {
    display: none;
  }
  .clearAll {
    display: none;
  }
  .dropdown-box{
    max-width:275px;
    position:relative;
    white-space: pre-wrap;
    margin-left:33px;
  }
  .multiform-bottom {
    border-bottom: 2px solid gray;
  }
  .multiform-bottom1 {
    border-right: 0px;
    border-bottom: 1px solid gray;
  }
  >>>.v-progress-circular{
    right:40px !important;
  }
  .autocompete-menual-dropdown{
    left:-19px !important;
  }
  .autocompete-menual-dropdown1{
    left:-19px !important;
  }
}
 .autocompete-menual-dropdown {
        border: 1px solid lightgray;
        background-color: white;
        cursor: pointer;
        max-height: 250px;
        overflow-y: auto;
        position: absolute;
        width: 325px;
        /* max-width: 100%; */
        padding: 4px;
        border-radius: 2px;
        margin-top: 22px;
        /* margin-left: -44px; */
        z-index: 99999;
        font-size: 14px;
        font-weight: 500;
        left: 20px;
    }
    .autocompete-menual-dropdown1 {
        border: 1px solid lightgray;
        background-color: white;
        cursor: pointer;
        max-height: 250px;
        overflow-y: auto;
        position: absolute;
        width: 325px;
        /* max-width: 100%; */
        padding: 4px;
        border-radius: 2px;
        margin-top: 22px;
        /* margin-left: -44px; */
        z-index: 99999;
        font-size: 14px;
        font-weight: 500;
    }
    .va-content{
        text-align:left !important;
    }
    .air-icon{
        transform:rotate(90deg) !important;
        font-size:18px !important;
    }
    .inter-input::placeholder{
        color: #9ca3af;
        font-size: 13px;
        font-weight: 300;
    }
    .inter-input{
      width:100%;
    }
    .input-filled{
        font-weight:600;
        font-size:18px;
    }
    .fly-from{
       display:block;
       text-align:left;
       font-weight:600 !important;
       font-size:13px !important;
    }
    .autocomplete-label label{
        display:block;
       text-align:left;
       font-weight:600;
       font-size:1.125rem;
    }
    >>>.v-progress-circular{
      right:16px;
    }
</style>
