<template>
  <div style="background: #d3d3d34f;">
    <v-container>
      <div>
        <div class="row">
          <div class="col-lg-8 col-12">
            <v-card rounded="2" class="p-3">
              <h5>Departure</h5>
              <div>
                <div class="row gx-0 align-center border border-1 my-3 py-4 px-2 rounded-3">
                  <div class="col-md-3 w-25">
                    <p class="m-0"><span class="f-size-12 fw-400">Mar,24</span>
                      <span class="f-size-14 fw-500"> 10:20</span>
                    </p>
                    <p class="f-size-18 fw-600 m-0">Chennai (MAA)</p>
                  </div>
                  <div class="col-md-3 w-50">
                    <v-img src="@/assets/dot5.png" width="450px"></v-img>
                  </div>
                  <div class="col-md-3 w-25 text-end">
                    <p class="m-0"><span class="f-size-12 fw-400">Apr,15</span>
                      <span class="f-size-14 fw-500"> 04:12</span>
                    </p>
                    <p class="f-size-18 fw-600 m-0">Toronto (YYZ)</p>
                  </div>
                </div>
              </div>

              <div class="row gx-0 align-center border border-1 my-3 py-4 px-2 rounded-3">
                <div class="col-md-3 w-25">
                  <p class="m-0"><span class="f-size-12 fw-400">Mar,24</span>
                    <span class="f-size-14 fw-500"> 10:20</span>
                  </p>
                  <p class="f-size-18 fw-600 m-0">Toronto (YYZ)</p>
                </div>
                <div class="col-md-3 w-50">
                  <v-img src="@/assets/dot5.png" width="450px"></v-img>
                </div>
                <div class="col-md-3 w-25 text-end">
                  <p class="m-0"><span class="f-size-12 fw-400">Apr,15</span>
                    <span class="f-size-14 fw-500"> 04:12</span>
                  </p>
                  <p class="f-size-18 fw-600 m-0">Chennai (MAA)</p>
                </div>
              </div>
              <!-- <hr> -->
              <div class="d-flex justify-end">
                <div class="d-flex justify-end mb-2">
                  <!-- <v-btn class="print-btn-1 ms-3 me-2" id="buttontActive" prepend-icon="mdi-printer">Print</v-btn> -->
                  <v-btn color="green" class="print-btn-mbl print-btn-2 mx-2" style="text-transform: capitalize;">
                    <v-icon>mdi-printer</v-icon>
                    <span class="ms-2">Print</span>
                  </v-btn>
                </div>
                <!-- <v-btn color="primary" @click.stop="drawer = !drawer" style="text-transform: capitalize;">Travel
                  Details</v-btn> -->
                <v-btn color="#ee2b34" rounded="2" class="ms-2" @click="managePopup = !managePopup"
                  style="text-transform: capitalize;">Manage Trip</v-btn>
              </div>
            </v-card>

            <v-card rounded="2" class="p-3 mt-3">
              <h5>Passenger Details</h5>
              <div v-for="data of 3" :key="data"
                class="row gx-0 align-center justify-content-between border-bottom border-2 py-2 mt-3">
                <div class="col-md-4">
                  <span><i class="fa-regular fa fa-user" style="color: #FFD43B;"></i></span>
                  <span class="f-size-16 fw-600 m-0 ms-2">Ram Kumar</span>
                </div>
                <div class="col-md-3 text-end">
                  <span class="f-size-16 fw-600 m-0 ms-2">M/Adult</span>
                </div>
              </div>
            </v-card>
          </div>

          <div class="col-lg-4 col-12">
            <v-card rounded="2" class="p-3">
              <p class="f-size-14">Booking ID:</p>
              <h5 class="fw-600">AUX-5749e7ff3</h5>
              <div class="mt-3">
                <p class="m-0">
                  <span class="f-size-18">Canceled</span>
                  <span><v-icon class="ms-sm-2 ms-1" size="12px" color="red"
                      style="position: relative;top: -2px;">mdi-circle</v-icon></span>
                </p>
                <p class="f-size-12 m-0">Reason for cancelation: Payment Failed</p>
                <p class="f-size-12 m-0">Booking No. 3238345343</p>
              </div>
            </v-card>

            <v-card rounded="2" class="p-3 mt-3" @click.prevent="flightDetailSection()">
              <div class="row">
                <div class="col-10">
                  <h5>Travel Details</h5>
                </div>
                <div class="col-1">
                  <v-icon>mdi-chevron-right-box</v-icon>
                </div>
              </div>
            </v-card>

            <v-card rounded="2" class="p-3 mt-3">
              <h5>Payment Details</h5>

              <div class="bg-light mt-3 p-2">
                <div class="row">
                  <div class="col-md-6">
                    <p class="f-size-18 fw-500">Total Price</p>
                  </div>
                  <div class="col-md-6">
                    <p class="f-size-16 fw-500 text-end">US$ 2025.56</p>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-6">
                    <h6>Base Price</h6>
                  </div>
                  <div class="col-md-6">
                    <h6 class="text-end">US$ 125.56</h6>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <h6>Taxes & Fees</h6>
                  </div>
                  <div class="col-md-6">
                    <h6 class="text-end">US$ 21.25</h6>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </v-container>
  </div>





  <v-layout style="position: static !important;" @click.stop>
    <v-navigation-drawer v-model="drawer" persistent temporary location="right" style="width:500px;">
      <div class="d-flex justify-end p-2">
        <v-icon size="28" color="danger" @click="closeModal()">mdi-close-box</v-icon>
      </div>
      <div class="p-3">
        <h4>Departure</h4>
        <div style="height:200px;">
          <v-timeline truncate-line="both">
            <v-timeline-item dot-color="pink" size="x-small">
              <template v-slot:opposite>
                <span>10:12</span>
              </template>
              <p class="m-0">Toronto</p>
              <p>Airport Name</p>
            </v-timeline-item>

            <v-timeline-item dot-color="red" size="x-small">
              <template v-slot:opposite>
                <p class="m-0">Air India</p>
              </template>

              <template v-slot:icon>
                <v-avatar>
                  <img src="https://i.pravatar.cc/64">
                </v-avatar>
              </template>
              <span>22:25</span>
            </v-timeline-item>

            <v-timeline-item dot-color="pink" size="x-small">
              <template v-slot:opposite>
                <span>15:07</span>
              </template>
              <p class="m-0">Chennai</p>
              <p>Airport Name</p>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
    </v-navigation-drawer>
    <!-- <v-main style="height: 250px">
        <div class="d-flex justify-center align-center h-100">
          <v-btn color="primary" @click.stop="drawer = !drawer">Toggle</v-btn>
        </div>
      </v-main> -->
  </v-layout>





  <v-dialog v-model="managePopup" persistent>
    <div class="d-flex justify-center managePopup">
      <v-card class="p-4" width="750px">
        <div class="d-flex justify-content-between mb-2">
          <span class="invisible"></span>
          <h3>Manage Trip</h3>
          <span><v-icon @click="(managePopup = false), (modifyCheckBox = false)">mdi-close</v-icon></span>
        </div>
        <h5>For more information, contact us</h5>

        <div>
          <div class="mt-2 f-size-12">
            <h6>How much it costs</h6>
            <span>If changes are allowed by the airline, the following charges
              apply:</span>
            <ul class="mt-2">
              <li>
                airline's penalty when included in the ticket's fare rules
              </li>
              <li>
                our service fee of 56 CAD per person (not applicable for
                Platinum Support Package)
              </li>
              <li>any difference in fare and taxes from the original trip</li>
            </ul>
          </div>

          <div class="mt-2 f-size-12">
            <h6>How to change a ticket</h6>
            <span>If changes are allowed by the airline, the following charges
              apply:</span>
            <ul class="mt-2">
              <li>
                airline's penalty when included in the ticket's fare rules
              </li>
              <li>
                our service fee of 56 CAD per person (not applicable for
                Platinum Support Package)
              </li>
              <li>any difference in fare and taxes from the original trip</li>
            </ul>
          </div>
        </div>

        <div class="border border-1 border-gray p-2">
          <v-form ref="form" v-model="validForm" @submit.prevent="toggleTawkToChat()">
            <div>
              <div class="p-2 bg-gray">
                <span>Recommended</span>
              </div>
            </div>
            <div>
              <v-icon>mdi-chat-processing</v-icon>
              <span class="ms-2">Chat</span>
            </div>
            <div class="d-flex justify-center align-center checked-box" style="max-width: fit-content">
              <v-checkbox v-model="modifyCheckBox" @click="errorMsg = false" class="pe-3 ps-0 checkBox"
                style="flex: none"></v-checkbox>
              <span class="agree-content f-size-12">By using this chat I allow my data to be stored for security
                and quality review as mentioned in the Privacy Policy.
              </span>
            </div>
            <p v-if="errorMsg" class="w-50 ps-4 m-0 f-size-12 fw-400" style="color: #b00020">
              You must agree to continue!
            </p>
            <div class="text-center">
              <!-- <v-btn @click="javascript:void(Tawk_API.toggle())" rounded color="primary" dark>Start Live Chat</v-btn> -->
              <v-btn rounded color="primary" type="submit" dark>Start live chat</v-btn>
            </div>
          </v-form>
        </div>

        <div class="border border-1 border-gray p-2 d-flex justify-content-between mt-2">
          <div @click="managePopup = false">
            <v-icon>mdi-email</v-icon>
            <a class="ms-2" :href="'mailto:' + emailPortal">{{
                  emailPortal
                }}</a>
          </div>
          <div class="invisible">
            <v-icon>mdi-menu-down-outline</v-icon>
          </div>
        </div>

        <div class="mt-3">
          <span class="f-size-12">Please note that a support ticket will only be created for
            inquiries submitted through channels listed on this page. We will
            not respond to messages received outside of these options, including
            but not limited to those sent via regular mail and sent directly to
            employees</span>
        </div>

        <!-- <div class="d-flex mt-3">
          <v-btn @click="tryAgain1()" color="#ee2b34">Try Again</v-btn>

          <v-btn class="ms-2" to="/" color="#ee2b34">Return to Home</v-btn>
        </div> -->
      </v-card>
    </div>
  </v-dialog>

</template>

<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  data() {
    return {
      managePopup: false,
      drawer: false,

      retriveData: [],
      fromAirports: [],
    }
  },
  methods: {
    flightDetailSection() {
      this.drawer = !this.drawer

      if (this.drawer) {
        document.documentElement.style.overflow = 'hidden';
        document.body.scroll = "no";
        document.documentElement.style.pointerEvents = 'none';



        document.body.addEventListener('copy', this.preventCopyPaste);
        document.body.addEventListener('paste', this.preventCopyPaste);
      }

    },

    closeModal() {
      this.drawer = false;
      document.documentElement.style.overflow = 'scroll';
      document.documentElement.style.pointerEvents = '';
    },

    preventCopyPaste(event) {
      event.preventDefault();
    },







    // getColor() {
    //     if (
    //       this.bookingStatus == "cancelled" ||
    //       this.bookingStatus == "ticketed"
    //     ) {
    //       return "danger";
    //     } else if (this.bookingStatus === "booked") {
    //       return "success";
    //     } else {
    //       return "warning";
    //     }
    //   },

    getConfig() {
      // let getConfigData = this.$store.state.data

      const getConfigData = getConfigDataFromLocalStorage();

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      if (getConfigData) {
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;

        this.portal_sitetitle =
          getConfigData.payload.portal_configuration.site_title;
        this.modifyBookingUrl =
          getConfigData.payload.portal_configuration.API_endpoints.modify_booking;
        this.portalId = getConfigData.payload.portal_configuration.portal_id;

        if (this.getPassid.includes("env")) {
          this.retreiveBookApi =
            getConfigData.payload.portal_configuration.API_endpoints.meta_retreive;
          console.log(
            this.retreiveBookApi,
            "retreiveBookApiretreiveBookApi....1"
          );
        } else {
          this.retreiveBookApi =
            getConfigData.payload.portal_configuration.API_endpoints.retreive_booking;
          console.log(
            this.retreiveBookApi,
            "retreiveBookApiretreiveBookApi....2"
          );
        }
      }
    },


    async retrivingBooking() {
      this.booking_refId = JSON.parse(this.$route.query.booking_id);
      let retreivebooking = {
        request_type: "retreive_booking",
        booking_id: this.booking_refId.booking_Id,
        pnr: this.booking_refId.pnr,
        last_name: this.booking_refId.last_name,
        portal_id: this.portalId,
      };

      console.log(retreivebooking, "retreivebookingretreivebooking");

      await axios.post(this.retreiveBookApi, retreivebooking, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          console.log(response, "vasanth");
          if (response.data) {
            setTimeout(() => {
              this.overlay = false;
              this.showConfirmPage = true;
            }, 5000);
          }

          console.log(response.data, "retrievebooking555555555.........2999");
          this.retriveData.push(response.data);

          if (this.retriveData.length > 0) {
            this.bookingretrive();
          }
        })
        .catch((error) => {
          console.log(error, "retreivebooking......2");

          setTimeout(() => {
            this.dialog3 = true;
          }, 20000);
        });

      // this.local_id = this.$route.query.pass_id;
      // this.Localoneway_id = this.$route.query.pass_uid;
      // localStorage.setItem("delete_Id", this.Localoneway_id);
      // localStorage.setItem("delete_Id", this.local_id);
    },





  },
  created() {
    this.overlay = true;
    this.getPassid =
      this.$route.query.pass_id ||
      this.$route.query.pass_uid ||
      this.$route.query.booking_id;
    // console.log(this.getPassid, "this.getPassidthis.getPassid....1");
    // console.log(this.$route.query.pass_id, "this.getPassidthis.getPassid....2");
    // console.log(
    //   this.$route.query.pass_uid,
    //   "this.getPassidthis.getPassid....3"
    // );
    // console.log(
    //   this.$route.query.booking_id,
    //   "this.getPassidthis.getPassid....4"
    // );

    this.getConfig();

    this.retrivingBooking();

  },
}

</script>



<style scoped>
.f-size-20 {
  font-size: 20px;
}

.f-size-18 {
  font-size: 18px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}


>>>.v-navigation-drawer__scrim {
  pointer-events: none !important;
}



/* >>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 24px;
  height: 50%;
} */
</style>