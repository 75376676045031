<template>
    <div v-if="showCookieInfo" class="cookie-info">
      <p>
        Third-party cookies are required for this website to function properly. 
        Please enable third-party cookies in your browser settings to continue.
      </p>
      <button class="text-dark" @click="closeCookieInfo">Got it</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showCookieInfo: true
      };
    },
    mounted() {
      // Check if third-party cookies are blocked
      if (!navigator.cookieEnabled) {
        this.showCookieInfo = true;
      }
    },
    methods: {
      closeCookieInfo() {
        this.showCookieInfo = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .cookie-info {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #333;
    color: white;
    text-align: center;
  }
  .cookie-info p {
    margin: 0;
  }
  .cookie-info button {
    margin-top: 5px;
    padding: 5px 10px;
    background-color: #ccc;
    border: none;
    cursor: pointer;
  }
  </style>
  