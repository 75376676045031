<template>

	<div :class="{ 'vh-100': dataloader }">
		<section>
			<div class="htmlContent" v-html="termsContent"></div>
		</section>

		<div v-if="dataloader">
			<dataloader></dataloader>
		</div>
	</div>

</template>

<script>

import axios from "axios";
import dataloader from "@/components/dataLoder.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
	components: {
		dataloader
	},
	data() {
		return {

			termsContent: "",
			getType: "",
			getcontentUrl: "",
			getLangname: "",
			dataloader: true,
		}
	},

	methods: {

		getConfig() {
			const getConfigData = getConfigDataFromLocalStorage();
			if (getConfigData) {

				let locale = this.getLangname;

				console.log(locale, "getcontentUrlrgetcontentUrlrgetcontentUrlrab.....1")

				let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data[locale]

				console.log(getsiteContentData, "getcontentUrlrgetcontentUrlrgetcontentUrlrab.....2")


				this.getcontentUrl = getsiteContentData.termsofuse.url

				this.getType = getsiteContentData.termsofuse.type

				this.getConfigFunc();
			}
		},

		getConfigFunc() {
			console.log("getcontentdatadatadatadatadatadata")

			axios.get(this.getcontentUrl, {
				headers: {
					"Content-Type": this.getType,
				},
			})
				.then((response) => {

					this.termsContent = response.data
					this.dataloader = false;
					console.log(response.data, "getcontentdatadatadatadatadatadata...1")

				})

				.catch((error) => {
					this.dataloader = true;
					console.log(error, 'erroroor.....')
				})
		},
		updateContent() {
			this.getLangname = this.$i18n.locale;
			console.log(this.$i18n.locale, 'getcontententUrl...1')
			this.getConfig();
		}
	},

	watch: {
		"$i18n.locale": {
			handler: function () {
				this.updateContent();
				this.getConfig();
				this.getConfigFunc();
			},
			deep: true,
		},
	},


	created() {
		this.updateContent();
		// this.getLangname = this.$i18n.locale;
		// this.getConfig();
		this.getConfigFunc();
	}
}

</script>
<style scoped>
@media screen and (max-width:430px) {
	>>>.htmlContent a {
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	>>>.htmlContent span {
		white-space: pre-wrap;
		word-wrap: break-word;
	}
}
</style>
