<template>
    <v-footer color="lightgrey" padless class="text-center text-white" style="background-color: #caced1;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-md-0">
                    <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
                        <v-img :src="require('@/assets/country1.jpg')"></v-img>
                        
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-md-0">
                    <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
                        <v-img :src="require('@/assets/country2.jpg')"></v-img>
                        
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-md-0">
                    <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
                        <v-img :src="require('@/assets/country3.jpg')"></v-img>
                        
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-md-0">
                    <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
                        <v-img :src="require('@/assets/country4.jpg')"></v-img>
                        
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-md-0">
                    <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
                        <v-img :src="require('@/assets/country5.jpg')"></v-img>
                        
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 mb-4 mb-md-0">
                    <div class="bg-image hover-overlay ripple shadow-1-strong rounded" data-ripple-color="light">
                        <v-img :src="require('@/assets/country6.jpg')"></v-img>
                        
                    </div>
                </div>
            </div>


            <div class="row mt-5 text-dark">
                <div class="col-lg-2">
                    <div class="footer-col-1">
                        <v-img :src="require('@/assets/buymytrip.png')" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mt-4 mt-lg-0 offset-lg-1">
                    <h6 class="fw-bold">Company</h6>
                    <ul class="p-0 fs-14">
                        <li>About us</li>
                        <li>Destination</li>
                        <li>Packages</li>
                        <li>Contact us</li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mt-4 mt-lg-0">
                    <h6 class="fw-bold">Help</h6>
                    <ul class="p-0 fs-14">
                        <li>Help/Faqs</li>
                        <li>Cancel Your Flight</li>
                        <li>Press</li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mt-4 mt-lg-0">
                    <h6 class="fw-bold">More</h6>
                    <ul class="p-0 fs-14">
                        <li>Domestic Flights</li>
                        <li>Investor Realtion</li>
                        <li>Partnership</li>
                        <li>Jobs</li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-6 mt-4 mt-lg-0">
                    <h6 class="fw-bold">Terms</h6>
                    <ul class="p-0 fs-14">
                        <li>Privacy policy</li>
                        <li>Terms of use</li>
                        <li>Accessibility</li>
                    </ul>
                </div>
            </div>

            <div class="row" style="background-color: rgba(0, 0, 0, 0.2);">
                <div class="col-6 d-flex align-center">
                    <a class="btn-link btn-floating btn-lg text-dark me-4 social-icons" href="#!" role="button"
                        data-mdb-ripple-color="dark">
                        <v-icon color="white">
                            mdi-facebook
                        </v-icon>
                    </a>
                    <!-- Twitter -->

                    <!-- Google -->
                    <a class="btn-link btn-floating btn-lg text-dark me-4 social-icons" href="#!" role="button"
                        data-mdb-ripple-color="dark">
                        <v-icon color="white">
                            mdi-instagram
                        </v-icon>
                    </a>
                    <a class="btn-link btn-floating btn-lg text-dark me-4 social-icons" href="#!" role="button"
                        data-mdb-ripple-color="dark">

                        <v-icon color="white">
                            mdi-twitter
                        </v-icon>
                    </a>
                </div>
                <div class="col-6 text-end p-3">
                    © 2023 Copyright:
                    <a class="text-white" href="">buymytrip.com</a>
                </div>
            </div>
        </div>
    </v-footer>
</template>



<style scoped>
/* footer */


.v-footer{
    margin-top: 10px;
}
.social-icons {
    color: var(--primary-color) !important;
}

.fs-14 {
    font-size: 14px;
    list-style-type: none;
}

.fs-14 li {
    padding: 6px;
}

section {
    padding: 10px 5px;
}

.slide-card {
    border-radius: 10%;
}

.card-test {
    position: relative;
    padding: 10px 15px;
    border-radius: 8%;
    max-height: 500px;
}

.card-img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50%;
    right: 0;
    transform: translateY(-50%);
}

.radio {

    background-color: #E2E7FF;
    border-radius: 14px;
    color: #007bff;
    width: 120px;
    font-size: 15px !important;
    font-weight: 400px;
}

.normal-radio {
    font-size: 14px !important;
    font-weight: 400px;
    height: 30px;
    width: 130px;
}

.cart-adult {
    padding: 2px 15px 15px 15px;
    width: 450px;
    cursor: pointer;

}

.adult {
    border: 1px solid lightgrey;
    border-radius: 5px;
    background-color: #F7F7F7;
}

.adult-plus {

    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #05386d;
}

.adult-title {
    font-size: 12px;
    font-weight: 600;
    color: #05386d;
}

.p-datepicker-group-container {

    background-color: #ffffff !important;
    width: 200px;
    padding: 5px 3px;
    border: 1px solid lightgray;
    position: relative;
    right: 14px;

}

.calendar-container {
    display: flex;
    align-items: center;
}

.icon-container {
    margin-right: 10px;
}

.calendar-wrapper {
    border-bottom: 1px solid #a9a1a1;
    max-width: 500px;
}

@media only screen and (max-width: 992px) {
    .car-right {
        width: 100%;
        height: auto;
        margin-top: 0;
    }

    .session-bg {
        background-image: none;
    }

    .back {
        background-image: none;
    }

    .flight-right {
        width: 80% !important;
    }

    .bannar-bg {
        background: none;
    }

    /* .form-btn {
    padding-right: 30px !important;
  } */

    .btn-box,
    .pipe {
        color: blueviolet !important;
    }

    .btn-active {
        background-color: blueviolet;
        color: white !important;
    }
}

.mdi-airplane-takeoff::before {
    content: "\F05D5";
    margin-top: 10px;
}

.mdi-airplane-landing::before {
    content: "\F05D4";
    margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
    .owl-theme .owl-nav [class*="owl-"] {
        margin-right: 0 !important;
    }

    .owl-theme .item {
        width: auto;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .owl-theme .owl-nav [class*="owl-"] {
        margin-right: 0 !important;
    }

    .owl-theme .item {
        width: auto;
    }
}
</style>