<template>
  <div class="row d-flex flex-column align-end" v-if="isVisible">
    <div
      class="col-lg-4 d-flex flex-column align-center"
      style="position: absolute; z-index: 199"
    >
      <v-card
        style="
          width: 80%;
          border-radius: 7px;
          box-shadow: 0px 0px 8px 0px black;
        "
        outlined
      >
        <!-- v-click-outside="onClickOutside"> -->
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-md-12 main-page">
            <div
              class="bg-light main-page mx-auto p-2"
              style="background-color: #f8b2b2; width: 100%; height: auto"
            >
              <div
                class="col-12 pe-1 pt-1 d-flex justify-end"
                @click="closeLoginBox()"
              >
                <v-icon style="color: navy; cursor: pointer" size="small"
                  >mdi-close-circle</v-icon
                >
              </div>

              <v-form
                ref="form"
                v-if="loginshow"
                v-model="valid"
                @submit.prevent="loginsubmitForm"
                style="width: 90%"
              >
                <v-text-field
                  v-model="loginuser.userEmail"
                  :rules="eMail"
                  :label="this.$t('loginContents.labels.Email')"
                  variant="underlined"
                  :placeholder="this.$t('loginContents.placeholder.email')"
                  style="height: 55px; font-size: 15px !important"
                ></v-text-field>
                <!-- <span style="color: red;">invalid Username</span><br> -->

                <v-text-field
                  v-model="loginuser.userPass"
                  :rules="uPass"
                  :label="this.$t('loginContents.labels.password')"
                  :placeholder="this.$t('loginContents.placeholder.password')"
                  variant="underlined"
                  required
                  :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible ? 'text' : 'password'"
                  @click:append-inner="visible = !visible"
                  style="height: 55px; font-size: 15px !important"
                ></v-text-field>
                <!-- <span style="color: red;">invalid password</span> -->

                <div class="row">
                  <div class="col-6">
                    <v-checkbox
                      v-model="terms"
                      :label="this.$t('loginContents.labels.rememberMe')"
                    ></v-checkbox>
                  </div>
                  <div class="col-6">
                    <span style="position: relative; top: 15px">
                      <p
                        class="forgotStyle"
                        @click="
                          (this.loginshow = false), (this.forgotshow = true)
                        "
                      >
                        {{ $t("loginContents.buttonsLink.forgotPassword") }}
                      </p>
                    </span>
                  </div>
                </div>

                <div>
                  <p class="text-danger" v-if="errorMsg">
                    {{ $t("loginContents.errorNotify.errorMsg") }}
                  </p>
                </div>

                <div class="d-flex mb-3">
                  <div class="col-9 d-flex justify-end">
                    <v-btn
                      style="background-color: #ee2b34; color: white"
                      width="60%"
                      :disabled="btnDisabled"
                      type="submit"
                      >{{ $t("loginContents.buttonsLink.login") }}</v-btn
                    >
                  </div>
                  <div class="col-3 ps-1 d-flex justify-end">
                    <v-progress-circular
                      v-if="loadLogin"
                      :width="3"
                      style="color: navy"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </div>
                <!-- <v-divider></v-divider> -->

                <p
                  class="text-center"
                  style="font-weight: 500; font-size: 14px"
                >
                  {{ $t("loginContents.labels.createAnaccount") }}
                  <span @click="signUp()" style="cursor: pointer">
                    &nbsp; <b style="color: navy">{{ $t("loginContents.signUp") }}</b></span
                  >
                </p>
              </v-form>

              <v-form
                ref="form"
                v-if="forgotshow"
                v-model="valid"
                @submit.prevent="submitResetForm"
                style="width: 90%"
              >
                <h4 v-if="forgotshow"> {{ $t("loginContents.labels.resetPassword") }}</h4>

                <v-text-field
                  v-model="resetDetail.resetMail"
                  :rules="eMail"
                  :label="this.$t('loginContents.labels.Email')"
                  variant="underlined"
                  placeholder="Enter your email"
                  style="height: 55px; font-size: 15px !important"
                ></v-text-field>
                <!-- <span style="color: red;">invalid Username</span><br> -->

                <div class="row justify-end">
                  <div class="col-10 my-3 text-end">
                    <span
                      @click="
                        (this.loginshow = true), (this.forgotshow = false)
                      "
                      style="
                        font-weight: 500;
                        font-size: 13px;
                        letter-spacing: 1px;
                      "
                    >
                      {{ $t("loginContents.labels.rememberPass") }}
                      <span
                        style="
                          color: #0d6efd;
                          text-decoration: underline;
                          cursor: pointer;
                        "
                        >{{ $t("loginContents.buttonsLink.login") }}</span
                      >
                    </span>
                  </div>
                </div>

                <div>
                  <p class="text-danger" v-if="errforget">{{ $t("loginContents.labels.emailnotfound") }}</p>
                </div>

                <div class="d-flex mb-3">
                  <div class="col-9 d-flex justify-end">
                    <v-btn
                      style="background-color: #ee2b34; color: white"
                      width="60%"
                      :disabled="btnDisabled"
                      type="submit"
                      >{{ $t("loginContents.buttonsLink.submit") }}</v-btn
                    >
                  </div>
                  <div class="col-3 ps-1 d-flex justify-end">
                    <v-progress-circular
                      v-if="loadforget"
                      :width="3"
                      style="color: navy"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </div>
              </v-form>

              <div v-if="emailsentMsg">
                <div class="d-flex justify-center align-center p-3">
                  <p>
                    {{ $t("loginContents.buttonsLink.emailSentMsg") }}
                  </p>
                </div>

                <div class="d-flex justify-center align-center p-3">
                  <span
                    @click="
                      (this.loginshow = true),
                        (this.forgotshow = false),
                        (this.emailsentMsg = false)
                    "
                    style="
                      font-weight: 500;
                      font-size: 13px;
                      letter-spacing: 1px;
                    "
                  >
                     {{ $t("loginContents.labels.rememberPass") }}
                    <span
                      style="
                        color: #0d6efd;
                        text-decoration: underline;
                        cursor: pointer;
                      "
                      >{{ $t("loginContents.buttonsLink.login") }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>

  <div class="row d-flex flex-column align-end d-none" v-if="registerbox">
    <div
      class="col-lg-4 d-flex flex-column align-center"
      style="position: absolute; z-index: 2"
    >
      <v-card
        style="
          width: 80%;
          border-radius: 7px;
          box-shadow: 0px 0px 8px 0px black;
        "
        outlined
        v-click-outside="onClickOutside"
      >
        <div class="row d-flex align-items-center justify-content-center">
          <div class="col-md-12 main-page">
            <div
              class="bg-light main-page mx-auto shadow-none"
              style="background-color: #f8b2b2; width: 100%; height: auto"
            >
              <div
                class="col-12 pe-1 pt-1 d-flex justify-end"
                @click="closeRegisterBox()"
              >
                <v-icon size="small" style="color: navy; cursor: pointer"
                  >mdi-close-circle</v-icon
                >
              </div>

              <div v-if="regForm">
                <v-form
                  ref="form"
                  v-model="valid"
                  @submit.prevent="registerSubmitForm"
                  style="width: 100%"
                >
                  <v-text-field
                    v-model="personal.username"
                    :rules="userName"
                    label="Name"
                    variant="underlined"
                    placeholder="Enter your Name"
                    style="height: 60px"
                  ></v-text-field>
                  <!-- <span style="color: red;">invalid Username</span><br> -->

                  <v-text-field
                    v-model="personal.email"
                    :rules="eMail"
                    label="Email ID"
                    placeholder="johndoe@gmail.com"
                    type="email"
                    variant="underlined"
                    style="height: 60px"
                  ></v-text-field>
                  <!-- <span style="color: red;">invalid Email</span><br> -->

                  <v-text-field
                    v-model="personal.password"
                    :rules="passWord"
                    label="Password"
                    placeholder="Enter your password"
                    variant="underlined"
                    required
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'"
                    @click:append-inner="visible = !visible"
                    style="height: 60px"
                  ></v-text-field>
                  <!-- <span style="color: red;">invalid password</span> -->

                  <v-text-field
                    v-model="personal.rePassword"
                    :rules="repassWord"
                    label="Re-Password"
                    placeholder="Enter your Re-password"
                    variant="underlined"
                    required
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="visible ? 'text' : 'password'"
                    @click:append-inner="visible = !visible"
                    style="height: 60px"
                  ></v-text-field>
                  <!-- <span style="color: red;">invalid Re-password</span> -->

                  <div class="d-flex my-4">
                    <div class="col-9 d-flex justify-end">
                      <v-btn
                        width="70%"
                        :disabled="btnDisabled"
                        type="submit"
                        style="background-color: #ee2b34; color: white"
                        >Submit</v-btn
                      >
                    </div>
                    <div class="col-3 ps-3 d-flex justify-end">
                      <v-progress-circular
                        v-if="loadRegister"
                        :width="3"
                        style="color: navy"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </div>
                  <!-- <v-divider></v-divider> -->
                  <p style="font-weight: 500">
                    You have already an account?
                    <span @click="signInAgain" style="cursor: pointer">
                      &nbsp; <b style="color: navy">Sign In</b></span
                    >
                  </p>
                </v-form>
              </div>

              <div v-if="regMsg">
                <div class="d-flex justify-center align-center p-3">
                  <v-icon color="success" size="50"
                    >mdi-check-circle-outline</v-icon
                  >
                  <h4 class="text-success ps-2 m-0">
                    Register Successfully...
                  </h4>
                </div>
                <div class="d-flex justify-center align-center p-3">
                  <p>{{ registerMsg }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>

  <div v-if="registerCom">
    <registerCom :isRegister="registerCom" />
  </div>
</template>

<script>
import registerCom from "@/components/registerCom.vue";
import axios from "axios";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    registerCom,
  },
  data() {
    return {
      loginbox: true,
      registerCom: false,
      terms: false,
      visible: false,
      loadLogin: false,
      btnDisabled: false,
      errorMsg: false,

      loginshow: true,
      forgotshow: false,
      emailsentMsg: false,
      resetshow: false,
      loadforget: false,
      errforget: false,
      userName: "",
      userEmail: "",

      customKey: "qt3cU5nWjV2OPeQBEYkwD4adr8oy9I",
      customMail: "sarath@gmail.com",

      loginuser: {
        userEmail: "",
        userPass: "",
        confirmPass: "",
      },

      resetDetail: {
        resetMail: "",
      },

      eMail: [
        (v) => !!v || "Please enter your email",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      uPass: [(v) => !!v || "Please enter your Password"],

      loginReq: {
        request_type: "user_login",
        email: "",
        password: "",
        portal_id: "11111",
        token: "Tc7RQaX79Hrx5CFK",
      },

      forgetDetails: {
        request_type: "forgot_password",
        email: "irwogms@gmail.com",
        user_id: "443355",
        portal_id: "11111",
      },

      loginUrl: "",
      forgotPassword: "",

      // Register Code ----->

      valid: false,

      registerbox: false,
      loginSection: true,
      // terms: false,
      // visible: false,

      regForm: true,
      regMsg: false,
      registerMsg: "",
      loadRegister: false,
      // btnDisabled: false,

      personal: {
        username: "",
        email: "",
        password: "",
        rePassword: "",
      },

      // userName: [
      //     v => !!v || 'Please enter your Username',
      //     v => (v.length >= 3) || 'Name must be greater than 3 ',
      // ],

      // eMail: [
      //     v => !!v || 'E-mail is required',
      //     v => /.+@.+/.test(v) || 'E-mail must be valid',
      // ],

      passWord: [
        (v) => !!v || "Please enter your Password",
        // v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        // v => (v && v.length <= 20) || 'Password must be less than 20 characters',
        // v => (v && /[a-z]/.test(v)) || 'Password must contain at least one lowercase letter',
        // v => (v && /[!@#$%^&*]/.test(v)) || 'Password must contain at least one special character'
      ],
      repassWord: [
        (v) => !!v || "Please enter your Re-password",
        (v) => v === this.personal.password || "Passwords do not match",
      ],

      // strResult: "0123456789",

      // user_reqId:"",

      registerReq: {
        request_type: "user_register",
        user_name: "",
        email: "",
        password: "1234567890",
        portal_id: "11111",
        portal_group_id: "11111",
        user_id: "",
        token: "Tc7RQaX79Hrx5CFK",
      },

      registerUrl: "",
      portalGroupId: "",
      portalId: "",

      // Register Code End ----->
    };
  },
  methods: {
    signUp() {
      this.$emit("switch-to-register");
      // this.registerCom = true;
      // this.registerCom = true;
      //this.registerbox = true
    },

    onClickOutside() {
      this.loginbox = false;
      console.log(this.loginbox, "5555555");
    },

    loginsubmitForm() {
      if (this.valid) {
        console.log(this.loginuser, "vvvvaaaallll");

        this.loadLogin = true;
        this.btnDisabled = true;

        this.loginReq.email = this.loginuser.userEmail;
        this.loginReq.password = this.loginuser.userPass;
        // this.loginReq.portal_id = this.portalId
        // console.log(this.loginReq, 'kkkk.....')

        // axios.post('https://dl.buymytrip.com/api/b2clogin', this.loginReq, {
        axios
          .post(this.loginUrl, this.loginReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("Response:", response.data);
            localStorage.setItem("loginuser", JSON.stringify(response.data));

            localStorage.setItem("value", true);

            this.errorMsg = false;
            this.loginbox = false;

            setInterval(() => {
              location.reload();
            }, 500);

            // console.log(response.data.user,'.....Response...:');
            // this.forgetDetails.user_id=response.data.user.user_id
            // console.log(this.forgetDetails.user_id,'.....Response..11111.:');
            // JSON.stringify(localStorage.setItem('loginDatas', this.loginuser))
            // let logindata = JSON.stringify(response.data)
            // localStorage.setItem("loginDatas", logindata)
          })
          .catch((error) => {
            console.error(
              "Error:",
              error.response ? error.response.data : error.message
            );
            this.errorMsg = true;
            // alert("Incorrect email or password...")
            this.loadLogin = false;
            this.btnDisabled = false;
          });
      }
    },

    submitResetForm() {
      // this.loginbox = false;

      if (this.valid) {
        // this.emailsentMsg = true
        // this.forgotshow = false
        this.loadforget = true;

        this.forgetDetails.email = this.resetDetail.resetMail;
        this.forgetDetails.user_id = this.resetDetail.resetMail;
        this.forgetDetails.portal_id = this.portalId;
        this.forgetDetails.portal_group_id = this.portalGroupId;

        console.log(this.forgotPassword, this.forgetDetails, "reqqqqqqqqqq");

        axios
          .post(this.forgotPassword, this.forgetDetails, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          .then((response) => {
            console.log(response, "rrrreeeeeessssssss.....");
            this.loadforget = false;
            if (response.data.forgot_password_response == "success") {
              this.forgotshow = false;
              this.emailsentMsg = true;
            } else if (response.data.forgot_password_response == "fail") {
              this.forgotshow = true;
              this.emailsentMsg = false;
              this.loadforget = false;
              this.errforget = true;
            }
          })
          .catch((errors) => {
            console.log(errors, "eeerrrrooorrr");
            this.forgotshow = true;
          });
      }
    },

    closeLoginBox() {
      this.loginbox = false;
      this.$emit("close"); // Emit the 'close' event to the parent component
      console.log(this.loginbox, "1111111");
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.loginUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.b2clogin;
        this.registerUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.b2cregister;
        this.forgotPassword =
          getConfigData1.payload.portal_configuration.API_endpoints.forgot_password;
        this.portalId = getConfigData1.payload.portal_configuration.portal_id;
        this.portalGroupId =
          getConfigData1.payload.portal_configuration.portal_group_id;

        console.log(this.loginUrl, "head...reg..1..");
      }
    },

    // Regiter code

    signInAgain() {
      //   this.loginSection = true
      this.loginbox = true;
      this.registerbox = false;
      console.log(this.loginSection, this.registerbox, "Workinglogin....");
    },
    // closeRegBox() {
    //         this.loginbox = false;
    //         this.$emit('close'); // Emit the 'close' event to the parent component
    //         console.log(this.loginbox, "1111111")
    //     },

    // onClickOutside() {
    //   this.registerbox = false
    // },
    registerSubmitForm() {
      console.log(this.valid, "oooooooooo");
      if (this.valid) {
        this.loadRegister = true;
        this.btnDisabled = true;

        // this.generateBookingRefId()

        console.log(this.personal, "eeeee......");

        this.registerReq.user_name = this.personal.username;
        this.registerReq.email = this.personal.email;
        this.registerReq.password = this.personal.password;
        this.registerReq.user_id = this.personal.email;

        console.log(this.registerReq, "ffff.....");

        axios
          .post(this.registerUrl, this.registerReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data) {
              this.regForm = false;
              this.regMsg = true;
              console.log("Response:", response.data);
              console.log("Response-Msg:", response.data.register.message);
              this.registerMsg = response.data.register.message;

              // this.registerbox = false
            }
          })

          .catch((error) => {
            console.log(error.response.data.message, "wejjfuoajk");
            alert(`${error.response.data.message}`);
            this.loadRegister = false;
            this.btnDisabled = false;
          });
      }
    },

    // getRandomChars(length) {
    //         let result = "";
    //         for (let i = 0; i < length; i++) {
    //             const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //             result += this.strResult[randomIndex];
    //         }
    //         console.log(result, "result");
    //         return result;
    //     },
    //     getRandomInt(min, max) {
    //         return Math.floor(Math.random() * (max - min + 1)) + min;
    //     },

    //     generateBookingRefId() {
    //         const randomChars1 = this.getRandomChars(1);
    //         const randomNum = this.getRandomInt(10, 99);
    //         const randomChars2 = this.getRandomChars(1);

    //         this.registerReq.user_id = randomChars1 + randomNum + randomChars2;
    //         console.log(this.registerReq.user_id, 'this.booking_refId ')
    //     },

    closeRegisterBox() {
      this.registerbox = false;
      this.$emit("close"); // Emit the 'close' event to the parent component
      console.log(this.registerbox, "00000");
    },

    // getConfig() {
    //   // let portalLocal = localStorage.getItem("portalData")
    //   // let getConfigData1 = JSON.parse(portalLocal)
    //   const getConfigData1 = getConfigDataFromLocalStorage();
    //   if (getConfigData1) {
    //     this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
    //     console.log(this.registerUrl, 'head...reg..1..')
    //   }
    // },
  },

  mounted() {
    console.log(this.isVisible, "isVisible....1");
  },

  created() {
    this.getConfig();
    console.log(this.isVisible, "isVisible....2");
  },
};
</script>

<style scoped>
/* Login Page */

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
}

.btn-active {
  background-color: blueviolet;
  color: white;
}

.v-checkbox .v-input__details {
  display: none;
}

>>> .v-label--clickable {
  cursor: pointer;
  font-size: 12px;
}

>>> .v-input__details {
  display: block !important;
}

.forgotStyle {
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1px;
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
  text-align: end;
}

/* Login Page End */

@media only screen and (max-width: 992px) {
  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}

/* Register code */

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
}

.btn-active {
  background-color: blueviolet;
  color: white;
}

>>> .v-input__details {
  display: block !important;
}

/* .v-checkbox .v-input__details {
  display: none;
} */

/* Login Page End */

@media only screen and (max-width: 992px) {
  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}
</style>
