<template>
    <div :class="{ 'vh-100': dataloader }">

        <section v-html="aboutContent"></section>

        <div v-if="dataloader">
            <dataloader></dataloader>
        </div>
    </div>
</template>


<script>

import axios from "axios";
import dataloader from "@/components/dataLoder.vue";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    components: {
        dataloader
    },
    data() {
        return {

            getType: "",
            dataloader: true,
            getcontentUrl: "",
            getLangname: "",

            aboutContent: "",
        }
    },

    methods: {

        getConfig() {
            let locale = this.getLangname
            console.log(locale, 'getcontententUrl...1')

            console.log(this.getLangname, 'getcontententUrl...2')

            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {

                let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data


                Object.keys(getsiteContentData).forEach(v => {
                    if (v == locale) {
                        this.getcontentUrl = getsiteContentData[v].aboutus.url
                        this.getType = getsiteContentData[v].aboutus.type

                        console.log(getsiteContentData[v], v, locale, 'getsiteContentDatagetsiteContentData')
                    }
                })
                // this.getcontentUrl = getsiteContentData.aboutus.url
                console.log(this.getcontentUrl, 'getcontententUrl...4')

                this.getConfigFunc();
            }
        },

        getConfigFunc() {

            console.log(this.getcontentUrl, 'getcontententUrl...5')
            axios.get(this.getcontentUrl, {
                headers: {
                    "Content-Type": this.getType,
                },
            })
                .then((response) => {
                    if (response) {
                        this.aboutContent = response.data
                    }
                    console.log(this.aboutContent, "getcontentUrlrgetcontentUrlrgetcontentUrlrab.....");
                    this.dataloader = false;
                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                    this.dataloader = true;
                })
        },


        updateContent() {
            let getlanglocale = this.$i18n.locale;

            this.$router.push({
                path: '/aboutus',
                query: { lang: getlanglocale }
            }).then(() => {
                this.getLangname = this.$route.query.lang;
                console.log(this.getLangname, 'getcontententUrl...7');
                this.getConfig();
            }).catch(() => {
                this.getLangname = this.$i18n.locale;
                console.log(this.getLangname, 'getcontententUrl...8');
                this.getConfig();
            });

            console.log(this.getLangname, 'getcontententUrl...1');
        }

    },

    watch: {

        "$i18n.locale": {
            handler: function () {
                this.updateContent();
            },
            deep: true,
        },

        aboutContent: {
            handler() {
                // console.log('Aboutcontentupdated:', newVal);
            },
            deep: true,
        },
    },

    mounted() {
        this.updateContent();
    },

    created() {
        this.updateContent();
    }
}

</script>