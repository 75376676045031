<template>
    <section>
        <div class="row ">
            <div class="col-md-12">
                <v-card>
                    <v-form ref="form" v-model="valid" @submit.prevent="datas">
                    <div style="background-color: #5191fa ;padding: 10px;font-size: 20px;font-weight: 500;color: white;">
                        <v-cart-title>
                            Traveller Details
                        </v-cart-title>
                    </div>
                    <div class="pa-2">
                        <div class="row mt-2 " v-for="(data, index) of adultData" :key="data">
                            <h6 style="color:blue">Adult  {{ index + 1 }} {{ title }}</h6>

                            <div class="row gy-2">
                                <div class="col-lg-3">
                                    <v-text-field label="First Name" variant="outlined" v-model="data.fname"
                                    :rules="[v => !!v || 'this field is required']"></v-text-field>
                                </div>
                                <div class="col-lg-3">
                                    <v-text-field label="Last Name" variant="outlined" v-model="data.last"
                                    :rules="[v => !!v || 'this field is required']"></v-text-field>
                                </div>
                                <div class="col-lg-2">
                                    <v-text-field label="Middle Name" variant="outlined" v-model="data.middle"
                                        :rules="userName"></v-text-field>
                                </div>
                                <div class="col-lg-2">
                                    <v-select label="Salutation" :items="['Mr', 'Miss', 'Mrs', 'Ms']"
                                        v-model="data.salutation" variant="outlined"></v-select>
                                </div>
                                <div class="col-lg-2">
                                    <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"   :rules="[v => !!v || 'this field is required']"
                                        v-model="data.genter"></v-select>
                                </div>
                            </div>

                          

                            <div class="row gy-2">
                                <label>Date of Birth</label>
                                <div class="col-md-3 d-flex align-center">
                                    
                                    <span>
                                        <v-icon>mdi-calendar</v-icon>
                                    </span>
                                    <span class="p-float-label">
                                        <!-- <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                                            Departure
                                        </span> -->
                                        <VueDatePicker v-model="dateOfBirth" ref="dpRef1" :max-date="new Date()"   :rules="[v => !!v || 'this field is required']"
                                            :min-date="new Date(new Date().getFullYear() -100, new Date().getMonth(), new Date().getDate())"
                                            :format="dateFormat" :hide-navigation="['time']" auto-apply
                                            @update:modelValue="fromDateChanged" :on-click-outside="ClickOutDepat"
                                            class="px-0" />
                                        <!-- <span class="p-error">Dep. Date is required</span> -->
                                    </span>
                                </div>


                                <div class="col-md-6 mt-2">
                                    <v-btn-toggle class="d-flex align-center">
                                        <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                            @click="ffbdata(data, index)">
                                            FFp
                                            <v-tooltip activator="parent" location="top" width="200px">
                                                Please select an airline/frequent
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                            @click="tsadata(index)">
                                            TSA
                                            <v-tooltip activator="parent" location="top" width="200px">
                                                Please select an airline/frequent flyer program
                                            </v-tooltip>
                                        </v-btn>
                                        <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                                            @click="apidata(index)">
                                            APIS
                                            <v-tooltip activator="parent" location="top" width="200px">
                                                the validating carrier are being displayed.
                                            </v-tooltip>
                                        </v-btn>
                                    </v-btn-toggle>
                                </div>
                            </div>



                            <div>
                                <v-card class="ffb-cart" v-if="data.ffbnumber">
                                    <div align="end">
                                        <v-icon color="primary" @click="data.ffbnumber = false">
                                            mdi-close-circle
                                        </v-icon>
                                    </div>

                                    <h6>Frequent Flyer Program</h6>

                                    <div class="row mt-4">
                                        <div class="col-md-3">

                                            <v-select label="Frequent Flyer Program" rounted="5" v-model="air" :items="['Aeroplan-Air canada', 'Flying Returns-Air india', 'Air Points-Air America',
                                                'Phonix-Air china']" variant="outlined"></v-select>
                                        </div>
                                        <div class="col-md-3">
                                            <v-text-field label="FFP Number" variant="outlined"
                                                v-model="ffb"></v-text-field>
                                        </div>
                                        <div class="col-md-6" align="end">
                                            <v-btn variant="outlined" color="primary" @click="reset()">
                                                Reset
                                            </v-btn>
                                        </div>
                                    </div>



                                </v-card>

                                <v-card class="ffb-cart" v-if="data.TSAnumber">
                                    <div align="end">
                                        <v-icon color="primary" @click="data.TSAnumber = false">
                                            mdi-close-circle
                                        </v-icon>
                                    </div>

                                    <h6>Transport Security Administration</h6>
                                    <div class="row mt-4 gy-3">
                                        <div class="col-lg-3">
                                            <v-text-field label="Last name as per passport"
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-lg-3">
                                            <v-text-field label="First name as per passport"
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-lg-3">
                                            <v-text-field label="2nd and additional given names"
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-lg-3">
                                            <v-text-field label="Date of Birth" type="date"
                                                variant="outlined"></v-text-field>
                                        </div>
                                    </div>
                                    <p class="redress">Redress number and known traveller number
                                        <span>
                                            <v-icon @click="Redressnumber = !Redressnumber">mdi-menu-down</v-icon>
                                        </span>
                                    </p>

                                    <div class="row mt-4  gy-3">
                                        <div class="col-md-3">
                                            <v-text-field v-if="Redressnumber" label="Redress Number" v-model="Redress"
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-md-3">
                                            <v-text-field v-if="Redressnumber" label="Known Traveler Number"
                                                v-model="Traveler" variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-md-6" align="end">
                                            <v-btn variant="outlined" color="primary" @click="reset1()">
                                                Reset
                                            </v-btn>
                                        </div>

                                    </div>

                                </v-card>





                                <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                                    <h6>Advance Passenger Information System</h6>
                                    <h6>Primary Data of the Travel Document</h6>
                                    <div class="row mt-3  gy-3">
                                        <div class="col-lg-3">
                                            <v-text-field label="Last name as per passport"
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-lg-3">
                                            <v-text-field label="First name as per passport"
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-lg-3">
                                            <v-text-field label="2nd and additional given names    "
                                                variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-lg-2">
                                            <v-text-field label="Date Of Birth" type="date"
                                                variant="outlined"></v-text-field>
                                        </div>
                                    </div>


                                    <div class="row mt-4  gy-3">
                                        <div class="col-3-lg">
                                            <v-select label="Document Type" :items="['Aeroplan-Air canada', 'Flying Returns-Air india', 'Air Points-Air America',
                                                'Phonix-Air china']" variant="outlined"></v-select>
                                        </div>
                                        <div class="col-3-lg">
                                            <v-text-field label="Document Number" variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-3-lg">
                                            <v-select label="Issuance Country" rounted="5"
                                                :items="['Aeroplan-Air canada', 'Flying Returns-Air india', 'Air Points-Air America', 'Phonix-Air china']"
                                                variant="outlined"></v-select>
                                        </div>
                                        <div class="col-3-lg">
                                            <v-select label="Nationality" :items="['Aeroplan-Air canada', 'Flying Returns-Air india', 'Air Points-Air America',
                                                'Phonix-Air china']" variant="outlined"></v-select>
                                        </div>
                                    </div>

                                    <div class="row mt-2  gy-3">
                                        <div class="col-2-lg">
                                            <v-text-field label="Issue Date" type="date" variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-2-lg">
                                            <v-text-field label="Expiration" type="date" variant="outlined"></v-text-field>
                                        </div>
                                    </div>
                                    <p style="color: blue;cursor: pointer;" @click="apivalue = !apivalue">Passenger
                                        contact address (DOCA) <span>
                                            <v-icon>mdi-menu-down</v-icon>
                                        </span></p>
 
                                    <div class="row mt-2  gy-3" v-if="apivalue">
                                        <div class="col-md-2">
                                            <p>Type of address</p>
                                        </div>
                                        <div class="col-md-10">
                                            <v-radio-group inline color="primary" v-model="value">
                                                <v-radio label="Destination" value="1"></v-radio>
                                                <v-radio label="Residence" value="2"></v-radio>
                                            </v-radio-group>
                                        </div>

                                    </div>


                                    <div class="row mt-4  gy-3" v-if="apivalue">
                                        <div class="col-md-2">
                                            <v-text-field label="Address Details" variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-md-2">
                                            <v-text-field label="ZIP/Postal code" variant="outlined"></v-text-field>
                                        </div>
                                        <div class="col-md-3">
                                            <v-select label="Country"
                                                :items="['Aeroplan-Air canada', 'Flying Returns-Air india', 'Air Points-Air America', 'Phonix-Air china']"
                                                variant="outlined"></v-select>
                                        </div>
                                        <div class="col-md-3">
                                            <v-select label="State/province" :items="['Aeroplan-Air canada', 'Flying Returns-Air india', 'Air Points-Air America',
                                                'Phonix-Air china']" variant="outlined"></v-select>
                                        </div>
                                        <div class="col-md-2">
                                            <v-text-field label="City" variant="outlined"></v-text-field>
                                        </div>

                                    </div>
                                    <div class="col-12 mt-2" align="end">
                                        <v-btn variant="outlined" color="primary">
                                            Reset
                                        </v-btn>
                                    </div>

                                </v-card>

                            </div>
                        </div>


                        

                        <div class="mt-4" style="background-color:#f6f6f6 ;padding:10px;">
                            <h6>Passenger Contact Data</h6>
                        </div>


                        <div class="row mt-4  gy-3">
                            <div class="col-lg-6">
                                <div class="row" v-for="data of detail1" :key="data">
                                    <div class="col-md-5">
                                        <v-text-field label="Phone Number" :rules="userName" variant="outlined"
                                            v-model="data.number"></v-text-field>
                                    </div>
                                    <div class="col-md-5">
                                        <v-text-field label="Email ID" :rules="userName" variant="outlined"
                                            v-model="data.email"></v-text-field>
                                    </div>
                                    <div class="col-md-2">
                                        <v-icon v-if="detail1.length > 1" color="red" @click="delet()">
                                            mdi-close-circle
                                        </v-icon>
                                    </div>

                                </div>
                                <div class="col-12" align="end">
                                    <h6 @click="added()" style="cursor: pointer;color: blue;">+ Add Passenger</h6>
                                </div>


                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-md-5">
                                        <v-checkbox label="Contact me on whatsapp" color="success"></v-checkbox>
                                    </div>
                                    <div class="col-md-7">
                                        <v-checkbox label="Send me the latest travel deals, special offers, coupons."
                                            color="success"></v-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                    
                </v-form>
                </v-card>
            </div>

        </div>
    </section>
    <div>

    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    components: {
        VueDatePicker
    },
    data: () => ({
        valid:false,
        ffb: "",
        air: "Aeroplan-Air canada",
        Redress: "",
        Traveler: "",
        Redressnumber: true,
        result: "name",
        value: "1",
        clicked1: false,
        dateOfBirth: '',
        dateFormat: 'dd MMM yyyy',
        apivalue: true,
        dateData: [],
        yearData: [],

        adultData: [],
        childData:[],
        infrantData:[],
        adult:"",
            child:"",
            inf:"",

        detail1: [{
            number: "",
            email: ""
        }],
        userName: [
            v => !!v || 'This field is required',
        ],
    }),



    methods: {
       
        reset() {
            this.ffb = "",
                this.air = ""
        },
        reset1() {
            this.Redress = "",
                this.Traveler = ""
        },
        added() {
            if (this.detail1.length < 4) {
                this.detail1.push({
                    number: "", email: ""
                })
            }

        },
        delet(index) {

            this.detail1.splice(index, 1)
        },
        add() {
            for (let i = 0; i < this.adultData.length; i++) {
                if (this.adultData.length < 1) {
                    this.adultData.push({
                        fname: "",
                        last: "",
                        middle: "",
                        year: "",
                        date: "",
                        month: "",
                        genter: "",
                        salutation: "",
                        ffbnumber: false,
                    })
                }
            }
        },
        ffbdata(data, index) {
            console.log(index, 'index')
            console.log(data, '12345')
            this.adultData.map((item, i) => {
                if (i == index) {
                    item.ffbnumber = !item.ffbnumber
                }

                return item;
            })

        },
        tsadata(index) {

            this.adultData.map((item, i) => {
                if (i == index) {
                    item.TSAnumber = !item.TSAnumber
                }

                return item;
            })

        },
        apidata(index) {
            this.apivalue = true
            this.adultData.map((item, i) => {
                if (i == index) {
                    item.Apinumber = !item.Apinumber
                }

                return item;
            })

        },

        day() {

            for (let i = 1; i <= 31; i++) {

                this.dateData.push(i)
                // console.log(this.date, '9999999')
            }
        },
        year() {

            for (let i = 2023; i <= 2035; i++) {

                this.yearData.push(i)
                // console.log(this.yearData, '9999999')
            }
        },


        fromDateChanged(newValue) {
            this.dateOfBirth = newValue;
            console.log(this.dateOfBirth, 'dobdond.....')
            if (this.dateOfBirth) {
                this.clicked1 = true;
                this.fromDateError = false;
            }
            else {
                this.clicked1 = false;
                this.fromDateError = true;
                // this.$refs.dpRef1.openMenu();
            }
        },


        // ClickOutDepat() {
        //     this.$refs.dpRef1.closeMenu();

        //     if (this.oneway.dedate) {
        //         this.clicked1 = true;
        //     }
        //     else {
        //         this.clicked1 = false;
        //     }
        // },

        getData() {
            for (let i = 0; i <= this.adultData.length ; i++) {
                // console.log(this.adultData.length, 'ssss')
                if (this.adultData.length<this.adult) {
                    this.adultData.push(
                        {
                            fname: "",
                            last: "",
                            middle: "",
                            year: "",
                            date: "",
                            month: "",
                            genter: "",
                            salutation: "",
                            ffbnumber: false,
                            TSAnumber: false,
                            Apinumber: false,
                        }
                    )
                }
                else{
                    return this.adultData
                }
                // console.log(this.adultData.length, 'eeeeeeeee')
            }
        },

        getpass(){
            let localData=JSON.parse(localStorage.getItem("rountData"))
            this.adult=localData.adult
     this.child=localData.child
     this.inf=localData.infrant
        }


    },

    mounted() {
        let result1 = localStorage.getItem("value1")
        console.log(result1, '123245')

        console.log(this.dateOfBirth, 'dobdob.....')
    },
    created() {
        this.getpass()
        this.day()
        this.year()
        this.add()
        this.getData()
    }

}
</script>

<style scoped>
.ffb-button {
    color: #0748ac !important;
    border: 1px solid #0748ac !important;
    margin-left: 10px;
    height: 40px !important;


}

.ffb-button:hover {
    color: #fff !important;
    background: #0b446d !important;
    border: 1px solid #0b446d;
}

.ffb-button:focus {
    color: #fff !important;
    background: #0b446d !important;
    border: 1px solid #0b446d;
}

.ffb-button:active {
    color: #fff !important;
    background: #0b446d !important;
    border: 1px solid #0b446d;
}

.ffb-cart {
    box-shadow: none;
    background-color: #f6f6f6;
    padding: 10px 20px !important;
    margin-top: 10px;
}

.redress {
    cursor: pointer;
    color: #5191fa;
}

.redress:hover {
    cursor: pointer;
    color: #0b446d;
}

.v-input__control {
    height: 45px !important;
}

.v-text-field .v-field--active input {
    opacity: 1;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    top: -6px;
}

.v-field--center-affix .v-label.v-field-label {
    top: 39%;
    transform: translateY(-50%);
}

.v-selection-control .v-label {

    height: auto;

}




/* Datepicker Style Section */

.v-card {
    overflow: unset;
}

>>>.dp__input_icon {
    display: none;
}

.v-icon {
    width: 100%;
}

>>>.dp__input {
    height: 42px;
    border-color: darkgrey;
    border-radius: 4px !important;
    padding: 0px 2px;
}

.mdi-calendar {
    margin-right: 10px;
    width: auto;
    color: grey;
}

.calendar-container {
    display: flex;
    align-items: center;
    position: relative;
}

.label-top {
    position: absolute;
    top: 0px;
    left: -8px;
    transform: translateY(-100%);
    background-color: transparent;
    padding: 0 8px;
    font-size: 12px;
    color: gray;
    /* transition: all 0.3s ease-out; */
    pointer-events: none;
}

.datepicker-label {
    position: absolute;
    top: 24px;
    left: -8px;
    transform: translateY(-100%);
    background-color: transparent;
    padding: 0 8px;
    font-size: 1rem;
    color: gray;
    /* transition: all 0.3s ease-out; */
    pointer-events: none;
    z-index: 1;
}

.label-top2 {
    position: absolute;
    top: 0px;
    left: -8px;
    transform: translateY(-100%);
    background-color: transparent;
    padding: 0 8px;
    font-size: 12px;
    color: gray;
    /* transition: all 0.3s ease-out; */
    pointer-events: none;
}


.datepicker-label2 {
    position: absolute;
    top: 24px;
    left: -8px;
    transform: translateY(-100%);
    background-color: transparent;
    padding: 0 8px;
    font-size: 1rem;
    color: gray;
    /* transition: all 0.3s ease-out; */
    pointer-events: none;
    z-index: 1;
}

.multiLabel-top {
    position: absolute;
    right: 87px;
    transform: translateY(-100%);
    background-color: white;
    padding: 0 8px;
    font-size: 12px;
    color: #999;
    /* transition: all 0.3s ease-out; */
    pointer-events: none;
}


.multiDatepicker-label {
    position: absolute;
    right: 69px;
    background-color: transparent;
    padding: 0 8px;
    font-size: 1rem;
    color: #999;
    /* transition: all 0.3s ease-out; */
    pointer-events: none;
    z-index: 1;
}


.delete-icon {
    display: flex;
    justify-content: end;
    position: absolute;
    left: 12px;
    z-index: -1;
}


.p-float-label {
    width: 100%;
}

.p-float-label label {
    position: absolute;
    pointer-events: none;
    top: 14px;
    left: 0px;
    margin-top: -0.5rem;
    transition-property: all;
    transition-timing-function: ease;
    line-height: 1;
    color: gray;
}

/* Datepicker Style Section End */
</style>

   