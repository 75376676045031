<template>
  <footer style="background-color: rgb(16, 55, 105); color: white">
    <div style="padding: 20px 40px">
      <!-- <div class="container">


        <div class="row p-3 gy-3">
          <div class="col-md-3 col-12">
            <div class="footer-col-1" align="center">
              <v-img
                :src="portalLogo"
                style="height: 55px; filter: brightness(0) invert(1)"
              />
       
              <div class="mt-2">
                <a
                  v-if="footMenu.includes('fb')"
                  class="btn-link btn-floating btn-lg text-dark me-4 social-icons"
                  :href="getFacebook"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <v-icon color="white"> mdi-facebook </v-icon>
                </a>

            
                <a
                  v-if="footMenu.includes('insta')"
                  class="btn-link btn-floating btn-lg text-dark me-4 social-icons"
                  :href="getInstagram"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <v-icon color="white"> mdi-instagram </v-icon>
                </a>

                <a
                  v-if="footMenu.includes('twitter')"
                  class="btn-link btn-floating btn-lg text-dark me-4 social-icons"
                  href="#!"
                  role="button"
                  data-mdb-ripple-color="dark"
                >
                  <v-icon color="white"> mdi-twitter </v-icon>
                </a>
              </div>
            </div>
          </div>

          <div class="col-md-2 col-6" v-if="footMenu.includes('aboutus')">
            <h6 style="margin-top: 30px">
              <a
                href="/aboutUs"
                target="_blank"
                style="color: white; text-decoration: none"
                >About us</a
              >
            </h6>
          
          </div>
          <div class="col-md-2 col-6" v-if="footMenu.includes('contactus')">
            <h6 style="margin-top: 30px">
              <a
                href="/contactPage"
                target="_blank"
                style="color: white; text-decoration: none"
              >
                Contact us</a
              >
            </h6>
          
          </div>
          <div class="col-md-2 col-6" v-if="footMenu.includes('privacypolicy')">
            <h6 style="margin-top: 30px">
              <router-link
                to="/page/privacy-policy"
                target="_blank"
                style="color: white; text-decoration: none"
                >Privacy policy</router-link
              >
            </h6>
           
          </div>
          <div class="col-md-2 col-6" v-if="footMenu.includes('termsofuse')">
            <h6 style="margin-top: 30px">
              <router-link
                to="/page/terms-of-use"
                target="_blank"
                style="color: white; text-decoration: none"
              >
                Terms of use
              </router-link>
            </h6>
            
          </div>
        </div>






      </div> -->

      <div class="row g-0 footer-row">
        <div class="col-md-6">
          <div class="footer-col-1" align="left">
            <v-img
              :src="portalLogo"
              style="height: 55px;width:215px; filter: brightness(0) invert(1)"
            />
          </div>
          <div class="mt-4 pt-3 text-start">
            <div class="my-2">
              <a
                v-if="footMenu.includes('fb')"
                class="btn-link btn-floating btn-lg text-dark me-4 social-icons"
                :href="getFacebook"
                role="button"
                data-mdb-ripple-color="dark"
              >
                <v-icon color="white"> mdi-facebook </v-icon>
              </a>

              <a
                v-if="footMenu.includes('insta')"
                class="btn-link btn-floating btn-lg text-dark me-4 social-icons"
                :href="getInstagram"
                role="button"
                data-mdb-ripple-color="dark"
              >
                <v-icon color="white"> mdi-instagram </v-icon>
              </a>

              <a
                v-if="footMenu.includes('twitter')"
                class="btn-link btn-floating btn-lg text-dark me-4 social-icons"
                href="#!"
                role="button"
                data-mdb-ripple-color="dark"
              >
                <v-icon color="white"> mdi-twitter </v-icon>
              </a>
            </div>
            <div>
              <v-icon color="white">mdi-email</v-icon>
              <span class="ms-2" style="font-size: 15px">{{
                emailPortal
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-2 d-none">
          <!-- <div class="f-head">Products and services</div>
          <br />
          <div class="f-side">Cancellation protection</div> -->
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <div>
            <ul style="list-style: none; line-height: 40px">
              <li>
                <div class="f-head">{{ $t("footerContents.company") }}</div>
              </li>
              <!-- <li class="f-side">
                <a href="/aboutUs" target="_blank" style="color: white; text-decoration: none">{{ $t("footerContents.aboutUs") }}</a>
              </li> -->
              <li class="f-side">
                <a :href="getLocalizedUrl('/aboutUs')" target="_blank" style="color: white; text-decoration: none">{{ $t("footerContents.aboutUs") }}</a>
              </li>
              <li class="f-side">
                <router-link
                  to="/page/terms-of-use"
                  target="_blank"
                  style="color: white; text-decoration: none"
                >
                  {{ $t("footerContents.termsofUse") }}
                </router-link>
              </li>
              <li class="f-side">
                <router-link to="/page/privacy-policy" target="_blank" style="color: white; text-decoration: none">
                  {{ $t("footerContents.privacyPolicy") }}</router-link>
              </li>
              <!-- <li class="f-side">Information on cookies</li> -->
            </ul>
          </div>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <ul style="list-style: none; line-height: 40px">
            <li>
              <div class="f-head">{{ $t("footerContents.support") }}</div>
            </li>
            <li class="f-side">
              <a href="/contactPage" target="_blank" style="color: white; text-decoration: none">
                {{ $t("footerContents.contactUs") }}</a>
            </li>
            <li class="f-side" v-if="header.includes('mybookings')">
              <a href="/mybooking" target="_blank" style="color: white; text-decoration: none">
                {{ $t("footerContents.myBookings") }}</a>
            </li>
            <!-- <li class="f-side">My Bookings</li> -->
          </ul>
        </div>
      </div>

      <div
        class="mx-lg-5 mx-3"
        style="
          display: flex;
          justify-content: center;
          /* background-color: lightgray; */
          padding: 11px 1px 0px 3px;
        "
      >
        <div
          class="row d-flex justify-content-center"
          style="
            border-radius: 5px;
            background-color: lightgray;
            padding: 11px 1px 0px 3px;
          "
        >
          <div class="col-sm-1 f-img">
            <v-img :src="require('@/assets/visa.png')"></v-img>
          </div>

          <div class="col-sm-1 f-img mt-1">
            <v-img :src="require('@/assets/mascard.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img">
            <v-img :src="require('@/assets/american.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img">
            <v-img
              :src="require('@/assets/jcb.png')"
              style="height: 54px"
            ></v-img>
          </div>
          <div
            class="col-sm-1 f-img"
            style="
              width: 120px;

              position: relative;
              top: 10px;
            "
          >
            <v-img :src="require('@/assets/dinners.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img">
            <v-img :src="require('@/assets/discover.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img" style="width: 100px">
            <v-img :src="require('@/assets/aff.png')"></v-img>
          </div>
          <div class="col-sm-1 f-img mt-1">
            <v-img :src="require('@/assets/gpay.png')"></v-img>
          </div>
        </div>
      </div>

      <div class="container mt-2">
        <div class="d-md-flex d-block justify-content-between">
          <div>
            <p class="m-0" style="font-size: 12px">
              {{ addressLine1 }} <br />
              {{ $t("footerContents.customerNumber") }}: {{ phonePortal }} | {{ $t("footerContents.email") }}:
              contact@buymytrip.com
            </p>
          </div>
          <div style="display: flex; justify-content: center">
            <p class="text-md-end text-start mt-md-0 mt-3" style="font-size: 14px">
              {{ $t("footerContents.copyright") }}©{{ year }}, {{ legalName }}. {{ $t("footerContents.allrights") }}.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </footer>
</template>

<script>
// import axios from "axios";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  data() {
    return {
      portalLogo: "",
      getInstagram: "",
      getFacebook: "",

      footerMenus: [],
      footMenu: "",
      header:[],
      year: "",
      phonePortal: "",
      emailPortal: "",
      addressLine1:"",
      addressLine2:"",
      legalName:"",
    };
  },

  methods: {

    getLocalizedUrl(path){
      return `${path}?lang=${this.$i18n.locale}`;
    },
    
    getConfig() {
      // let getConfigData = this.$store.state.data

      const getConfigData = getConfigDataFromLocalStorage();

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      if (getConfigData) {
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        this.getInstagram =
          getConfigData.payload.portal_configuration.social_media_links.instagram;
        this.getFacebook =
          getConfigData.payload.portal_configuration.social_media_links.facebook;

        this.phonePortal = getConfigData.payload.portal_configuration.phone;
        this.emailPortal = getConfigData.payload.portal_configuration.email;

        //mybooking
        this.header = getConfigData.payload.portal_configuration.menus.enabled.header.split(',')
        // console.log(this.header,' headerrrrr')
        // let footMenu=""
        this.footMenu =
          getConfigData.payload.portal_configuration.menus.enabled.footer;
          this.addressLine1 = getConfigData.payload.portal_configuration.portal_footer_address1
          this.addressLine2 = getConfigData.payload.portal_configuration.portal_footer_address2
          this.legalName = getConfigData.payload.portal_configuration.portal_legal_name
        // this.footerMenus = footMenu.split(',')

        // console.log(this.footMenu, 'fmfmfmfmfm...')
      }
    },
  },

  // watch: {
  //       "$i18n.locale": {
  //           handler: function () {
  //               this.getLocalizedUrl
  //           },
  //           deep: true,
  //       },
  //   },


  created() {
    this.getConfig();
    const d = new Date();
    this.year = d.getFullYear();
  },
};
</script>

<style scoped>
/* footer */
.social-icons {
  color: var(--primary-color) !important;
}

.fs-14 {
  font-size: 14px;
  list-style-type: none;
}

.fs-14 li {
  padding: 6px;
}

section {
  padding: 10px 5px;
}

.slide-card {
  border-radius: 10%;
}

.card-test {
  position: relative;
  padding: 10px 15px;
  border-radius: 8%;
  max-height: 500px;
}

.card-img {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50%;
  right: 0;
  transform: translateY(-50%);
}

.radio {
  background-color: #e2e7ff;
  border-radius: 14px;
  color: #007bff;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400px;
  height: 30px;
  width: 130px;
}

.cart-adult {
  padding: 2px 15px 15px 15px;
  width: 450px;
  cursor: pointer;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.p-datepicker-group-container {
  background-color: #ffffff !important;
  width: 200px;
  padding: 5px 3px;
  border: 1px solid lightgray;
  position: relative;
  right: 14px;
}

.calendar-container {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-right: 10px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 500px;
}

@media only screen and (max-width: 992px) {
  .car-right {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .session-bg {
    background-image: none;
  }

  .back {
    background-image: none;
  }

  .flight-right {
    width: 80% !important;
  }

  .bannar-bg {
    background: none;
  }

  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}

.mdi-airplane-takeoff::before {
  content: "\F05D5";
  margin-top: 10px;
}

.mdi-airplane-landing::before {
  content: "\F05D4";
  margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
    padding-right: 15px;
  }

}

@media only screen and (max-width: 768px) {

  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
  }
}
.f-head {
  font-weight: 700;
  font-size: 16px;
}
.f-side {
  /* text-decoration: underline; */
  font-size: 15px;
}
.f-img {
  height: 50px;
  width: 65px;
  /* filter: contrast(0.5); */
}
.footer-row {
  padding: 0px 50px;
}
@media screen and (max-width: 430px) {
  .footer-row {
    padding: 0px 0px !important;
  }
}
</style>
