<!-- <template>
    <div class="flex justify-content-center mt-4">
      <div class="calendar-container">
        <label for="calendar-input" class="calendar-label">Departing</label>
        <span class="icon-container">
          <v-icon>mdi-calendar</v-icon>
        </span>
        <span class="calendar-wrapper">
          <Calendar v-model="date" :dateFormat="dateFormat" :id="inputId" :appendTo="appendToConfig" />
        </span>
      </div>
    </div>
  </template>


<script >
import { ref } from "vue";
import Calendar from 'primevue/calendar';

const date = ref(null);
const dateFormat = "dd/mm/yy";
const inputId = "calendar-input";
const appendToConfig = {
  target: "body",
  handler: "click"
};

export default {
  components: {
    Calendar
  },
  setup() {
    return {
      date,
      dateFormat,
      inputId,
      appendToConfig
    };
  }
}
</script>
  
  <style scoped>
  .calendar-container {
    display: flex;
    align-items: center;
  }
  
  .icon-container {
    margin-right: 10px;
  }
  
  .calendar-wrapper {
    display: inline-block;
    border-bottom: 1px solid lightgray;
  }
  
  .calendar-label {
    display: block;
    margin-bottom: 5px;
  }
  </style> -->
  
 

 

<!-- 
  <template>
    <div class="m-auto mt-5" style="max-width: 400px;">
      <v-autocomplete
        label="Select"
        :items="countries"
        v-model="selectedCountry"
        item-value="selectedCountry"
        item-title="countryName"
        variant="outlined">
  </v-autocomplete>

  
      <h3 v-if="selectedCountry">Country Code: {{ selectedCountry}}</h3>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        countries: [
          { countryName: "Afghanistan", code: 93 },
          { countryName: "Aland Islands", code: 358 },
          { countryName: "Albania", code: 355 },
          { countryName: "Algeria", code: 213 },

        ],
        selectedCountry: null,
      };
    },
    methods: {
      getCountryCode(country) {
        console.log(this.countries,"ffffffffff")
        const selectedCountry = this.countries.find((c) => c.code === country);
        console.log(this.selectedCountry,"ssssssssssss")
        return selectedCountry ? selectedCountry.code : null;
      },
    },
  };
  </script> -->


<!-- <template>
  <div>
    <v-autocomplete v-model="select" :loading="loading" :items="items" item-title="places" item-value="icao" dense
      hide-no-data hide-details @input="querySelections"></v-autocomplete>
  </div>
</template>

<script>
import userData from '@/airport_icao.json';

export default {
  data() {
    return {
      select: null,
      loading: false,
      fromAirports: [],
      items: [],
    };
  },
  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      Object.keys(userData).forEach(icao => {
        const airport = userData[icao];
        if (airport.iata == "") {
          console.log(airport.iata)
        }
        else {
          this.fromAirports.push({
            icao: airport.icao,
            places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
          });
        }

      });
    },

    querySelections(v) {
      this.loading = true;
      setTimeout(() => {
        const searchString = typeof v === 'string' ? v.toLowerCase() : '';

        this.items = this.fromAirports.filter(e => {
          return (
            e.places.toUpperCase().toLowerCase().indexOf(searchString) > -1
          );
        });
        this.loading = false;
      }, 500);
    },
  },

};
</script> -->



<!-- <template>
  <v-toolbar color="teal">
    <v-toolbar-title>State selection</v-toolbar-title>

    <v-autocomplete
      v-model="select"
      v-model:search="search"
      :loading="loading"
      :items="items"
      class="mx-4"
      density="comfortable"
      hide-no-data
      hide-details
      label="What state are you from?"
      style="max-width: 800px;"
    ></v-autocomplete>
    

    <v-btn icon="mdi-dots-vertical"></v-btn>
  </v-toolbar>
</template>



<script>
  export default {
    data () {
      return {
        loading: false,
        items: [],
        search: null,
        select: null,
        states: [
          'Alabama',
          'Alaska',
          'American Samoa fgsdfgdfs dfgsdfgdsfg fdgdfggs dfgsdgfg sdfgsddgds',
          'Arizona',
          'Arkansas',
          'California',
          'Colorado',
          'Connecticut',
          'Delaware',
          'District of Columbia',
          'Federated States of Micronesia',
          'Florida',
          'Georgia',
          'Guam',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
    
        ],
      }
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
    },
    methods: {
      querySelections (v) {
        this.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          this.loading = false
        }, 500)
      },
    },
  }
</script> -->





<!-- 
<template>
  <v-col cols="2">
    <v-autocomplete
      v-model="selectedItem"
      :items="formattedData"
      item-title="selectedItem?'paceAndDis':'paceAndDis1'"
      item-value="dis"
      label="Autocomplete"
    ></v-autocomplete>
  </v-col>
  <div>Selected Item (dis): {{ selectedItem }}</div>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: null,
      data: [
        {
          pace: "hkattu",
          dis: "thgttttttttttttttttttttttttttttta",
          age: 23
        },
        {
          pace: "hkattu",
          dis: "dddddddddddgggggggggggggggggggggggggggggggggggggggggggggg",
          age: 23
        },
        {
          pace: "hkattu",
          dis: "ttttttuuuuuuuuuuuuuuuuuuuuuuuuuuuuuut",
          age: 23
        }
      ]
    };
  },
  computed: {
    formattedData() {
      return this.data.map(item => ({
        ...item,
        paceAndDis: `${item.pace} - ${item.dis}`,
        paceAndDis1: `${item.pace} - ${item.dis},- ${item.age}`
      }));
    }
  }
};
</script> -->



<template>
  <v-col cols="2">
    <v-autocomplete
      v-model="selectedItem"
      :items="formattedData"
      :item-title="selectedItem ? 'paceAndDis1': 'paceAndDis'"
      item-value="dis"
      
    ></v-autocomplete>
  </v-col>
  <div>Selected Item (dis): {{ selectedItem }}</div>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: null,
      data: [
        {
          pace: "hkattu",
          dis: "thg",
          age: 23
        },
        {
          pace: "hkattu",
          dis: "ddddddd",
          age: 23
        },
        {
          pace: "hkattu",
          dis: "ttt",
          age: 23
        }
      ]
    };
  },
  computed: {
    formattedData() {
      return this.data.map(item => ({
        ...item,
        paceAndDis: `${item.pace} - ${item.dis}`,
        paceAndDis1: `${item.pace} - ${item.dis} - ${item.age}`
      }));
    }
  }
};
</script>




  

  

  
  