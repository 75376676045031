import axios from "axios";

class MulticityService {
  constructor() {
    this.user="http://localhost:4000/AirShoppingRS";
  }

   getAllUser(){
    return axios.get(this.user);
   }
   createUser(data){
    return axios.post(this.user,data);
   }
}
export default new MulticityService();