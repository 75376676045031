<template>
    <!-- Footer -->

    <footer class="footer-distributed">

        <div class="footer-left">

            <div class="col-12 col-md-10 ">
                <div class="row ">
                    <div class="col-12 footer-img">
                        <v-img :src="require('@/assets/buymytrip.png')" height="50px" class="bg-light" max-width="60%" />
                    </div>
                    <div class="col-12">
                        <p class="footer-links">
                            <a href="#" class="link-1">Home</a>
                            <a href="#">About</a>
                            <a href="#">Destination</a>
                            <a href="#">Contacts</a>
                        </p>
                    </div>
                </div>
            </div>
            <!-- <p class="footer-company-name">buymytrp © 2023</p> -->
        </div>

        <div class="footer-center text-white">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <!--Column1-->
                    <div class="footer-pad">
                        <h4>Company</h4>
                        <ul class="list-unstyled">
                            <li>About us</li>
                            <li>Destination</li>
                            <li>Packages</li>
                            <li>Contact us</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <!--Column1-->
                    <div class="footer-pad">
                        <h4>Help</h4>
                        <ul class="list-unstyled">
                            <li>Help/Faqs</li>
                            <li>Cancel Your Flight</li>
                            <li>Press</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-right">
            <h4 class="text-light">About</h4>
            <p class="footer-company-about">
                Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus
                vehicula sit amet.Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit,
                eu auctor lacus
                vehicula sit amet.
            </p><span class="read-more"><a href="">Read More...</a></span>

            <div class="footer-icons">
                <a href="#"><v-icon>mdi-facebook</v-icon></a>
                <a href="#"><v-icon>mdi-instagram</v-icon></a>
                <a href="#"><v-icon>mdi-twitter</v-icon></a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 copy">
                <p class="text-center text-light">&copy; Copyright 2023 - <a href="">buymytrip</a></p>
            </div>
        </div>

    </footer>

    <!-- Footer End -->
</template>


<style scoped>
/* Footer Start */


/* * {
    font-family: Open Sans;
} */

section {
    width: 100%;
    display: inline-block;
    background: #333;
    height: 50vh;
    text-align: center;
    font-size: 22px;
    /* font-weight: 700; */
    text-decoration: underline;
}

footer{
    margin-top: 10px;
}
.footer-distributed {
    background: #666;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    /* font: bold 16px sans-serif; */
    padding: 50px 50px 20px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
    width: 30%;
}



/* Footer links */

.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
    padding-left: 6px;
    font-size: 18px;
}

/* Footer Center */

.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
    font-size: 17px;
    line-height: 38px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 2;
}

.footer-distributed .footer-center p a {
    color: lightseagreen;
    text-decoration: none;
    ;
}

.footer-distributed .footer-links a:before {
    content: "|";
    font-weight: 300;
    font-size: 20px;
    left: 0;
    color: #fff;
    display: inline-block;
    padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
    width: 35%;
    padding: 0px 20px 10px 20px;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: lightgray;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-icons {
    margin-top: 20px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 45px;
    height: 38px;
    cursor: pointer;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 10px;
}

.read-more {
    color: white;
    padding: 5px 5px 5px 0px;

}

.read-more a {
    color: white;
}


.copy {
    font-size: 16px;
    padding: 3px;
    border-top: 1px solid #FFFFFF;
}


/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

    .footer-distributed {
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }

    .footer-distributed .footer-center i {
        margin-left: 0;
    }

    .footer-img {
        display: flex;
        justify-content: center;
    }

}
</style>