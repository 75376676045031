export default {
    headerContents: {
        helpSupport: "Help & Support",
        chatSupport: "Chat Support",
        requestForm: "Request Form",
        account: "Account",
        login: "Login",
        register: "Register",
        myBookings: "My Bookings",
        viewBooking: "View Booking",
        myProfile: "My Profile",
        logOut: "Log Out",
    },
    homePageContent: {
        welcomeMsg: {
            text1: "Discover Great Flight Deals!",
            text2: "Save up to 60% on your flight bookings to any destination",
        },
        hightlightContent: {
            heading1: "Best Price Promise",
            subHead1: "Get best fares to destinations worldwide.",
            heading2: "Get Great Deals!",
            subHead2: "Best fares in 500+ airlines for low airfares!",
            heading3: "Expert Guidance",
            subHead3: "Get personalized assistance from our travel experts.",
        },
        contentSection: {
            heading1: "Top Destinations",
            heading2: "Flight Deals",
            heading3: "What Types Of Travel Do We Specialize In?",
            heading4: "Our Airline Partners",
            heading5: "Why Book with Us?",
            subcribe: "Subscribe To Our Newsletter & Get exciting offers",
            subcribeText: "Keep yourself updates with latest travels news and ongoing promotions. We promise no spamming and we won't share your details with any 3rd party.",
            sendWhatsapp: "Send me on Whatsapp",
            subcribeAgree: "By subscribing, I agree to receive promotional messages and updates from ByMyTrip.",
            bookNow: "BOOK NOW",
            economy: "Economy",
            premiumeconomy: "Premium Economy",
            business: "Business",
            firstclass: "First Class",
        },
        whybookContent: {
            heading1: "Easy Booking",
            content1: "Book your stress-free trip with us! Enjoy attractive offers, and a few clicks to your next adventure.",
            heading2: "Affordable Pricing",
            content2: "Fly affordably without compromising on quality with our great flight deals!",
            heading3: "Exciting Deals",
            content3: "Discover exclusive deals and discounts on flights to top destinations around the world.",
            heading4: "Best Services",
            content4: "Our team is always available to assist you, ensuring a smooth, hassle-free travel experience.",
        }
    },
    formsContents: {
        modifyContent: {
            heading: "FIND CHEAP TICKETS-SAVE BIG",
        },
        labels: {
            flyFrom: "Flying from",
            subDep: "Where do you want to fly from?",
            flyTo: "Flying to",
            subArr: "Where do you want to fly to?",
            travelDate: "Travel Date",
            fromData: "From",
            toData: "To",
            departure: "Departure",
            return: "Return",
            arrival: "Arrival",
            flight: "Flight",
            email: "Email",
            phoneNumber: "Phone Number",
            recentSearch: "Recent searches",
        },
        options: {
            oneWay: "One-way",
            return: "Return",
            multiCity: "Multi-city",
            adult: "Adult",
            child: "Child",
            infants: "Lap Infants",
            classType: "Class Type",
            passenger: "Passenger",
            passengers: "Passengers",
            travellers: "Traveller(s)",
            cabin: "Cabin",
            economy: "Economy",
            premiumEconomy: "Premium Economy",
            business: "Business",
            firstClass: "First Class",
            done: "Done",
            addCity: "Add City",
            clearAll: "Clear All",
            search: "Search",
        },
    },
    loaderPageContent: {
        loading: "CARGANDO",
        loadMsg1: "Find our lowest price to destinations worldwide guranteed.",
        loadMsg2: "Search select and save. the fastest way to book your trip.",
        loadMsg3: "Get award winning support and special deals.",
    },
    geoipContent: {
        text1: "You have reached to the",
        text2: "Site of",
        text3: "Continue to the",
        text4: "Site at",
    },
    searchPageContent: {
        filterContent: {
            filterSearch: "FILTER YOUR SEARCH",
            filterBy: "Filter By",
            stops: "Stops",
            fareType: "Fare Type",
            priceRange: "Price Range",
            avgperPax: "Avg.per pax",
            airlines: "Airlines",
            selectAll: "Select All",
            clearAll: "Clear All",
            more: "More",
            showless: "Show less",
            onwardjourney: "Onward Journey",
            returnjourney: "Return Journey",
            depFrom: "Departure From",
            arrivalAt: "Arrival at",
            resetFilter: "Reset Filters",
            recommended: "Recommended",
            best: "Best",
            cheapest: "Cheapest",
            shortest: "Shortest",
            after: "After",
        },
        itinContent: {
            depature: "Depature",
            return: "Return",
            nonRefund: "Non Refundable",
            refund: "Refundable",
            direct: "Direct",
            stop: "Stop",
            stops: "Stops",
            nonStop: "Non Stop",
            flightDetails: "Flight Details",
            select: "Select",
            modify: "Modify",
        },
        flightDetails: {
            cabin: "Cabin",
            overView: "OVERVIEW",
            baggage: "BAGGAGE",
            fareDetils: "FARE DETAILS",
            depature: "Depature",
            arrival: "Arrival",
            layoverTime: "LayoverTime",
            layover: "LayoverTime",
            bookFlight: "Book This Flight",
            totaltripDuration: "Total Trip Duration",
            checkedBag: "Checked Baggage",
            carryOnBag: "CarryOn Baggage",
            bagContent1: "Up to 62 linear inches/158 linear centimeters",
            bagContent2: "Up to 50 pounds/23 kilograms",
            piece: "Piece",
            pieces: "Pieces"
        },
    },
    fareDetContent: {
        fareDetails: "Fare Details",
        paymentDetails: "Payment Details",
        passenger: "Passenger",
        passengers: "Passengers",
        adult: "Adult",
        child: "Child",
        infant: "Infant",
        checkIn: "CHECK-IN",
        totalPrice: "Total Price",
        grandTotal: "Grand Total",
        fareSummary: "Fare Summary",
        basePrice: "Base Price",
        taxesFees: "Taxes & Fees",
        ticperPerson: "Ticket Price per person",
        priceInfo1: "All fares are quoted in",
        priceInfo2: "Some airlines may charge baggage fees. Your credit/debit card may be billed in multiple charges totaling the final total price.",
        confirmbtnText: "By clicking 'Confirm & Book', I agree that I have read and accepted the.",
        termsofUse: "Terms of use, Fare rules",
        perPerson: "Per Person"
    },
    baggageDetContent: {
        contents: {
            baggAndRules: "Baggage and Cancellation Rules",
            baggage: "Baggage Rules",
            cancelRules: "Cancellation Rules",
            carryOn: "Carry-On",
            checked: "Checked Bag",
            piece: "Piece",
            pieces: "Pieces"
        }
    },
    flightDetContent: {
        contents: {
            flightDetails: "Flight Details",
            backSearch: "Back To Search",
            depart: "DEPART",
            return: "RETURN",
            changePlanes: "Change planes at",
            connectTime: "Connecting Time",
            connectFlight: "Connecting flight may depart from a different terminal",
            continue: "Continue",
        },
    },
    flightInfoContent: {
        headings: {
            heading1: "Itinerary Information",
            heading2: "Traveller Details",
            subHead1: "Passenger Contact Data",
        },
        contents: {
            travellerDetails: "Traveller Details",
            adult: "Adult",
            child: "Child",
            infants: "Infants",
            contactInfo: "Contact information",
            contactWhats: "Contact me on whatsapp",
            sendCoupon: "Send me the latest travel deals, special offers, coupons.",
            tripDuration: "Trip Duration",
            stops: "Stops",
            stop: "Stop",
            nonStop: "Non-Stop",
            mustContinue: "You must agree to continue!",
            acknowledgement: "Acknowledgement",
            privacyPolicy: "Privacy Policy",
            termsofUse: "Terms of use, Fare rules",
            termsCondition: "Terms & Conditions, Fare rules",
            infoText: "By selecting to continue I acknowledge that I have read and accept the Rules & Restrictions, Terms of use, Fare rules and Privacy Policy. In addition, I also confirm that I have verified that all information entered during the reservation process is accurate. I also acknowledge that this will serve as my electronic signature.",
            continue: "Continue"
        },
        labels: {
            firstName: "First Name",
            middleName: "Middle Name",
            lastName: "Last Name",
            salutation: "Salutation",
            gender: "Gender",
            male: "Male",
            female: "Female",
            dateofBirth: "Date of Birth",
            year: "Year",
            month: "Month",
            date: "Date",
            countryCode: "Country Code",
            phoneNumber: "Phone Number",
            emailId: "Email ID",
            mr: "Mr",
            miss: "Miss",
            mrs: "Mrs",
            mstr: "Mstr",
        },
    },
    paymentContent: {
        headingContent: {
            tripDetails: "Trip Details",
            paymentDetails: "Payment Details",
            billingHeading: "Billing Details",
            cardText: "We accept all major Credit Card",
            paymentHeading: "Choose Payment Mode",
            bookingDetHeading: "Billing Details",
            paymentSub: "Your Payments are secured by SSL certificate",
        },
        labelsContent: {
            fullName: "Full Name",
            country: "Country",
            stateLabel: "Region/State/Province",
            region: "Region",
            state: "State",
            Province: "Province",
            city: "City",
            addressLine: "Address Line",
            postelCode: "Postel Code",
            countryCode: "Country Code",
            phoneNumber: "Phone Number",
            email: "Email",
            altContact: "Alternate Contact",
            phone: "Phone",
            cardNumber: "Card Number",
            NameonCard: "Name on Card",
            expiry: "Expiry",
            cvvCode: "CVV",
            cvvInfo: "3 digits printed on the back of the card",
            continueTobook: "Continue To Book"
        },
        fareUpgrade: {
            fareUpgradeHeading: "Upgrade Your Ticket",
            continueBasic: "Continue With Basic",
            perPassenger: "Per Pessenger(each-way)",
            upgradeNow: "Upgrade Now",
            congratsText: "Congratulations!",
            fareContent1: "You have saved upto 35% on baggage charges.",
            fareContent2: "Fare upgrade option selection for the following passengers.",
            fareHead: "Fare Upgrade",
            totalAmount: "Total Amount"
        },
        paymentMode: {
            heading: "Pay with Credit Card",
            subHead: "Credit Card",
            payNow: "Pay Now",
            payNowInfo: "On Clicking 'Pay Now' your card will be used for confirming the booking",
        },
    },
    bookingDetContent: {
        traveller: "Traveller",
        contactDetails: "Contact Details",
    },
    bookingviewContent: {
        statusText: "Your Booking Has Been",
        subText1: "Thank you for choosing our service! Your booking has been received and the reservation is processing.",
        subText2: "Soon your tickets will be issued and sent to your email.",
        status1: "Requested",
        status2: "Duplicate",
        status3: "Booked",
        status4: "Ticketed",
        status5: "Hold",
        status6: "Cancelled",
        headings: {
            bookingID: "Booking ID",
            bookedOn: "Booked on",
            depature: "Depature",
            return: "Return",
            arrival: "Arrival",
            flightdetails: "Flight details",
            tripID: "Trip ID",
            bookingDate: "Booking Date",
            bookingDetails: "Booking Details",
            eticNumber: "E-ticket number:",
            manageTrip: "Manage Trip",
            travellerDetails: "Traveller Details",
            contactDetails: "Contact Details",
            travelDetails: "Travel Details",
            flightTime: "Flight Time",
            flightNumber: "Flight number",
            stops: "Stop",
            class: "Class",
            passengerDetails: "Passenger Details",
            subtext1: "The e-ticket number is valid for all flights",
            termsCondition: "TERMS & CONDITIONS, FARE RULES",
            viewTicket: "View Ticket",
            roundTrip: "ROUND TRIP",
            onewayTrip: "ONEWAY TRIP",
            multiTrip: "MULTICITY",
        },
        buttonsText: {
            cancelBook: "Cancel Booking",
            modifyBook: "Modify Booking",
            print: "Print",
            startLive: "Start live chat",
            bookanotherFlight: "Book Another Flight",
            modify: "Modify",
            Cancel: "Cancel"
        },
        manageTripContent: {
            heading: "For more information, contact us",
            text1: "How much it costs",
            text2: "How to change a ticket",
            recommended: "Recommended",
            reachoutEmail: "Reach out to us by email at",
            chat: "Chat",
            mustAgree: "You must agree to continue!",
            checkBox: "By using this chat I allow my data to be stored for security and quality review as mentioned in the Privacy Policy.",
            emailInfo: "We will get back to you as soon as possible.Feel free to contact us for any Queries or Support.",
            chatNotes: "Please note that a support ticket will only be created for inquiries submitted through channels listed on this page. We will not respond to messages received outside of these options, including but not limited to those sent via regular mail and sent directly to employees.",
            content1: "If changes are allowed by the airline, the following charges apply",
            content2: "Airline's penalty when included in the ticket's fare rules",
            content3: " Our service fee of 56 CAD per person (not applicable for Platinum Support Package)",
            content4: "Any difference in fare and taxes from the original trip"
        },
    },
    loginContents: {
        signIn: "Sign in",
        signUp: "Sign Up",
        subHaed: "Sign in and Get lots of Cashback Rewards and Discount",
        labels: {
            Email: "",
            emailAddress: "Email Address",
            password: "Password",
            rememberMe: "Remember Me",
            createAnaccount: "Create an Account",
            resetPassword: "Reset your Password",
            rememberPass: "Remember your password?",
            emailnotfound: "Email Not Found",
            emailSentMsg: "You will receive a link to reset the password, if your email is already registered. Please follow the instructions in the email to reset your password."
        },
        buttonsLink: {
            login: "Login",
            or: "OR",
            createAccount: "Create New Account",
            forgotPassword: "Forgot Password",
            submit: "Submit"
        },
        successNotify: {
            text1: "Login Successfully",
        },
        errorNotify: {
            text1: "User Not Found",
            text2: "Please Enter Correct Details",
            errorMsg: "Incorrect Email or Password."
        },
        validError: {
            email: "Please enter your email",
            email1: "E-mail must be valid",
            password: "Please enter your Password",
        },
        placeholder: {
            email: "Enter your Email",
            password: "Enter your Password"
        }
    },
    registerContents: {
        signIn: "Sign in",
        signUp: "Sign Up",
        create: "Create",
        account: "Account",
        subHaed: "For security, please sign in to access your information",
        labels: {
            name: "Name",
            emailId: "Email ID",
            password: "Password",
            confirmPassword: "Confirm Password",
            rePassword: "Re-Password",
            byClick: "By clicking on register, I Understand & agree to world travel",
            YouHaveaccount: "You have already an account?",
        },
        buttonsLink: {
            register: "Register",
            login: "Login",
            submit: "Submit",
            alreadyAccount: "You have already an account?",
            signIn: "Sign In"
        },
        successNotify: {
            text1: "Login Successfully",
            text2: "Register Successfully"
        },
        errorNotify: {
            text1: "User Not Found",
            text2: "Please Enter Correct Details",
        },
        validError: {
            userName: "Please enter your username",
            email: "Please enter your email",
            email1: "E-mail must be valid",
            email2: "E-mail is required",
            password: "Please enter your Password",
            passNotmatch: "Passwords do not match"
        },
        placeholder: {
            name: "Enter your Name",
            email: "johndoe@gmail.com",
            password: "Enter your password",
            rePassword: "Enter your Re-password",
        }
    },
    loaderContent: {
        confirmText: "Please wait while we confirm your booking",
        retrieveText: "Please wait while we retrieve your booking",
    },
    myBookingContent: {
        heading: "VIEW YOUR BOOKING",
        labels: {
            bookId: "Booking ID",
            lastName: "Last Name",
        },
        buttons: {
            submit: "Submit",
            or: "OR",
            returnHome: "Return to Home",
            print: "Print"
        },
    },
    viewBooking: {
        header: {
            heading1: "UPCOMMING TRIP",
            heading2: "COMPLETED TRIP",
            heading3: "CANCELLED TRIP",
        },
        lebels: {
            bookingId: "Booking ID",
            travelDate: "Travel Date",
            bookingDate: "Booking Date",
        },
        contents: {
            emptyText1: "Looks empty, you've no upcoming bookings.",
            emptyText2: "When you book a trip, you will see your itinerary here.",
        },
        buttons: {
            myBookings: "My Bookings",
            viewBooking: "VIEW BOOKING",
            logOut: "LOG OUT",
            bookNow: "Book Now"
        }
    },
    myProfile: {
        headings: {
            profile: "Profile",
            loginDetails: "Login Details",
            personalProfile: "PERSONAL PROFILE",
            myBookings: "My Bookings",
            subHead1: "Basic info, for a faster booking experience",
            subHead2: "Manage your email address mobile number and password",
        },
        contents: {
            ok: "OK",
            successText: "Profile Has Updated",
        },
        labels: {
            firstName: "FIRST NAME",
            middleName: "MIDDLE NAME",
            lastName: "LAST NAME",
            birthDay: "BIRTHDAY",
            gender: "GENDER",
            male: "MALE",
            female: "FEMALE",
            others: "OTHERS",
            userId: "USER ID",
            emailId: "EMAIL ID",
            selectDate: "Select Date"
        },
        validError: {
            firstName: "Please enter First Name",
            lastName: "Please enter Last Name",
        },
        buttons: {
            update: "UPDATE",
            edit: "EDIT",
        }
    },
    contactContent: {
        heading1: "We are happy to deal with all enquiries and requests",
        subHead1: "Let Us know What You need, and we'll respond as soon as possible",
        formLabels: {
            name: "Name",
            natureEnquiry: "Nature of Enquiry",
            phoneNumber: "Phone Number",
            email: "Email",
            bookingRef: "Booking Reference",
            comment: "Comments",
            questions: "Questions",
            enterCaptcha: "Enter Captcha",
            submit: "Submit"
        },
        natureOfEn: {
            generalEnq: "General Enquiry",
            booking: "Bookings",
            cancelGroups: "Cancellations Groups",
            packagesHotel: "Packages and HotelsComplaints",
            reportIss: "Report Issue"
        }
    },
    footerContents: {
        company: "Company",
        support: "Support",
        aboutUs: "About Us",
        contactUs: "Contact Us",
        termsofUse: "Terms of use, Fare rules",
        email: "Email",
        privacyPolicy: "Privacy policy",
        myBookings: "My Bookings",
        followUs: "FOLLOW US",
        customerNumber: "Customer Service Number",
        copyright: "Copyright",
        allrights: "All rights reserved"
    },
};