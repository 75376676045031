<template>
    <header>
    <!-- Navbar Start -->
    <nav class="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primary sticky-top p-0">
        <v-img :src="require('@/assets/buymytrip.png')" height="50px" max-width="35%"/>
        <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto p-4 p-lg-0">
                <a href="#" class="nav-item nav-link active">Home</a>
                <a href="#" class="nav-item nav-link">About</a>
                <a href="#" class="nav-item nav-link">Destination</a>
                <!-- <div class="nav-item dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                    <div class="dropdown-menu fade-up m-0">
                        <a href="#" class="dropdown-item">Pricing Plan</a>
                        <a href="#" class="dropdown-item">Features</a>
                        <a href="#" class="dropdown-item">Free Quote</a>
                        <a href="#" class="dropdown-item">Our Team</a>
                        <a href="#" class="dropdown-item">Testimonial</a>
                        <a href="#" class="dropdown-item">404 Page</a>
                    </div>
                </div> -->
                <a href="#" class="nav-item nav-link">Contacts</a>
            </div>
            <v-btn class="book-btn text-none me-4 text-white fw-bolder fs-6" color="danger" rounded="3" variant="elevated"
                width="14%" height="40px">
                View Booking
            </v-btn>
            <div class="form-btn" style="margin-right: 30px !important;">
                <v-btn height="30px" elevation="5" style="margin: 15px;">
                    <v-icon>mdi-account</v-icon>
                    Login
                </v-btn>
            </div>
        </div>
    </nav>
    <!-- Navbar End -->
</header>

</template>


<style scoped>
/*** Navbar ***/
.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
    position: relative;
    margin-right: 30px;
    padding: 25px 0;
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
    color: var(--primary);
}


@media (max-width: 991.98px) {
    .navbar-light .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar-light .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }

    .book-btn {
        height: 40px;
        width: 35% !important;
        margin-left: 10px;
    }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
    height: 75px;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--dark);
    font-weight: 500;
}

.navbar-light.sticky-top {
    top: -100px;
    transition: .5s;
}

@media (min-width: 992px) {
    .navbar-light .navbar-nav .nav-link::before {
        position: absolute;
        content: "";
        width: 0;
        height: 5px;
        top: -6px;
        left: 50%;
        background: #FFFFFF;
        transition: .5s;
    }

    .navbar-light .navbar-nav .nav-link:hover::before,
    .navbar-light .navbar-nav .nav-link.active::before {
        width: 100%;
        left: 0;
    }

    .navbar-light .navbar-nav .nav-link.nav-contact::before {
        display: none;
    }

    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }

}


</style>