<template>
  <div class="trip1">
    <v-card style="padding: 50px; margin-top: 50px;overflow: unset;" width="600px" rounded="3" >
      <div class="d-flex justify-center">
        <h4>Modify your booking</h4>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <span> Booking Reference </span>
          <br>
          <span></span>
        </div>

        <div class="col-md-6">
          <v-select
            variant="outlined"
            v-model="bookingStatus"
            :items="[
              'Cancel Booking',
              'Date Change',
              'Upgrade Booking',
              'Request Addons',
            ]"
          ></v-select>
        </div>
      </div>

 

      <div v-if="datechange">
        <div class="row">
          <div class="col-md-4 d-flex justify-center">
            <p>Outbound Between</p>
          </div>
          <div class="col-md-4">
            <VueDatePicker ref="dpRef1" v-model="dedate1" :min-date="new Date()"
                                :six-weeks="sixWeekMode"
                                :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                                :format="dateFormat" :hide-navigation="['time']" auto-apply
                                :multi-calendars="multiCalender" 
                                class="px-0" />
          </div>
          <div class="col-md-4">
            <VueDatePicker ref="dpRef1" v-model="dedate2" :min-date="new Date()"
                                :six-weeks="sixWeekMode"
                                :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                                :format="dateFormat" :hide-navigation="['time']" auto-apply
                                :multi-calendars="multiCalender" 
                                class="px-0" />
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-4 d-flex justify-center">
            <p>Iutbound Between</p>
          </div>
          <div class="col-md-4">
            <VueDatePicker ref="dpRef1" v-model="dedate3" :min-date="new Date()"
                                :six-weeks="sixWeekMode"
                                :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                                :format="dateFormat" :hide-navigation="['time']" auto-apply
                                :multi-calendars="multiCalender" 
                                class="px-0" />
          </div>
          <div class="col-md-4">
            <VueDatePicker ref="dpRef1" v-model="dedate4" :min-date="new Date()"
                                :six-weeks="sixWeekMode"
                                :max-date="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())"
                                :format="dateFormat" :hide-navigation="['time']" auto-apply
                                :multi-calendars="multiCalender" 
                                class="px-0" />
          </div>
        </div>
      </div>

      <div class="d-flex justify-center" v-if="requstup">
        <p>
          Please mention your request below.Our support team will be in touch
          with you at the soonest posible
        </p>
      </div>

      <div class="mt-2 ms-4">
        <div>Notes</div>
        <div class="mt-1 col-11">
          <v-textarea  variant="outlined"></v-textarea>
        </div>
      </div>


      <div class="d-flex justify-center mt-4">
        <v-btn width="250px" color="rgb(255, 107, 10)" style="height: 40px !important">Request</v-btn>
      </div>
    </v-card>
</div>

<div class="d-flex justify-center " style="    flex-flow: wrap;">
<p>
    We have received your request.Our customer support team will get back to you at the soonest possible
</p>

<p>
    Please note that  all notification requests are subject to the airline team conditions,
    and may have applicable charges
</p>
</div>

  <br />
</template>
<script>
    import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    components:{
        VueDatePicker
    },
  data() {
    return {
      bookingStatus: "Cancel Booking",
      requstup:false,
      datechange:false,
      dedate1:"",
      dedate2:"",
      dedate3:"",
      dedate4:"",
    };
  },
  watch: {
    bookingStatus(newVal) {
      console.log(newVal, "newVal");
      if(newVal=="Cancel Booking"){
        this.datechange=false;
        this.requstup=false
      }
    else if(newVal=="Date Change"){
        this.datechange=true;
        this.requstup=false
      }
      else if(newVal=="Upgrade Booking" || newVal=="Request Addons"){
        this.datechange=false;
        this.requstup=true
      }
    },
  },
};
</script>
<style scoped>
.trip1{
    justify-content: center;
    display: flex;
    padding: 15px;
}

</style>
