<template>
  <header :class="{ sticky: isSticky }" style="background-color: rgb(247 239 241 / 64%)">
    <!-- v-click-outside="onClickOutside"> -->

    <div class="container">
      <nav class="navbar navbar-expand-lg px-3 navbar-fixed-top">
        <a :href="portalUrl">
          <v-img :src="portalLogo" class="portal_logo" @click="$router.push('/')" />
        </a>
        <v-spacer></v-spacer>




        <div class="countryDrop d-flex align-center me-2" v-click-outside="() => onClickOutside('country')"
          v-if="headerMenus.includes('country')" :class="{ 'active': isCountryActive }" @click="toggleCountry()">
          <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer">
            <div class="d-flex align-center">
              <div>
                <v-img :src="display_flagLogo" width="30px" height="30px" style="border-radius: 50%" />
              </div>
              {{ console.log(currencyData.display, 'currencyDatadisplaycurrencyDatadisplay') }}
              <span class="ps-1 currency-text" style="font-size: 15px; font-weight: 400; color: black">
                {{ currencyData.display }}</span>
            </div>
          </v-card>

          <span v-if="countryOptions.length > 1" class="d-sm-block d-none">
            <v-icon size="30" :class="{ 'rotate-icon': isCountryActive }">mdi-menu-down</v-icon>
          </span>

          <div class="dropdown d-flex justify-center" id="dropdown" v-if="countryOptions.length > 1" 
          :class="{
            'active d-block': isCountryActive,
            'd-none': !isCountryActive,
            'coDropSize2': sortedCountryOptions.length > 2,
            'coDropSize1': sortedCountryOptions.length <= 2
          }">
            <div class="px-3 pt-2 pb-1 w-100">
              <div class="d-flex justify-content-between">
                <p class="m-0 f-size-15 fw-600">Country and Currency</p>
                <!-- <span class="p-1 hovercountry">
                  <v-icon>mdi-window-close</v-icon>
                </span> -->
              </div>
              <div class="row w-100">
                <div class="py-2 hovercountry"
                  :class="sortedCountryOptions.length > 2 ? 'col-md-4 col-sm-6' : 'col-sm-6'"
                  v-for="(country, index) in sortedCountryOptions" :key="index">
                  <div class="d-flex align-center">
                    <a :href="`${country.portal_url}?ref=web&redirect=false`" style="text-decoration: none">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="country.flag_logo" width="22px" height="22px" style="border-radius: 50%" />
                        </div>
                        <span class="ps-1 f-size-14" :class="index == 0 ? 'text-info fw-500' : 'text-dark fw-400'">
                          <span>{{ country.country_name }}</span>
                          <span> ({{ country.currency }})</span>
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="languageDrop d-flex align-center me-2" v-click-outside="() => onClickOutside('language')"
          v-if="headerMenus.includes('language')" :class="{ 'active': isLanguageActive }" @click="toggleLanguage()">
          <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer">
            <div class="d-flex align-center">
              <div class="currency-text">
                <v-img :src="require('@/assets/language_icon.png')" width="22px" />
              </div>
              <span class="ps-1 f-size-15 fw-400 text-dark text-uppercase">{{ displayLanguage }}</span>
            </div>
          </v-card>

          <span v-if="languageOptions.length > 1" class="d-sm-block d-none">
            <v-icon size="30" :class="{ 'rotate-icon': isLanguageActive }">mdi-menu-down</v-icon>
          </span>

          <div class="dropdown d-flex justify-center" id="dropdown" v-if="languageOptions.length > 1"
          :class="{
            'active d-block': isLanguageActive,
            'd-none': !isLanguageActive,
            'lagDropSize2': sortedLanguageOptions.length > 2,
            'lagDropSize1': sortedLanguageOptions.length <= 2
          }">
            <div class="px-3 pt-2 pb-1 w-100">
              <div class="d-flex justify-content-between mb-2">
                <h6 class="m-0 f-size-15 fw-600">Language</h6>
              </div>
              <div class="row">
                <div class="py-2 hovercountry" :class="sortedLanguageOptions.length > 2 ? 'col-md-4 col-sm-6' : 'col-sm-6'"
                  v-for="(lang, index) in sortedLanguageOptions" :key="index">
                  <div class="d-flex align-center" @click="changeLocale(lang.code)">
                    <span class="f-size-14" :class="index == 0 ? 'text-info fw-500' : 'text-dark fw-400'">
                      <span>{{ lang.name }}</span>
                      <span class="text-uppercase"> ({{ lang.code }})</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="d-flex align-center me-2 d-none" v-if="headerMenus.includes('country')">
          <v-menu location="bottom">
            <template v-slot:activator="{ props }">
              <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer"
                @click="dialogCountry = true">
                <div class="d-flex align-center">
                  <div>
                    <v-img :src="display_flagLogo" width="30px" height="30px" style="border-radius: 50%" />
                  </div>
                  {{ console.log(currencyData.display, 'currencyDatadisplaycurrencyDatadisplay') }}
                  <span class="ps-1 currency-text" style="font-size: 15px; font-weight: 400; color: black">
                    {{ currencyData.display }}</span>
                </div>
              </v-card>

              <v-list-item-icon v-if="countryOptions.length > 1" class="d-sm-block d-none">
                <span>
                  <v-icon size="30" :class="{ 'rotate-icon': isMenuCurrency }">mdi-menu-down</v-icon>
                </span>
              </v-list-item-icon>
            </template>



            <v-list style="cursor: pointer" v-if="countryOptions.length > 1">
              <div class="d-flex justify-center countryDropdown">
                <div class="px-3 py-2" style="max-width: 600px;">
                  <div class="d-flex justify-content-between">
                    <h6 class="m-0">Select your currency</h6>
                    <span class="p-1 hovercountry" @click="dialogCountry = false">
                      <v-icon>mdi-window-close</v-icon>
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-sm-4 py-2 hovercountry" v-for="(country, index) in sortedCountryOptions"
                      :key="index">
                      <div class="d-flex align-center">
                        <a :href="`${country.portal_url}?ref=web&redirect=false`" style="text-decoration: none">
                          <div class="d-flex align-center">
                            <div>
                              <v-img :src="country.flag_logo" width="25px" height="25px" style="border-radius: 50%" />
                            </div>
                            <span class="ps-1 f-size-14 fw-400" :class="index == 0 ? 'text-info' : 'text-dark'">
                              <span>{{ country.country_name }}</span>
                              <span> ({{ currencyData.display }})</span>
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-list>

            <!-- <v-dialog v-model="dialogCountry" max-width="600" transition="dialog-top-transition"  persistent>
              <div class="d-flex justify-center">
                <v-card class="p-4" width="600">
                  <div class="d-flex justify-content-between">
                    <h5>Select your currency</h5>
                    <span class="p-1 hovercountry" @click="dialogCountry = false">
                      <v-icon>mdi-window-close</v-icon>
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-4 py-2 hovercountry" v-for="(country, index) in sortedCountryOptions" :key="index">
                      <div class="d-flex align-center">
                        <a :href="`${country.portal_url}?ref=web&redirect=false`" style="text-decoration: none">
                          <div class="d-flex align-center">
                            <div>
                              <v-img :src="country.flag_logo" width="30px" height="30px" style="border-radius: 50%" />
                            </div>
                            <span class="ps-1" style="font-size: 15px; color: black">{{ country.currency }}</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-dialog> -->


            <!-- <v-list style="cursor: pointer" v-if="countryOptions.length > 1">
              <v-list-item class="hoverList" v-for="(country, index) in countryOptions" :key="index">
                <div>
                  <div v-if="country.currency !== currencyData.display">
                    <a :href="`${country.portal_url}?ref=web&redirect=false`" style="text-decoration: none">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="country.flag_logo" width="30px" height="30px" style="border-radius: 50%" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{ country.currency }}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </v-list-item>
            </v-list> -->
          </v-menu>
        </div>



        <div class="d-flex align-center d-none" v-if="headerMenus.includes('language')">
          <v-menu location="bottom">
            <template v-slot:activator="{ props }">
              <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer">
                <div class="d-flex align-center">
                  <div class="currency-text">
                    <v-img :src="require('@/assets/language_icon.png')" width="22px" />
                  </div>

                  {{ console.log(currencyData.display, 'currencyDatadisplaycurrencyDatadisplay') }}
                  <span class="f-size-15 fw-400 text-dark ps-1 text-uppercase">{{ displayLanguage
                    }}</span>
                </div>
              </v-card>

              <v-list-item-icon v-if="languageOptions.length > 1" class="d-sm-block d-none">
                <span>
                  <v-icon size="30" :class="{ 'rotate-icon': isMenulanguage }">mdi-menu-down</v-icon>
                </span>
              </v-list-item-icon>
            </template>
            <v-list style="cursor: pointer" v-if="languageOptions.length > 1">
              <v-list-item class="hoverList" v-for="(lang, index) in dropdownLanguages" :key="index">
                <div class="d-flex align-center" @click="changeLocale(lang.code)">
                  <span class="ps-1" style="font-size: 15px; color: black">{{ lang.name }} - <span
                      class="text-uppercase">{{
                        lang.code }}</span></span>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>


        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="container-fluid setSize">

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-2 mb-lg-0">

              <li class="nav-item dropdown d-flex justify-end align-center" v-if="headerMenus.includes('email')">
                <!-- <v-menu location="bottom"> -->
                <v-menu v-model="isMenuHelp" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/helpbox.png')" width="25px" />
                        </div>
                        <!-- <span class="ps-1 currency-text" style="font-size: 15px;font-weight: 400;color:black">{{ $t('homePageContent.welcomeMsg.text1') }}</span> -->
                        <span class="ps-1" style="font-size: 15px; font-weight: 400; color: black">{{
                          $t("headerContents.helpSupport") }}</span>

                        {{ console.log(this.$i18n.locale, 'helpSupport...1') }}

                      </div>
                    </v-card>

                    <v-list-item-icon>
                      <span>
                        <v-icon size="30" :class="{ 'rotate-icon': isMenuHelp }">mdi-menu-down</v-icon>
                      </span>
                    </v-list-item-icon>
                  </template>
                  <v-list style="cursor: pointer">
                    <v-list-item class="hoverList">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/email_icon.png')" width="18px" />
                        </div>
                        <a :href="'mailto:' + emailPortal" class="text-decoration-none">
                          <span class="ps-1" style="font-size: 15px; color: black">{{ emailPortal }}</span>
                        </a>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" @click="toggleTawkToChat()"
                      v-if="this.headerMenus.includes('chatsupportlink')">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/chat_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.chatSupport")
                        }}</span>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" to="/contactPage">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/reqform_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.requestForm")
                        }}</span>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>

              <li class="nav-item dropdown d-flex justify-end align-center" v-if="headerMenus.includes('accounts')">
                <v-menu v-model="isMenuAccount" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-card class="shadow-none bg-transparent" v-bind="props" style="cursor: pointer">
                      <div class="d-flex align-center">
                        <div>
                          <span><i class="bi bi-person-circle f-size-18"></i></span>
                        </div>
                        <span class="ps-1 f-size-15 fw-400" style="color: black">{{
                          this.userName ? this.userName : $t("headerContents.account")
                        }}</span>
                      </div>
                    </v-card>

                    <v-list-item-icon>
                      <span>
                        <v-icon size="30" :class="{ 'rotate-icon': isMenuAccount }">mdi-menu-down</v-icon>
                      </span>
                    </v-list-item-icon>
                  </template>
                  <v-list style="cursor: pointer">
                    <v-list-item class="hoverList" to="/myprofile" v-if="this.userName">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/myprofile_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{ $t("headerContents.myProfile")
                          }}</span>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" v-if="!this.userName" @click="showLoginForm()">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/userlogin_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.login")
                        }}</span>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" v-if="!this.userName" @click="showRegisterForm()">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/userregister_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.register")
                        }}</span>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" to="/mybooking" v-if="this.headerMenus.includes('mybookings')">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/viewBooking_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.viewBooking")
                        }}</span>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" to="/dashboard"
                      v-if="this.userName && this.headerMenus.includes('viewbooking')">
                      <div class="d-flex align-center">
                        <div>
                          <v-img :src="require('@/assets/myBooking_icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.myBookings")
                        }}</span>
                      </div>
                    </v-list-item>
                    <v-list-item class="hoverList" v-if="this.userName">
                      <div class="d-flex align-center" @click="deleteItem()">
                        <div>
                          <v-img :src="require('@/assets/logout_Icon.png')" width="18px" />
                        </div>
                        <span class="ps-1" style="font-size: 15px; color: black">{{
                          $t("headerContents.logOut")
                        }}</span>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </li>

            </ul>
          </div>

        </div>
      </nav>
    </div>
  </header>

  <div v-if="loginform">
    <loginCom :isVisible="loginform" @close="closeLogin()" @switch-to-register="switchToRegister"></loginCom>
  </div>
  <div v-if="registerform">
    <registerCom :isRegister="registerform" @close="closeRegister()" @switch-to-login="switchToLogin"></registerCom>
  </div>

</template>

<script>
// import loginCom from '@/components/loginCom.vue'
import loginCom from "@/components/loginCom.vue";
import registerCom from "@/components/registerCom.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

// import axios from "axios";
// import { onMounted } from 'vue';

// import { onMounted } from 'vue';
// import { mapGetters } from 'vuex';
// import { useStore } from 'vuex';

// setTimeout(() => {
//   onMounted(() => {
//     // this won't work.
//     console.log(this.$store.state.data,'head')
//   })
// }, 100)

export default {
  components: {
    registerCom,
    loginCom,
  },
  data() {
    return {
      loginform: false,
      registerform: false,
      selectedCountry: null,
      isSticky: false,
      conDetails: true,
      menuData: "",

      isMenuCurrency: false,
      isMenulanguage: false,
      isMenuHelp: false,
      isMenuAccount: false,
      // CurrencyItems: [
      //   { name: "EN CA$", src: require("@/assets/CA.svg"), link: "https://buymytrip.ca/" },
      //   // { name: "EN AU$", src: require("@/assets/AU.svg"), link:"https://buymytrip.com.au/"},
      //   // { name: "EN US$", src: require("@/assets/US.svg"), link:"https://buymytrip.com/"},
      //   // { name: "EN UK£", src: require("@/assets/GB.svg"), link:"https://buymytrip.co.uk/"},
      // ],

      dialogCountry: false,
      isCountryActive: false,
      isLanguageActive: false,

      PortMail: "us-bookings@buymytrip.com",
      PortContact: "+1-659 251 2000",

      portCurrency: "EN US$",
      portCurrencyLogo: require("@/assets/US.svg"),

      hostName: "",
      portalUrl: "",

      userDetails: true,
      userProfile: false,
      userEmail: "",
      userName: "",
      portalLogo: "",
      emailPortal: "",
      phonePortal: "",
      countryOptions: [],
      languageOptions: [],
      languagePortal: "",
      currencyPortal: "",
      countyIsocode: "",
      siteContentData: "",
      // languageData:"",

      user: {
        initials: "",
        // fullName: 'John Doe',
        email: "",
      },

      languages: [
        { flag: "gb", language: "CA-en", title: "English (UK)" },
        { flag: "es", language: "CA-es", title: "Spanish (Spain)" },
        // { flag: 'fr', language: 'fr', title: 'French (France)' },
        // { flag: 'de', language: 'de', title: 'German (Germany)' },
        // { flag: 'it', language: 'it', title: 'Italian (Italy)' },
        // { flag: 'jp', language: 'ja', title: 'Japanese (Japan)' },
        // { flag: 'cn', language: 'zh', title: 'Chinese (China)' },
        // { flag: 'ca', language: 'en', title: 'English (Canada)' },
        // { flag: 'au', language: 'en', title: 'English (Australia)' },
        // { flag: 'za', language: 'en', title: 'English (South Africa)' },
        // { flag: 'us', language: 'en', title: 'English (USA)' },
        // { flag: 'gb', language: 'en', title: 'English (UK)' },
      ],

      displayLanguage: "",
      dropdownLanguages: [],

      headerMenus: [],
      // menuEmail:"",
      // menuPhone:"",
    };
  },
  methods: {
    showLoginForm() {
      this.loginform = true;
      this.registerform = false;
    },
    showRegisterForm() {
      this.registerform = true;
      this.loginform = false;
    },

    switchToLogin() {
      this.loginform = true;
      this.registerform = false;
    },
    switchToRegister() {
      this.registerform = true;
      this.loginform = false;
    },


    toggleCountry() {
      this.isCountryActive = !this.isCountryActive;
      this.isLanguageActive = false;
    },

    toggleLanguage() {
      this.isLanguageActive = !this.isLanguageActive;
      this.isCountryActive = false;
    },
    // getLanguage(getLang){
    //   console.log(getLang,'getLanggetLanggetLanggetLang')
    //   let data = getLang !== languages.display
    //   return data ? true : false
    // },

    // displayLanguage(){
    //   return languageOptions[0].code.toUpperCase()
    // },

    changeLocale(locale) {
      const reloadLang = setInterval(() => {
        // location.reload();
      }, 150);

      // this.languages.forEach(v=>{
      // console.log(v.language.split('-')[1],'v.language.split('-')[1]v.language.split('-')[1]')
      // if(v.language.split('-')[1] == locale){
      // const countryCode = this.countryOptions[0].country_code;

      if (reloadLang) {
        console.log(reloadLang, 'reloadLangreloadLang');
        const newLocale = `${this.countyIsocode}-${locale}`;
        console.log("Changing locale to", newLocale);
        this.$i18n.locale = newLocale;

        localStorage.setItem("defaultlang", newLocale);
        // this.$i18n.fallbackLocale = v.language;
        console.log("Current locale:", this.$i18n);
        // }
        // })
        this.displayLanguage = locale;
        // this.updateDropdownLanguages();

        // this.localeLanguage();
      }

    },

    updateDropdownLanguages() {
      console.log(this.languageOptions, 'languageOptionslanguage', this.displayLanguage)
      this.dropdownLanguages = this.languageOptions.filter((lang) => {
        if (lang.code !== this.displayLanguage) {
          return lang.code
        }
      });
      console.log(this.displayLanguage, "dropdownLanguagesdropdownLanguages");
      console.log(this.$i18n.locale, 'getegeteegtegete....1');
    },

    displayedLang() {
      let getlocalIso = this.$i18n.locale;

      let getsetlang = getlocalIso.split('-')[1]
      this.displayLanguage = getsetlang;
      // let datalang = this.localeLanguage();
      // let getlocalIso = datalang;
      // this.$i18n.locale = datalang;
      // let getlocalIso = this.$i18n.locale;
      console.log(this.displayLanguage, 'i18ni18ni18ni18ni18n');
      // this.updateDropdownLanguages();
      console.log(this.$i18n.locale, 'getegeteegtegete....2');
    },


    localeLanguage() {
      const storedLocale = localStorage.getItem("defaultlang");
      const storedSetLang = localStorage.getItem("setlang");

      let getlocalIso = "";
      let getlocalLang = "";
      let getsetIso = "";
      let getsetlang = "";
      // let savedLanguage = "";


      if (storedLocale) {
        getlocalIso = storedLocale.split('-')[0];
        getlocalLang = storedLocale.split('-')[1];
      }
      if (storedSetLang) {
        getsetIso = storedSetLang.split('-')[0];
        getsetlang = storedSetLang.split('-')[1];
      }


      if (storedSetLang && storedLocale) {
        console.log('Check...1');
        if (storedLocale == storedSetLang) {
          console.log('Check...2');

          return storedSetLang;
          // this.i18n.locale = storedSetLang;
        }
        else if (storedLocale !== storedSetLang) {
          console.log(getlocalIso, getsetIso, 'Check...3', getlocalLang, getsetlang);
          if (getlocalIso !== getsetIso && getlocalLang !== getsetlang) {

            localStorage.removeItem("defaultlang");
            console.log('Check...4');
            return storedSetLang;
            // this.i18n.locale = storedSetLang;

          }
          else if (getlocalIso == getsetIso && getlocalLang !== getsetlang) {
            console.log('Check...5');

            return storedLocale;
            // this.i18n.locale = storedLocale;
          }
        }
      }
      else if (storedSetLang) {
        console.log('Check...6');

        return storedSetLang;
        // this.i18n.locale = storedSetLang;
      }
      else if (storedLocale) {
        console.log('Check...7');

        return storedLocale;
        // this.i18n.locale = storedLocale;
      }
      else {
        console.log('Check...8');

        return `${this.countyIsocode}-${this.getLanguage[0].code}`;
        // this.i18n.locale = `${this.countyIsocode}-${this.getLanguage[0].code}`;
      }
    },


    toggleDropdown(dropdownName) {
      if (this.activeDropdown === dropdownName) {
        this.activeDropdown = null;
      } else {
        this.activeDropdown = dropdownName;
      }
    },

    toggleTawkToChat() {
      if (window.Tawk_API) {
        window.Tawk_API.toggle();
      }
    },

    portalLink(countryOptions) {
      return countryOptions[0].portal_url;
    },
    portallogo(countryOptions) {
      return countryOptions[0].flag_logo;
    },
    // portalLanguage(countryOptions) {
    //   return countryOptions[0].languages
    // },
    // portalcurrency(currencyData) {
    //   console.log(currencyData,'currencyDatacu')
    //   return currencyData.display
    // },

    handleScroll() {
      this.isSticky = window.scrollY > 90;
    },
    signIn() {
      // this.loginform = true;
      this.loginform = !this.loginform;
      // this.registerform = false;
    },
    signUp() {
      // this.registerform = true;
      this.registerform = true;
      // this.registerform = false;
    },

    onClickOutside(getmenu) {
     // console.log(getmenu, 'getmenugetmenugetmenu')
      if (getmenu == 'country') {
        this.isCountryActive = false;
      }
      else {
        this.isLanguageActive = false;
      }
      // this.loginform = false
      // console.log(this.loginform, "666666")
    },

    closeLogin() {
      this.loginform = false;
      console.log(this.loginform, "4444444");
    },
    // register() {
    //   this.registerform = !this.registerform; // Toggle the value of registerform
    //   this.loginform = false; // Close the login form if open
    // },
    closeRegister() {
      this.registerform = false;
      console.log(this.registerform, "registerformregisterform");
    },

    getUser() {
      let userData = JSON.parse(localStorage.getItem("value"));
      // console.log(userData, "user....");
      if (userData) {
        this.userDetails = false;
        this.userProfile = true;
      } else {
        this.userDetails = true;
        this.userProfile = false;
      }
    },
    booking() {
      this.$router.push("/dashboard");
    },
    deleteItem() {
      localStorage.removeItem("value");
      localStorage.removeItem("loginuser");
      setInterval(() => {
        location.reload();
      }, 500);
      this.userDetails = true;
      this.userProfile = false;
      this.$router.push("/");
    },

    getLoginDatas() {
      JSON.parse(localStorage.getItem("loginDatas"));
      // console.log(userItems, "loginddddd......");
      // this.user.email = userItems.userEmail
      // this.user.email = userItems.userPass
    },
    getlogo(data) {
      console.log(data, "duifuisddsddjik");
    },

    getConfigFunc() {
      // Parse the decrypted data as JSON
      const getConfigData = getConfigDataFromLocalStorage();
      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      console.log(getConfigData, "head...1");
      // let getConfigData = this.$store.state.data

      if (getConfigData) {
        let Menus = "";
        Menus = getConfigData.payload.portal_configuration.menus.enabled.header;
        this.menuData =
          getConfigData.payload.portal_configuration.menus.enabled.header;
        // console.log(this.menuData.includes('viewbookings'),'MenusMenus3333')
        this.headerMenus = Menus.split(", ");

        // console.log(this.headerMenus, "MenusMenusMenusMenus")

        // getConfigData.payload.portal_configuration.siteTitle
        // this.menuEmail = this.Menus.header.email
        // this.menuPhone = this.Menus.header.phone
        // console.log(this.menuEmail,"pmpmpmpmpm...1")
        // console.log(this.menuPhone,"pmpmpmpmpm....2")

        this.portalUrl = getConfigData.payload.portal_configuration.portal_url;
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        this.phonePortal = getConfigData.payload.portal_configuration.phone;
        this.emailPortal = getConfigData.payload.portal_configuration.email;
        this.currencyData = getConfigData.payload.portal_configuration.currency;
        this.countyIsocode = getConfigData.payload.portal_configuration.country.iso_code;

        // this.portalLanguage = 'ENG'
        this.portalLanguage = getConfigData.payload.portal_configuration.language;


         this.countryOptions = getConfigData.payload.portal_configuration.country_options;
        // this.countryOptions = [
        //   {
        //     "country_code": "US",
        //     "country_name": "United States",
        //     "languages": "en,fr",
        //     "flag_logo": "https://dl.buymytrip.com/img/logo/US.svg",
        //     "currency": "US$",
        //     "portal_url": "https://buymytrip.com",
        //     "default_domain": true
        //   },
        //   {
        //     "country_code": "CA",
        //     "country_name": "Canada",
        //     "languages": "en,es,fr",
        //     "flag_logo": "https://dl.buymytrip.com/img/logo/CA.svg",
        //     "currency": "CA$",
        //     "portal_url": "https://buymytrip.ca",
        //     "default_domain": false
        //   },
        //    {
        //      "country_code": "AU",
        //      "country_name": "Australia",
        //      "languages": "en,fr,es",
        //      "flag_logo": "https://dl.buymytrip.com/img/logo/US.svg",
        //      "currency": "AU$",
        //      "portal_url": "https://buymytrip.com",
        //      "default_domain": false
        //    },
        //   {
        //     "country_code": "IN",
        //     "country_name": "India",
        //     "languages": "en,es,fr,lk",
        //     "flag_logo": "https://dl.buymytrip.com/img/logo/CA.svg",
        //     "currency": "IN₹",
        //     "portal_url": "https://buymytrip.ca",
        //     "default_domain": false
        //   },
        // ];


        this.languageOptions = getConfigData.payload.portal_configuration.languages;
        // this.languageOptions = [
        //   {
        //     "code": "en",
        //     "name": "English"
        //   },
        //   {
        //     "code": "es",
        //     "name": "Spanish"
        //   },
        //    {
        //      "code": "fr",
        //      "name": "French"
        //    },
        //    {
        //      "code": "pt",
        //      "name": "Chinesh"
        //    },
        //    {
        //      "code": "ko",
        //      "name": "Korea"
        //    },
        //    {
        //      "code": "ja",
        //      "name": "Japan"
        //    },
        // ];


        // this.displayLanguage = this.languageOptions[0].code

        // this.languageData = getConfigData.payload.portal_configuration.language_data;
        // this.getlangaugeUrl();

        // console.log(this.languageData,'siteContentDatasiteContentDatasiteContentData')

        this.siteContentData = getConfigData.payload.portal_configuration.site_content_data;

        // this.updateDropdownLanguages();

        this.countryOptions.forEach((v, i) => {
          console.log(v.currency, v.flag_logo, i, this.currencyData.display, v.default_domain, i, 'checkcheckcheckcheck')
          if (v.currency == this.currencyData.display) {
            this.display_flagLogo = v.flag_logo;
            this.currencyData.display = v.currency;
            console.log(this.currencyData.display, this.display_flagLogo, "currencyDatacurrencyData...1")
          }
        });
      }
    },


  },

  watch: {
    '$i18n.locale': function () {
      this.displayedLang();
    }
  },

  computed: {
    sortedCountryOptions() {
      // Find the current country
      const currentCountryIndex = this.countryOptions.findIndex(
        country => country.currency === this.currencyData.display
      );

      // Remove the current country from the list
      let remainingCountries = [...this.countryOptions];
      if (currentCountryIndex !== -1) {
        remainingCountries.splice(currentCountryIndex, 1);
      }

      // Sort the remaining countries alphabetically by country name
      remainingCountries.sort((a, b) => a.country_name.localeCompare(b.country_name));

      // Add the current country back at the beginning of the list
      if (currentCountryIndex !== -1) {
        remainingCountries.unshift(this.countryOptions[currentCountryIndex]);
      }
      return remainingCountries;
    },

    sortedLanguageOptions() {
      const currentLanguage = this.languageOptions.find(lang => lang.code === this.displayLanguage);
      let remainingLanguages = [...this.languageOptions].filter(lang => lang.code !== this.displayLanguage);
      remainingLanguages.sort((a, b) => a.name.localeCompare(b.name));
      if (currentLanguage) {
        remainingLanguages.unshift(currentLanguage);
      }
      return remainingLanguages;
    }
  },

  created() {

    this.getConfigFunc();
    let $useresult = JSON.parse(localStorage.getItem("loginuser"));
    // console.log($useresult, '$useresult $useresult ')
    if ($useresult) {
      this.userEmail = $useresult.user.email;
      this.userName = $useresult.user.name;
      console.log($useresult, "$useresult$useresult");
      console.log(this.userEmail, this.userName, "adsasfasdas");
    }

    // this.localeLanguage();
    this.displayedLang();
    // this.localeLanguage();
    console.log(this.$i18n.locale, 'getegeteegtegete')
  },

  mounted() {
    // setTimeout(() => {
    // this.getConfigFunc();
    // }, 500)

    window.addEventListener("scroll", this.handleScroll);
    this.getUser();
    this.getLoginDatas();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
/* navbar */
.logo {
  width: 180px;
}

.portal_logo {
  width: 200px;
  height: 55px;
  cursor: pointer;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #f8f4f5 !important;
  z-index: 999;
  animation: fadeInDown 0.6s ease-in-out 0s 1 normal none running;
  transition: 0.6s;
  box-shadow: 0px 0px 20px 0px black;

  /* Add any other styles you need for the sticky header */
}

.countryDropdown span.hovercountry {
  position: relative;
  top: -10px;
  left: 10px;
}

.hovercountry:hover {
  background: #00000014;
  border-radius: 5px;
  cursor: pointer;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-18);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-400);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

/* .v-btn__content {
  color: #FF5400;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
} */

.bgimg {
  background-image: url("@/assets/CA.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  /* color: white; */
}

.btn-active {
  background-color: blueviolet;
  /* color: white; */
}

.v-checkbox .v-input__details {
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.navbar-toggler {
  border: none !important;
}

.dropdown-toggle {
  width: 50px;
  height: 30px;
  white-space: nowrap;
  border: 1px solid !important;
}

/* .v-app-bar {
  height: 100px;
  background-image: url('../assets/flight.jpg');
  background-size: 100% 100%;
} */

/* .btn-box,
.pipe {
  color: white !important;
} */

.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

.contact-box span {
  font-size: 15px;
}

.hoverList:hover {
  background-color: lightgrey;
}

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;
}

.booking:hover {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

.booking-active {
  color: #212529;
  border-bottom: 3px solid orange;
  padding-bottom: 2px;
  cursor: pointer;
}

.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.rotate {
  animation: spin 0.5s forwards;
}


.navbar .container-fluid.setSize {
  width: fit-content !important;
  /* width: auto; */
}

.contact-section-mobile1 {
  display: none !important;
}



@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 1200px) {
  .currency-text {
    display: none;
  }
}

/* .currency-text {
    display: block;
  } */

.booking {
  color: #212529;
  padding-bottom: 2px;
  cursor: pointer;
}

@media only screen and (min-width: 992px) {
  .contact-section-mobile {
    display: none !important;
  }

  .contact-section-large {
    display: flex !important;
  }

  .contact-section-collapse {
    display: none !important;
  }
}





@media only screen and (max-width: 991px) {
  .navbar .container-fluid.setSize {
    width: 100% !important;
    top: 100%;
    right: 0;
    position: absolute;
    background: white;
    width: fit-content !important;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 1px 2px 0px black;
    z-index: 999;
  }

  .contact-section-mobile {
    display: flex !important;
  }

  .contact-section-large {
    display: none !important;
  }

  .contact-section-collapse {
    display: block !important;
  }

  .coDropSize1 {
  width: 420px !important;
  right: 0 !important;
}

.coDropSize2 {
  width: 600px !important;
  right: 0 !important;
}

.lagDropSize1 {
  width: 300px !important;
  right: 0 !important;
}
.lagDropSize2 {
  width: 400px !important;
  right: 0 !important;
}
}

@media only screen and (max-width: 480px) {
  .myBooking {
    display: none !important;
  }

  .portal_logo {
    width: 150px;
    height: 55px;
    cursor: pointer;
  }

  .dropdown-toggle {
    width: 40px;
    height: 28px;
    padding: 0 !important;
  }

  /* .navbar .container-fluid.setSize {
    width: 100% !important;
    top: 100%;
    right: 0;
    position: absolute;
    background: white;
    width: fit-content !important;
    border-radius: 0px 0px 6px 6px;
  } */

  .iconDiv {
    border: 1px solid black;
  }

  .coDropSize1 {
  width: auto !important;
  right: 0 !important;
}

.coDropSize2 {
  width: auto !important;
  right: 0 !important;
}

.lagDropSize1 {
  width: auto !important;
  right: 0 !important;
}
.lagDropSize2 {
  width: auto !important;
  right: 0 !important;
}
}



@media screen and (max-width:430px) {
  .contact-section-mobile1 {
    display: block !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .dropdown-toggle {
    border: 0px !important;
  }

  /* .emailhover:hover{
  background-color:aquamarine;
} */
}

.dropdown-toggle {
  border: 0px !important;
}












/* Country Dropdown css */

.countryDrop.active:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0px;
  background: white;
  transform: translateX(20px) rotate(45deg);
  box-shadow: 0 0 7px 0px rgb(0 0 0 / 35%);
}

.countryDrop .dropdown.active {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 24px 2px rgba(0, 0, 0, .08);
  display: flex;
  position: absolute;
  /* right: 0; */
  top: 90%;
  z-index: 101;
  max-height: 500px;
  overflow-y: auto;
}

.coDropSize1 {
  width: 420px;
  right: 14%;
}

.coDropSize2 {
  width: 600px;
  right: 8%;
}


.languageDrop.active:after {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0px;
  background: white;
  transform: translateX(20px) rotate(45deg);
  box-shadow: 0 0 7px 0px rgb(0 0 0 / 35%);
}

.languageDrop .dropdown.active {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 24px 2px rgba(0, 0, 0, .08);
  display: flex;
  position: absolute;
  top: 90%;
  z-index: 999;
}

.lagDropSize1 {
  width: 300px;
  right: 10%;
}
.lagDropSize2 {
  width: 400px;
  right: 5%;
}


/* Country Dropdown css */
</style>
